.timeTracking-settings-container {
	margin: 10px 0;

	.flex-center{
		display: flex;
		align-items: center;
	}

    .timeTracking-layout-wrap{
        display:flex;
        justify-content: space-between;
    }
	.timeTracking-layout {
		@extend .flex-center;
		gap: 10px;
	}
	.timeTracking-table {
		width: 75%;
		.timeTracking-head {
			padding: 15px 0;
			border-bottom: 1px solid $az_gray_3;
			display: flex;
			.timeTrackingFields {
				font-weight: 500;
				white-space: nowrap;
				color: $az_gray_6;
			}
			.name {
				flex: 0.2;
				span {
					padding-left: 10px;
				}
			}
		}
		.timeTrackingBody {
			padding: 10px 0 0 0;
			.tr {
				@extend .flex-center;
				margin-bottom: 10px;
			}
			.cell_in {
				padding-right: 30px;
			}
			.name {
				flex: 0.2;
			}

			.icons {
				@extend .flex-center;

				.accept {
					margin-left: 10px;
				}

				button,
				span {
					min-width: 0;
					cursor: pointer;
					@extend .flex-center;
					background: none;
					color: $az_gray_4;
					padding: 0;
					width: fit-content;
				}
			}
		}
	}

	.timeTracking-settings {
		display: flex;
		margin-top: 16px;
		margin-bottom: 20px;
		width: 80%;

		.timeTracking-settings-aside {
			display: flex;
			flex: 0.52;
			.tooltip{
				color: $az_badge_background;
				height: 11px;
				position: relative;
				top: 5px;
			}
			p{
				margin: 0;
			}
		}	

		.timeTracking-settings-body {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 12px;

			.radio-btn {
				margin: 0 10px 0 0;
			}

			div {
				display: flex;
				gap: 4px;
			}

			button {
				margin-top: 16px;
				width: fit-content;
				align-self: center;
			}

			.timeTracking-settings-icons {
				justify-content: center;
				margin: 30px 0;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
}
