.tag-multi-select-container {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 35px;
	height: 35px;
	box-sizing: border-box;
	border-radius: 5px;
	transition: 0.3s all ease-in-out;
	border: 1px solid $az_border_color;
	z-index: 4;
	background: $az_white;
	padding-left: 10px;
	&.is-open {
		border-color: $az_cyan;
	}
	.icon {
		margin: 0px 10px 2px 8px;
		margin-left: auto;
		transition: 0.3s all ease-in-out;
		color: $az_cyan;
		&.rotate-icon {
			transform: rotate(180deg);
		}
	}
	.dots {
		height: 12px;
		min-height: 12px;
		max-height: 12px;
		min-width: 12px;
		max-width: 12px;
		width: 12px;
		border-radius: 50%;
		margin-right: 5px;
	}
	.additional-tags {
		font-size: 11px;
	}
}
