.teams-modal {
	margin: 50px;
	.teams-modal-container {
		.teams-modal-header {
			display: inline-block;
			width: 100%;
			.teams-modal-header-item {
				display: inline-flex;
				width: 50%;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
				.input-area,
				.row-value-select-box {
					width: 200px;
					margin-right: 20%;
				}
			}
		}
		.teams-modal-search-bar {
			width: 250px;
			margin-bottom: 20px;
		}
		.teams-modal-users {
			display: inline-block;
			width: 100%;
			height: 300px;
			max-height: 300px;
			overflow: scroll;
			.teams-modal-user-container {
				display: inline-flex;
				align-items: center;
				width: 33.33%;
				padding: 0 10px;
				margin: 15px 0;
				.user-checkbox {
					width: 14px;
					margin-right: 10px;
				}
			}
		}
		.workers-list-message {
			text-align: center;
			margin: 75px;
		}
		.teams-modal-buttons {
			margin-left: auto;
			margin-right: 0;
			width: fit-content;
		}
	}
}
