.multi-selector-combo-box {
	position: relative;
    .multi-select-container{
        display:flex;
        align-items:center;
        width:100%;
        min-height: 35px;
        height: 35px;
        box-sizing: border-box;
        border-radius:5px;
        transition: 0.3s all ease-in-out;
        border: 1px solid $az_border_color;
        z-index: 4;
        background:$az_white;
        padding-left:16px ;
        &.is-open{
            border-color: $az_cyan;
        }
        .icon{
            margin:0px 10px 2px 8px;
            margin-left: auto;
            transition: 0.3s all ease-in-out;
            color:$az_cyan;
            &.rotate-icon{
                transform: rotate(180deg);
            }
        }
    }
    .select-pill{
        padding: 4px 10px;
        color: $az_gray_6;
        background: $az_border_color;
        border-radius: 5px;
        margin-right: 8px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        max-width: 56%;
        max-height: 60%;
        p{
            line-height: unset;
            font-size: 13px;
            letter-spacing: 0.78px;
            text-overflow: ellipsis;
            max-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 4px;
            max-width: 88%;
        }
        svg {
            width: 12px;
            height: 12px;
        }
    }
	&.disabled {
        .multi-select-container{
            background: $az_gray_2;
            color: $az_gray_6;
            border: none;
        }
        .select-pill{
            background: $az_gray_3;
        }
	}
    .combo-box .combo-box__input-container {
        width: 100%;
    }
}
