.invoices{
    display: flex;
    justify-content:center;
    padding: 25px 25px 0px 25px;
    height:86vh;
	overflow-y: scroll;

}

.items-container {
    .add-jobs-wrapper {
        padding: 20px 20px 0px;
    }
    .add-jobs-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
        .add-jobs {
            display: flex;
            align-items: center;
        }
    }
}