.line-item{
    margin: 4px 0px;
    .rate-unit-container{
        display: flex;
        align-items: center;
        .slash{
            padding:0px 3px 0px 4px;
            font-size: 18px;
            letter-spacing: 0.72px;
            color: $az_border_color;
        }
    }
    .check-box-container{
        display: flex;
        align-items: center;
        .check-box{
            &:hover {
                .checkmark{
                    border: 1px solid $az_border_color;
                }
                .checkmark::after {
                    border-color: $az_cyan;
                }
                .combo-box__checkbox[type="checkbox"]:checked + .checkmark {
                    border: none;
                }

                .combo-box__checkbox[type="checkbox"]:checked + .checkmark::after {
                    border-color: $az_white;
                }
            }
        }
    }
    .line-delete-button{
        padding:0px;
        justify-content: flex-end;
    }
}