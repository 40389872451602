.category-settings-container {
	margin: 10px 0;

	.flex-gap-30 {
		display: flex;
		gap: 30px;
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 50px;
	}
	.category-layout-container {
		.allCategories {
			width: 40%;
		}
		.groupCategories {
			@extend .flex-gap-30;
			.menu {
				width: 20%;
			}
			.column-container {
				width: 100%;
				@extend .flex-gap-30;
				.group-column {
					flex: 1;
					&.job-status-columns {
						flex: 0.75;
					}
				}
				.all-column {
					flex: 1;
				}
				.divider {
					display: flex;
					align-items: center;
					> div {
						border-right: 1px dashed $az_gray_4;
						height: 80%;
					}
				}
			}
		}
	}

	.category-layout {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.category-table {
		width: 75%;
		.category-head {
			padding: 15px 0;
			border-bottom: 1px solid $az_gray_3;
			display: flex;
			.categoryFields {
				font-weight: 500;
				white-space: nowrap;
				color: #343741;
			}
			.name {
				flex: 0.2;
				span {
					padding-left: 10px;
				}
			}
		}
		.categoryBody {
			padding: 10px 0 0 0;
			.tr {
				display: flex;
				margin-bottom: 10px;
				align-items: center;
			}
			.cell_in {
				padding-right: 30px;
			}
			.name {
				flex: 0.2;
			}

			.icons {
				display: flex;
				align-items: center;

				.accept {
					margin-left: 10px;
				}

				button,
				span {
					min-width: 0;
					cursor: pointer;
					display: flex;
					align-items: center;
					background: none;
					color: $az_gray_4;
					padding: 0;
					width: fit-content;
				}
			}
		}
	}

	.category-settings {
		display: flex;
		margin-top: 16px;
		margin-bottom: 20px;
		width: 80%;

		.category-settings-aside {
			flex: 0.52;
		}

		.category-settings-body {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 12px;

			.radio-btn {
				margin: 0 10px 0 0;
			}

			div {
				display: flex;
				gap: 4px;
			}

			button {
				margin-top: 16px;
				width: fit-content;
				align-self: center;
			}

			.category-settings-icons {
				justify-content: center;
				margin: 30px 0;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
}
