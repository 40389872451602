.group-form {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.group-form-item {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.flex-center {
		display: flex;
		justify-content: center;
	}
	.flex-box {
		display: flex;
		align-items: center;
		gap: 10px;
		color: $az_gray_5;
	}
	.user-layout {
		margin-top: 10px;

		.search-user-container {
			@extend .flex-box;
			width: 60%;
		}

		.user-list-container {
			margin-top: 10px;
			.user-header {
				margin: 10px 0px;
				display: flex;
				gap: 4px;
				border-bottom: 1px solid $az_border_color;
				padding: 10px;
				text-transform: uppercase;
				font-size: 13px;
				> span {
					flex: 1;
					@extend .flex-center;
					gap: 10px;
					align-items: center;
				}
			}
			.users-list {
				max-height: 340px;
				overflow: auto;
				hr {
					border-style: dashed;
				}
				.user-row {
					display: flex;
					align-items: center;
					gap: 4px;
					padding: 10px 0px;

					.user-name {
						flex: 1;
					}
					.user-checkbox {
						flex: 1;
						@extend .flex-center;
					}
				}
			}
		}
	}
}
