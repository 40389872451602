.customers {
	height: auto;
	overflow-y: scroll;
}

.customers-forms {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	overflow-y: scroll;
	height: auto;
	width: 100%;
}

.customer-container {
	width: 70%;
	height: 85vh;
}

.contacts-container {
	background-color: $az_gray_1;
	border-radius: 5px;
}
.contact-header {
	height: 43px;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	text-transform: uppercase;
	font-weight: 400;
	background-color: $az_white;
	border-radius: 5px;
}

.search-add-new {
	display: flex;
	padding: 5px 15px;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0px;
}
.add-contact {
	color: $az_cyan;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 400;
	&.disabled {
		color: $az_gray_5;
		cursor: not-allowed;
	}
}

.primary-billing-area {
	justify-content: space-evenly;
	display: flex;
	align-items: center;
	margin: 5px 0px 10px 0px;
	.select__control {
		min-width: 155px;
	}
}

.contact-location-scroll-area {
	overflow: "auto" !important;
	max-height: "70vh" !important;
	height: "70vh" !important;
}

.locations-area {
	padding: 0px 14px;
}

.no-data-text {
	display: flex;
	justify-content: center;
	margin-top: 20%;
	font-size: 20px;
	font-weight: 500;
}
