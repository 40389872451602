.customer-form {
	// max-height: 100%;	
	min-width: 978px;
	max-width: 978px;
	width: 100%;
}
.customersContainer {
	max-height: 100%;
	max-width: 978px;
	width: 100%;

	.actions {
		margin-right: 20px;
	}
}
