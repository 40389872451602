.tagsContainer {
	overflow: auto;
	display: flex;
	flex-flow: wrap;
	align-items: center;
	.redirectLink {
		color: $az_blue;
		cursor: pointer;
		padding: 5px;
		margin: 6px 10px 6px 0px;
	}
}

.tagViewMode {
	margin: 5px 5px 5px 0px;
	padding: 5px 10px;
	font-size: 13px;
	color: $az_black;
	border-radius: 5px;
	display: inline-block;
	background: $az_white;
	border: 1px solid #ccd3ec;

	.tagText {
		margin-right: 5px;
	}

	.icon-class {
		cursor: pointer;

		svg {
			width: 13px;
			height: 13px;
			color: $az_gray_5 !important;
		}
	}
}

.checkbox-label-container {
	display: flex;
	align-items: center;
	border: 1px solid $az_gray_3;
	padding: 5px 12px;
	border-radius: 25px;
	&:hover {
		background-color: $az_cyan;
	}
}
.tags-listing-container {
	display: flex;
	align-items: center;
}
.dot {
	height: 14px;
	width: 14px;
	border-radius: 50%;
	margin-right: 2px;
	border: 0.5px solid $az_white;
}
