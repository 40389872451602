#payments {
	.payment-settings {
		display: flex;
		margin-top: 16px;
		width: 80%;

		.payment-settings-aside {
			flex: 0.52;
		}

		.payment-settings-body {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 12px;

			.radio-btn {
				margin: 0 10px 0 0;
			}

			div {
				display: flex;
				gap: 4px;
			}

			button {
				margin-top: 16px;
				width: fit-content;
				align-self: center;
			}

			.stax-info {
				display: flex;
				flex-direction: column;
				align-items: center;

				gap: 12px;

				.stax-info-heading {
					text-transform: uppercase;
					align-items: center;
				}
			}

			.payment-settings-icons {
				justify-content: center;
				margin: 30px 0;
				display: flex;
				flex-wrap: wrap;
			}

			.payment-settings-fatt-logo {
				margin-top: 20px;
				height: 45px;
			}

			.payment-settings-arrows {
				width: 85px;
				margin: 18px 25px 0;
			}

			.payment-settings-azuga-logo {
				width: 126px;
				align-self: flex-end;
			}
		}
	}
}
