.tile-header{
    display: flex;
    align-items:flex-start;
    justify-content:space-between;
    .section {
        display: flex;
        width: 100%;
    }

    h4{
        font-size:12px;
        font-weight:500;
        text-transform: uppercase;
        color:$az_gray_6;
        letter-spacing:0.72px;
        margin:0px;
    }
    .description {
        font-size: 14px;
        letter-spacing: 0.78px;
        color: $az_gray_6;
        line-height: 1.5;
    }

    .action-container {
        display: flex;
    }
  
}