@import "./comboCheckBox";
@import "./multiSelectComboBox";
.combo-box-container {
	position: relative;
	display: flex;
	align-items: center;
	&.make-absolute {
		position: absolute;
		width: 100%;
		margin-top: 8px;
		z-index: 10;
	}
	.error-icon {
		position: absolute;
		right: -34px;
		top: 8px;
	}
}
.combo-box {
	@import "./comboList";
	@import "./comboListItem";
	@import "./multiListItem";
	position: relative;
	display: inline-flex;
	transition: 0.3s all ease-in-out;
	border: 1px solid $az_border_color;
	min-height: 35px;
	width: 100%;
	border-radius: 5px;
	box-sizing: border-box;
	background: $az_white;
	align-items: center;
	&.disabled {
		background: $az_gray_2;
		color: #343741;
		border: none;
	}
	&.error {
		border: 1px solid #be3131 !important;
	}
	&.no-error-icon {
		width: 100%;
	}
	&.make-absolute {
		position: absolute;
	}
	.icon {
		margin: 0px 8px;
		height: 18px;
		width: 18px;
		transition: 0.3s all ease-in-out;
		color: $az_cyan;
		&.rotate-icon {
			transform: rotate(180deg);
		}
	}
	&:focus-within {
		border-color: $az_cyan;
	}
	.combo-loader {
		height: 100%;
		width: 20%;
		right: 0;
		left: auto !important;
		z-index: 0 !important;
	}
	.combo-box__input-container {
		width: 85%;
		position: relative;

		.combo-box__input {
			border: none;
			height: 100%;
			background: transparent;
			padding-left: 8px;
			width: 100%;
			color: $az_gray_6;
		}
		.combo-box__input::placeholder {
			color: $az_gray_4;
			font-size: 14px;
		}
		.placeholder,
		.pill-container {
			padding: 0px 8px;
			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	.no-options-message {
		color: $az_gray_4;
		text-align: center;
		width: 100%;
	}
	&.push-down {
		margin-top: 24px;
		z-index: 3;
	}
	&.combo_box__hide {
		margin-top: -35px;
		visibility: hidden;
		z-index: 0;
		opacity: 0;
	}
	.action-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $az_gray_6;
		width: 100%;
		text-transform: none;
		padding-top: 8px;
		svg {
			path {
				fill: $az_cyan;
			}
		}
	}
}
