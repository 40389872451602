.add-payment-modal {
	width: 948px;
	min-width: 948px;
	.left-section,
	.right-section {
		flex: 1;
	}
	.heading-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.modal-heading {
			font-size: 12px;
			font-weight: 600;
			color: $az_gray_6;
			text-transform: uppercase;
		}
	}
	.modal-body {
		display: flex;
		gap: 10%;
		.field {
			display: flex;
			align-items: center;
			gap: 20px;
			justify-content: space-between;
			margin-bottom: 10px;
			.field-key {
				font-size: 13px;
				color: $az_gray_6;
			}
			.hide-iframe {
				iframe {
					display: none;
				}
			}
		}
	}
	.save-payment-button {
		position: relative;
		float: right;
		margin-right: 18px;
		margin-top: 20px;
	}

	.iframe-wrapper{
		display: flex;
		align-items: center;
		.fattjs-iframe {
			border: 1px solid $az_border_color;
			border-radius: 5px;
			height: 35px;
			width: 180px;
		}
		.border-color {
			border: 1px solid $az_red;
		}
	}
}

.payment-history {
	max-width: 978px;
}

.methods-tile {
	.methods {
		display: grid;
    	grid-template-columns: repeat(2, 1fr);
		column-gap: 5%;
	}
	.payment-method {
		border: 1px solid $az_border_color;
		border-radius: 5px;
		opacity: 1;
		padding: 10px;
		font-size: 13px;
		color: $az_gray_6;
		max-width: 440px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:hover {
			border: 1px solid $az_cyan;
		}
		.card-type {
			text-transform: capitalize;
		}
	}
}

.methods-wrapper {
	width: 978px;
	margin: auto;
	margin-bottom: 30px;
}
