.worker-form {
	max-height: 100%;
	max-width: 1200px;
	width: 100%;
	.form-with-side-bar {
		display: flex;
		justify-content: space-between;
	}
	.user-form-tiles {
		max-width: 978px;
		width: 100%;
	}
	.user-action {
		display: flex;
		button {
			margin-left: 8px;
		}
	}
	.switch-user {
		height: 34px;
		display: flex;
		width: 100%;
		align-items: center;
		border-color: $az_border_color;
		transition: 0.2s all ease-in-out;
	}
	.label-toggle-container {
		display: flex;
		.label {
			min-width: 100px;
			margin-right: 16%;
			margin-top: 8px;
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.78px;
			color: $az_gray_6;
			white-space: nowrap;
		}
		.input-area {
			width: 75%;
			.input-container__user {
				margin: 0px;
				height: 35px;
				width: 88%;
				display: inline-flex;
				align-items: center;
				border-color: $az_border_color;
				transition: 0.2s all ease-in-out;
				&.input-container__user:focus-within {
					border-color: $az_cyan;
				}
				input {
					height: 30px;
				}
				input::placeholder {
					color: $az_gray_4;
				}
				&.is-error {
					border-color: $az_maroon;
				}
			}
		}

		.combo-box-container {
			width: 76%;
			.combo-box {
				width: 87%;
			}
		}

		.switch-container__user {
			margin: 0px;
			margin-left: 26px;
			height: 35px;
			width: 100%;
			display: inline-flex;
			align-items: center;
			border-color: $az_border_color;
			transition: 0.2s all ease-in-out;
			&.input-container__user:focus-within {
				border-color: $az_cyan;
			}
			input {
				height: 30px;
			}
			input::placeholder {
				color: $az_gray_4;
			}
			&.is-error {
				border-color: $az_maroon;
			}
		}
	}
	.heading-user {
		font-weight: 400;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		color: $az_gray_6;
		letter-spacing: 0.72px;
		margin: -8px 0px 16px 0px;
	}
	.heading-rates {
		display: flex;
		justify-content: space-between;
		font-weight: 400;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		color: $az_gray_6;
		letter-spacing: 0.72px;
		.edit-rate {
			color: $az_cyan;
			cursor: pointer;
			margin-bottom: 24px;
		}
	}
	.input-passward-container {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.label {
			min-width: 100px;
			margin-right: 16%;
			margin-top: 4px;
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.78px;
			color: $az_gray_6;
			white-space: nowrap;
		}
		.has-icon svg {
			margin-top: 0px;
		}
		.strength {
			font-size: 14px;
			line-height: 19px;
			margin-left: 26px;
			letter-spacing: 0.1px;
			color: $az_gray_4;
			width: 100%;
			margin-top: 5px;
		}
		.reset-password {
			color: $az_cyan;
			width: 100%;
			margin-left: 26px;
			.reset {
				cursor: pointer;
				width: 30%;
				margin-left: 0px;
			}
		}
		.input-area {
			width: 75%;
			.input-container__user {
				margin: 0px;
				height: 35px;
				width: 88%;
				display: flex;
				align-items: center;
				border-color: $az_border_color;
				transition: 0.2s all ease-in-out;
				&.input-container__user:focus-within {
					border-color: $az_cyan;
				}
				input {
					height: 30px;
				}
				input::placeholder {
					color: $az_gray_4;
				}
				&.is-error {
					border-color: $az_maroon;
				}
			}
		}
	}

	.email-link-container {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.label {
			min-width: 100px;
			margin-right: 16%;
			margin-top: 4px;
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.78px;
			color: $az_gray_6;
			white-space: nowrap;
		}
		.resend {
			display: flex;
			align-items: center;
			margin-left: 8px;
			.lastSeen {
				margin-left: 10px;
				font-style: italic;
			}
		}
		.input-area {
			width: 29%;
			.input-container__user {
				margin: 0px;
				height: 35px;
				width: 89%;
				display: flex;
				align-items: center;
				border-color: $az_border_color;
				transition: 0.2s all ease-in-out;
				&.input-container__user:focus-within {
					border-color: $az_cyan;
				}
				input {
					height: 30px;
				}
				input::placeholder {
					color: $az_gray_4;
				}
				&.is-error {
					border-color: $az_maroon;
				}
			}
		}
	}

	.trigger {
		.info-icon {
			margin-left: 8px;
			color: $az_gray_5;
		}
		svg {
			width: 14px;
			height: 14px;
		}
	}

	.username-label-container {
		.username-label {
			font-weight: 500;
			margin-left: 28%;
		}
		.username-container {
			margin: 12px;
			margin-left: 28%;
			display: flex;
			width: 76%;
			flex-wrap: wrap;
			.custom-radio-btn {
				display: flex;
				font-size: 14px;
				margin-right: 24px;
				padding-bottom: 12px;
				input[type="radio"] {
					width: 14px;
					height: 14px;
					margin: 0 8px 0 0;
				}
				input[type="radio"]:after {
					width: 14px;
					height: 14px;
					border-radius: 15px;
					top: 0px;
					left: 0px;
					position: relative;
					content: "";
					display: inline-block;
					visibility: visible;
					border: 2px solid $az_border_color;
				}
				input[type="radio"]:checked:after {
					width: 14px;
					height: 14px;
					border-radius: 15px;
					top: 0px;
					left: 0px;
					position: relative;
					background-color: $az_cyan;
					content: "";
					display: inline-block;
					visibility: visible;
					border: 0px solid $az_white;
				}
				input[disabled]:after {
					background-color: $az_gray_3;
				}
				&.disabled {
					input[type="radio"] {
						cursor: unset;
					}
					input[type="radio"]:after {
						border: 2px solid $az_gray_3;
						cursor: unset;
					}
					input[type="radio"]:checked:after {
						background-color: $az_gray_3;
						cursor: unset;
					}
				}
			}
		}
	}

	.label-value-container {
		.label {
			width: 5%;
		}
		.input-container__user {
			margin: 0px;
			height: 35px;
			width: 36%;
			display: inline-flex;
			align-items: center;
			border-color: $az_border_color;
			transition: 0.2s all ease-in-out;
			&.input-container__user:focus-within {
				border-color: $az_cyan;
			}
			input {
				height: 30px;
			}
			input::placeholder {
				color: $az_gray_4;
			}
			&.is-error {
				border-color: $az_maroon;
			}
		}
		.multi-selector-combo-box {
			width: 26%;
			.combo-box-container {
				width: 100%;
				.combo-box {
					width: 99%;
				}
			}
		}
		.combo-box-container {
			width: 30%;
			.combo-box {
				width: 86%;
			}
		}
	}
	.textarea-container__user {
		align-items: flex-start;
		.label {
			margin-top: 12px;
		}
		.value {
			width: 100%;
		}
		.textarea {
			resize: none;
			border-radius: 5px;
			border-color: $az_border_color;
			transition: 0.2s all ease-in-out;
			&::placeholder {
				color: $az_gray_4;
			}
			&:focus {
				border-color: $az_cyan;
			}
		}
	}
	.header-tile {
		max-height: max-content;
		transition: 0.6s all ease-in-out;
		&.extend-height {
			max-height: 800px;
		}
	}
	.permission-container {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
		.permission-title {
			display: flex;
			padding: 12px 12px 12px 0px;
			.empty-space {
				width: 20%;
			}
			.cell-width {
				width: 16%;
				text-align: center;
			}
		}
		.permissionList {
			max-height: 60vh;
			overflow: auto;
			position: relative;
			.permission-row {
				display: flex;
				padding: 4px;
				.permission-title {
					width: 20%;
				}
				.permission-button {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 16%;
					padding: 12px 12px 12px 0px;
				}
			}
		}

		.collapsible {
			background-color: $az_gray_4;
			color: $az_white;
			cursor: pointer;
			padding: 18px;
			width: 100%;
			border: none;
			text-align: left;
			outline: none;
			font-size: 15px;
		}

		.active,
		.collapsible:hover {
			background-color: $az_gray_6;
		}

		.content {
			padding: 0 18px;
			display: none;
			overflow: hidden;
			background-color: $az_white;
		}
	}

	.mt-16 {
		margin-top: 16px;
	}
}

.roles-privilege-section {
	.tile-header {
		display: none;
	}
	.permission-container {
		.permission-title {
			.cell-width {
				width: 20%;
				font-weight: 400;
			}
		}
		.permissionList {
			.permission-button {
				width: 20% !important;
			}
		}
	}
}
