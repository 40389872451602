.stax-enrollment-modal.migrated {
	.stax-enrollment {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-top: 12px;

		.enrollment-form {
			display: flex;
			justify-content: center;

			.form-left {
				flex-basis: 30%;
			}

			.form-right {
				flex-basis: 30%;
			}

			.field-row {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 15px;
				.field-key {
					flex: 1;
					white-space: nowrap;
				}
				.field-value {
					flex: 1;
					min-width: 200px;
					.input-box-container {
						input {
							border-radius: 5px;
						}
					}
				}
			}
		}

		.enrollment-form-buttons {
			display: flex;
			gap: 12px;
			align-self: flex-end;
		}
	}
}
