@mixin button-animation($variant,$color,$amount){
    @if $variant=="primary"{
        &:hover{
            background:darken($color, $amount);
        }
        &:active{
            background:darken($color, $amount*2);
        }
    }
    @if $variant=="outline"{
        &:hover{
            border:1px solid darken($color,$amount);
            color:darken($color,$amount);
        }
        &:active{
            border:1px solid darken($color, $amount*2);
            color:darken($color, $amount*2);
        }
    }
    @if $variant=="text"{
        &:hover{
            color:darken($color,$amount);
        }
        &:active{
            color:darken($color, $amount*2);
        }
    }
}

.button,button{
    position: relative;
	border: 0;
	height: 35px;
	padding: 0 20px;
	color: $az_white;
	cursor: pointer;
	min-width: 75px;
	text-align: center;
	font-weight: 600;
	border-radius: 3px;
	font-size: 12px;
    letter-spacing: 0.72px;
	white-space: nowrap;
	background: $az_cyan;
	transition: all 0.25s ease;
    text-transform: uppercase;
    display: inline-flex;
    align-items:center;
    justify-content: space-between;
    svg{
        margin: 0px 8px 0px 0px;
    }
    &.right{
        svg{
            margin: 0px 0px 0px 8px;
        }
        flex-direction: row-reverse;
    }
    &.large{
        height: 35px;
        min-width: 125px; 
    }
    @include button-animation("primary",$az_cyan,5);
    &.danger{
        background: $az_maroon;
        @include button-animation("primary",$az_maroon,5);
    }
    &.outline{
        border:1px solid $az_cyan;
        background:none;
        color:$az_cyan;
        @include button-animation("outline",$az_cyan,5);
        &.danger{
            border:1px solid $az_maroon;
            background:none;
            color:$az_maroon;
            @include button-animation("outline",$az_maroon,5);
        }
    }
    &.text{
        min-width: auto;
        background:none;
        color:$az_cyan; 
        @include button-animation("text",$az_cyan,5);
        &.danger{
            background:none;
            color: $az_maroon;
            @include button-animation("text",$az_maroon,5);
        }
        &.underline{
            text-decoration: underline;
        }
    }
    &.button[disabled]{
        background: $az_gray_4;
        cursor: unset;
        &.outline{
            border:1px solid $az_gray_4;
            background:none;
            color:$az_gray_4;
        }
        &.text{
            background:none;
            color:$az_gray_4; 
        }

    }
    &.padding-none {
        padding: 0;
    }
    &.text-transform-none {
        text-transform: none;
    }
}