.filter-icon {
	position: relative;
	svg {
		color: $az_cyan;
		size: 20px;
		height: 20px;
	}
	cursor: pointer;
	.badge {
		background: $az_cyan;
		position: absolute;
		right: -10px;
		top: -10px;
		content: attr(data-count);
		font-size: 51%;
		padding: 0.6em;
		border-radius: 999px;
		line-height: 0.75em;
		color: white;
		text-align: center;
		min-width: 2em;
		font-weight: bold;
		margin: 1px;
		border: 1px solid $az_white;
	}
}

.filter-tab {
	min-width: 450px;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
	padding: 12px 20px;
	color: $az_rpt_table_text;
	background-color: $az_white;
	font-weight: 400;
	font-size: 13px;
	border-radius: 8px;
	position: absolute;
	z-index: 9999;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: 0 1px 8px $az_white;
	box-shadow: 0 1px 8px $az_white;
	opacity: 1;
	-webkit-transition: opacity 0.8s;
	transition: opacity 0.8s;
	border: 1px solid $az-gray_7;
}

.filter-header {
	justify-content: space-between;
	.heading {
		font-weight: 500;
	}
	.clear-filter {
		color: $az_red;
		font-weight: 400;
		cursor: pointer;
	}
}

.filter-content {
	justify-content: space-between;
	.category-list {
		background: $az-gray_1;
		padding: 5px 0px;
		.item {
			cursor: pointer;
			padding: 15px 20px;
			text-align: left;
			border-left: 2px solid transparent;
			text-transform: capitalize;
			&.selected {
				border-left: 2px solid $az_cyan;
				background: $az_white;
				cursor: default;
				color: $az_cyan;
				font-weight: 400;
			}
			&.disabled {
				border-left: 2px solid transparent;
				background: $az-gray_1;
				cursor: not-allowed;
				font-weight: 400;
			}
			svg {
				margin: -5px 3px;
				width: 13px;
				height: 18px;
				cursor: pointer;
			}
		}
	}
	.search-container {
		.search-box-container {
			width: 100%;
		}
	}
	.filter-options {
		.options-list {
			padding: 10px;
			height: 260px;
			overflow-y: scroll;
			.list {
				margin-top: 20px;
				margin-bottom: 20px;
				text-transform: capitalize;
			}
		}
	}
}

.filter-footer {
	display: flex;
	justify-content: end;
	button {
		margin: 5px;
	}
}

.applied-filters {
	margin-top: 20px;
}

.single-pill {
	border: 1px solid $az-gray_7;
	padding: 3px 10px;
	border-radius: 20px;
	background: $az-gray_1;
	position: relative;
	margin: 0px 3px;
	.category {
		font-weight: 500;
	}
	.badge {
		position: absolute;
		right: -9px;
		top: -9px;
		font-size: 14px;
		color: $az-gray_3;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}
}
