.customers {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;
	height: 110vh;
	.listing-page-background {
		background: #ffffff;
		padding-right: 25px;
		padding-left: 25px;
		height: 100%;
	}
}
