.listing-workers-container {
    label {
        font-size: 12px;
    }
    .worker-container {
        margin: 0;
        display: flex;
        align-items: center;
    }
}

.worker-tooltip{
	display: flex;
	justify-content: flex-end;
	label {
		font-size: 12px;
	}
	.worker-container {
		max-width: 250px;
		display: flex;
		flex-wrap: wrap;
		padding-left: 8px;
	}
	.entire-worker {
		display: flex;
		grid-gap: 8px;
		align-items: center;
		padding: 4px 8px;
		border-radius: 50px;
		background-color: $az_gray_1;
		border: 1px solid $az_gray_3;
		margin-right: 4px;
		margin-bottom: 4px;
	}
	.remaining-worker-container {
		display: flex;
		grid-gap: 4px;
		margin-left: 4px;
		padding-left: 2px;
	}
}
