.summary-container{
    .seperator{
        margin-top: 15px;
        width: 100%;
        border-bottom: 1px solid $az_gray_2;
    }
    .summary-body{
        margin-top:15px;
        .summary-rows {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            .column-right {
                width: 70px;
                font-size: 12px;
                font-weight: 500;
                color: $az_gray_6;
                letter-spacing: 0.72px;
                margin: 0px;
                text-transform: capitalize;
            }
            .column-left{
                width:14%;
                &.tax{
                    width: 15%;
                }
            }
            .discount-select-box, .tax-select-box {
                .select__control{
                    min-width: 120px;
                    min-height: 35px;
                }
            }
            .icons-color {
                color: $az_gray_8;
            }
        }


        .summary-discount{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            width:25%;
            .discount-type{
                width: 65%;
            }
            .text-field{
                width:40%
            }
        }
    }
}