
// draggable component class 
.drag-dnd {
    display: flex !important;
    max-width: 200px;
    .draggable-box {
        width: 200px;
        height: 35px;
        display: inline-flex;
        background: $az_white 0% 0% no-repeat padding-box;
        border: 1px solid $az_gray_7;
        border-radius: 5px;
        background: $az_white;
        opacity: 1;
        align-items: center;
        padding-left: 3px;
        font-size: 13px;
        cursor: pointer;
	    margin-bottom: 5px;
    }
    .draggable-box:active{
        border: 1px solid $az_cyan;
    }

    .draggable-box:hover{
        border: 1px solid $az_cyan;
    }

    .draggable-box .drag-icon {
        color: $az_gray_7
    }
    .draggable-box:hover  .drag-icon {
        color: $az_cyan !important;
        
    }
    .draggable-box:active  .drag-icon {
        color: $az_cyan !important;
        
    }

}