.modal-body {
	margin-top: 10px;
	.form-field {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		.date-range-picker-input {
			width: 200px;
			border-radius: 6px;
			border: 1px solid $az_border_color;
		}
		.text-area-styles {
			width: 200px;
			border-radius: 6px;
			border: 1px solid $az_border_color;
		}
	}
	.dates-overlap-validate {
		text-align: right;
		margin: 10px 0 15px;
		label {
			color: $az_red;
			font: 13px;
			letter-spacing: 0.72px;
			font-size: 13px;
			line-height: 18px;
		}
		.invalid-date-message {
			margin-top: 4px;
		}
	}
	.ooo-submit-button {
		position: relative;
		float: right;
		margin-top: 10px;
	}
	.show-error-border {
		border: 1px solid $az_red;
	}
}
