.workerJobTimecard {
	.timecard-body {
		.timecard-columns {
			margin: 10px 0px;
			display: flex;
			font-weight: 500;
			font-size: 13px;
            .column {
                flex: 1
            }
		}
	}
    .timecard-footer {
        display: flex;
        justify-content: space-between;
    }
}

.custom-popper{
    z-index: 20001 !important;
}
