.org-layout {
	display: flex;
	.org-info {
		flex: 100%;
		.label-value-wrapper {
			justify-content: flex-start !important;
			label {
				width: 37% !important;
			}
			.label-required {
				&:after {
					content: "*";
					color: $az_maroon;
					font-size: 14px;
					line-height: 1;
				}
			}
			.sub-label,
			.custom-value {
				margin: 5px 10px 5px 0;
			}
		}
	}
	.logo {
		padding-left: 30px;
		border-left: 1px solid $az_border_color;
		flex: 40%;
	}
}

.logo-upload {
	margin-top: 10px;
	padding-left: 20px;

	.image-wrapper {
		margin-top: 20px;
		position: relative;
		width: fit-content;
		.company-logo {
			width: 150px;
			height: 150px;
			min-width: 150px;
			min-height: 150px;
			border: 1px solid $az_border_color;
			margin-top: 15px;
		}
		.image-label {
			position: absolute;
			width: 30px;
			height: 30px;
			background: $az_cyan;
			border-radius: 50%;
			padding: 4px;
			right: -7px;
			bottom: -7px;
		}
		.select-image-file {
			display: none;
		}
		.close-button {
			width: 24px;
			height: 24px;
			position: absolute;
			left: 112px;
			top: 38px;
			background-color: white;
			padding: 4px;
			border-radius: 50%;
		}
		.upload-logo-label {
			position: relative;
			top: 46%;
			left: 12%;
		}
	}
}
