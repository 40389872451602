.tag-settings-container {
	margin: 10px 0;
	display:flex;

	.flex-center{
		display: flex;
		align-items: center;
	}

	.tag-layout-wrap{
        display:flex;
        justify-content: space-between;
		width: 100%;
		gap: 40px;
    }
	.tag-body-wrap{
		display: flex;
		align-items: flex-start;
		position: relative;
	}
	.tag-layout {
		@extend .flex-center;
		width: 100%;

		.color-container-wrap{
			border: 1px solid $az_border_color;
			border-radius: 5px;
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: space-around;
			background: $az_white;
			height: 35px;
		}

		.color-container{
			width: 20px;
			height: 20px;
			border-radius: 30px;
			cursor: "pointer";
		}

		.disabled-color-input {
			background-color: $az_gray_2;
		}

	}
	.show_inactive{
		width: 14%;
		margin: 20px 0px 20px 0px;
		@extend .flex-center;
		justify-content: space-between;
		position: relative;
		left: 26%;
	}

	.tags_margin{
		margin: 0px 0px 0px 15px;
	}

	.tag-table {
		width: 75%;
		.tag-head {
			padding: 15px 0;
			border-bottom: 1px solid $az_gray_3;
			display: flex;
			.tagFields {
				font-weight: 500;
				white-space: nowrap;
				color: $az_gray_6;
			}
			.name {
				flex: 0.2;
				span {
					padding-left: 10px;
				}
			}
		}
		.tagBody {
			padding: 10px 0 0 0;
			.tr {
				@extend .flex-center;
				margin-bottom: 10px;
			}
			.cell_in {
				padding-right: 30px;
			}
			.name {
				flex: 0.2;
			}

			.icons {
				@extend .flex-center;

				.accept {
					margin-left: 10px;
				}

				button,
				span {
					min-width: 0;
					cursor: pointer;
					@extend .flex-center;
					background: none;
					color: $az_gray_4;
					padding: 0;
					width: fit-content;
				}
			}
		}
	}

	.tag-settings {
		display: flex;
		margin-top: 16px;
		margin-bottom: 20px;
		width: 80%;

		.tag-settings-aside {
			flex: 0.52;
		}

		.tag-settings-body {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 12px;

			.radio-btn {
				margin: 0 10px 0 0;
			}

			div {
				display: flex;
				gap: 4px;
			}

			button {
				margin-top: 16px;
				width: fit-content;
				align-self: center;
			}

			.tag-settings-icons {
				justify-content: center;
				margin: 30px 0;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
}
