.event-logs-page{
    text-align: center;
    color: $az_gray_6;
    display: flex;
    padding: 25px;
    flex-wrap: wrap;
    .event-view-card {
        border: 2px solid $az_gray_4;
        background:$az_white;
        border-radius:5px;
        margin-right: 16px;
        margin-bottom: 16px;
        max-width: 300px;
        padding:24px;
        &.user-event{
            border-color: $az_green;
        }
        &.query-event{
            border-color: $az_yellow;
        }
        &.post-event{
            border-color: $az_yellow;
        }
        &.error{
            border-color: $az_maroon;
        }

        .event-heading{
            font-size: 18px;
            font-weight: 500;
            color:$az_gray_5;
            margin: 8px 0px;
        }

        .event-text{
            font-size: 16px;
            font-weight: 400;
            margin: 8px 0px;
            overflow-wrap: break-word;
            max-width:250px;
            text-transform: lowercase;
        }
        // .event-bus{

        // }
        .event-status{
            text-transform: uppercase;
        }
        // .event-type{

        // }
        .details-block{
            position: relative;
            border-radius: 5px;
            background-color:rgb(0, 22, 40) ;
            color:rgb(125, 203, 196);
            padding:25px;
            overflow:auto;
            code{
                max-width: inherit;
                max-width:250px;
                overflow-wrap: break-word;
            }
            svg{
                height:14px;
                width: 14px;
                position: absolute;
                top: 8px;
                right:8px;
                color: $az_white;
                cursor: pointer;
            }
        }

    }
}