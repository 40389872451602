.job-schedule {
	.job-service-date-time {
		input {
			width: 150px;
		}
		.left {
			right: 140px;
			top: 0px;
		}
		.react-datepicker__close-icon {
			top: 9px;
		}
	}
	.key-value-container {
		margin: 0;
		.combo-box-container {
			width: 150px;
		}

		&.duration-container {
			label {
				font-weight: 500;
			}
			.duration {
				display: flex;
				align-items: center;
				width: 100%;
				.input-area {
					margin-right: 7px;
					width: 55px;
				}

				span {
					margin-right: 10px;
				}
			}
		}
	}
}
