@import "../components//uiComponents/comboBox/comboBox";
.schedule-dispatch {
	color: $az_rpt_black;
	overflow: hidden;
	.schedule-top-bar {
		padding: 8px 25px 8px 15px;
		display: flex;
		align-items: center;
		.schedule-group-select {
			display: flex;
			justify-content: space-between;
			width: 244px;
			margin-right: 24px;
			.schedule-group-level {
				margin-top: 12px;
				font-weight: 500;
				font-size: 15px;
			}
			.multi-selector-combo-box {
				width: 188px;
				.select-pill p{
					line-height: unset;
					font-size: 13px;
					letter-spacing: 0.78px;
					text-overflow: ellipsis;
					max-height: 22px;
					overflow: hidden;
					white-space: nowrap;
					margin-right: 4px;
				}
			}
			.combo-box-container {
                &.make-absolute{
                    z-index: 12000;
                }
            }
			.combo-box {
				.combo-box-list {
					top: 104%;
				}
				.combo-box__input-container .combo-box__input {
					border: none;
					height: 100%;
					background: transparent;
					padding-left: 8px;
					width: 100%;
					color: #343741;
				}
				.combo-box__input-container .combo-box__input::placeholder {
					color: $az_gray_4;
					font-size: 14px;
				}
				&.push-down {
					margin-top: 15px;
				}
			}
		}
		// justify-content: space-between;
		.search-group {
			width: 250px;
			position: relative;
			.search_icon {
				color: rgba($az_black, 0.5);
				position: absolute;
				height: 15px;
				width: 15px;
				top: 0;
				bottom: 0;
				left: 10px;
				margin: auto;
			}
		}
		input[type="text"] {
			transition: all 0.25s;
			@include input($bg_color: $az_white, $border_color: rgba($az_black, 0.5));
			@include input-placeholder-font(rgba($az_black, 0.4));
			color: $az_black;
			padding-left: 40px;
			&:focus {
				padding-left: 15px;
				& + .search_icon {
					@include opacity(0);
				}
			}
			height: auto;
		}
		p {
			line-height: 0;
			margin: 0;
		}
		.config-icon {
			color: $az_cyan;
		}
		.switch-label-icons {
			cursor: pointer;
			margin-left: auto;
			color: $az_cyan;
			display: flex;
			align-items: center;
			margin-right: 16px;
		}
		.schedule-worker-job-switch {
			display: flex;
			align-items: center;
			margin-right: 32px;
			color: $az_rpt_black;
		}
	}
	.shrink {
		animation: 1s shrink forwards;
	}
	.expand {
		animation: 1s expand;
	}
	@keyframes expand {
		from {
			opacity: 0;
			width: 0px;
		}
		to {
			opacity: 1;
			width: 250px;
		}
	}
	@keyframes shrink {
		from {
			width: 250px;
			opacity: 1;
		}
		to {
			width: 0px;
			opacity: 0;
			visibility: hidden;
		}
	}
	.switch-tab-container {
		display: flex;
		text-align: center;
		border: 1px solid $az_cyan;
		border-radius: 3px;
		.tab {
			width: 90px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $az_white;
			cursor: pointer;
			.label {
				color: $az_gray_4;
				cursor: pointer;
			}
		}
		&.animate {
			animation-delay: 2s;
			animation: animate-switch 1s ease-in-out;
		}
	}

	.schedule-content-box {
		display: flex;
		.exit-animation:active .schedule-content-left-panel {
			animation: jobListTransitionReverse 0.3s ease-in-out;
		}
		.schedule-content-left-panel {
			width: 100%;
			height: 100%;
			flex-basis: 35%;
			min-width: 340px;
			animation: jobListTransition 0.3s ease-in-out;
			.schedule-job-list-filter {
				padding: 8px 15px;
				background-color: $az_gray_6;
				.schedule-left-filter {
					align-items: center;
					display: flex;
					margin-bottom: 8px;
					#schedule-filter-label {
						font-weight: 600;
						color: $az_white;
						width: 73px;
					}
				}
				.job-list-show {
					margin-bottom: 0px;
				}
				#schedule-select-group {
					width: 100%;
					flex: 1 0 0;
				}

				.schedule-filter-select {
					.react-select__control {
						border-radius: 25px;
						padding-left: 10px;
						padding-right: 5px;
						background: $az_white;
						.small & {
							min-height: 30px;
							input {
								height: 30px;
							}
						}
						.react-select__indicators {
							max-height: 30px;
							height: 30px;
							margin-right: 8px;
							.react-select__indicator::before {
								max-height: 30px;
								height: 30px;
							}
							.react-select__indicator::after {
								max-height: 30px;
								height: 30px;
							}
						}
					}
				}
			}
			.schedule-job-list {
				height: 66vh;
				// padding: 8px;
				overflow-x: auto;
				.collapsable-list-conatiner {
					margin-bottom: 8px;

					.card-container {
						padding: 8px;
					}
				}
			}
			.available-worker-filter {
				display: flex;
				align-items: center;
				padding: 10px;
			}

			.search-container {
				padding: 4px 15px;
				background:$az_gray_6;
				.search-group {
					position: relative;
					.search_icon {
						color: rgba($az_black, 0.5);
						position: absolute;
						height: 15px;
						width: 15px;
						top: 0;
						bottom: 0;
						left: 10px;
						margin: auto;
					}
				}

				input[type="text"] {
					transition: all 0.25s;
					@include input(
						$bg_color: $az_white,
						$border_color: rgba($az_black, 0.5)
					);
					@include input-placeholder-font(rgba($az_black, 0.4));
					color: $az_black;
					padding-left: 40px;
					&:focus {
						padding-left: 15px;
						& + .search_icon {
							@include opacity(0);
						}
					}
					height: auto;
				}
				p {
					line-height: 0;
					margin: 0;
				}
			}
			.no_jobs {
				margin-top: 35vh;
			}
		}
		.schedule-content-right-panel {
			// background-color;
			width: 100%;
			height: 100%;
			overflow-x: hidden;
			.schedule-grid-view-select-box {
				background-color: $az_white;
				padding: 8px 25px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.grid-view-button-group {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					.schedule-button-pill {
						border-radius: 25px;
						margin-right: 8px;
						height: 28px;
						line-height: inherit;
						width: 70px;
						font-size: 13px;
						font-weight: 500;
						transition: none;
					}
				}
				.available-worker-filter {
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.pointer {
		&:hover {
			cursor: pointer;
		}
	}
	.collapsable-header {
		display: flex;
		align-items: center;
		background-color: $az_gray_6;
		color: $az_white;
		padding: 8px 15px;
		position: sticky;
		top: 0px;
		&.add-cursor {
			cursor: grab;
		}
		&.add-cursor:active {
			cursor: grabbing;
		}
		.job-number {
			margin-left: auto;
		}
		.chevron-container {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 25px;
			background: $az_white;
			margin-left: 8px;
			width: 18px;
			height: 18px;
			cursor: pointer;
			.arrow {
				color: $az_gray_6;
			}
		}
	}

	@keyframes jobListTransition {
		0% {
			flex-basis: 0%;
			opacity: 0;
			min-width: 0;
		}

		100% {
			flex-basis: 35%;
			opacity: 1;
			min-width: 340px;
		}
	}

	@keyframes jobListTransitionReverse {
		0% {
			flex-basis: 35%;
			opacity: 1;
			min-width: 340px;
		}

		100% {
			flex-basis: 0%;
			opacity: 0;
			min-width: 0;
		}
	}
	@keyframes animate-switch {
		0% {
			opacity: 0;
		}

		20% {
			opacity: 0;
		}

		40% {
			opacity: 0;
		}
		60% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.viewBy-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.viewBy-select {
			max-width: 100px;
			min-width: 100px;
			.react-select__control {
				border-radius: 25px !important;
				padding-left: 10px;
				padding-right: 5px;
				background: $az_white;
				max-height: 25px !important;
				max-width: 100px;
				min-height: 25px !important;
				.react-select__value-container {
					max-height: 25px;
					height: 25px;
					.react-select__single-value {
						max-height: 25px;
						height: 25px;
					}
				}
				.react-select__indicators {
					max-height: 25px;
					height: 25px;
					.react-select__indicator::before {
						max-height: 25px;
						height: 25px;
					}
					.react-select__indicator::after {
						max-height: 25px;
						height: 25px;
					}
				}
				.small & {
					min-height: 25px;
					input {
						height: 25px;
					}
				}
			}
			&.remove-padding {
				.react-select__menu {
					.react-select__option {
						padding: 0px 12px;
						text-align: left;
					}
				}
			}
			&.remove-border {
				min-width: 70px;
				max-width: 70px;
				.react-select__control {
					border: unset;
					width: 70px !important;
					padding-left: 4px;
					padding-right: 4px;
					.react-select__value-container--has-value {
						display: flex;
					}
				}
				.react-select__single-value {
					font-weight: 600;
					width: fit-content;
				}
			}

			&.remove-border-workers {
				min-width: 90px;
				max-width: 90px;
				.react-select__control {
					border: unset;
					width: 90px !important;
					padding-left: 4px;
					padding-right: 4px;
					.react-select__value-container--has-value {
						display: flex;
					}
				}
				.react-select__single-value {
					font-weight: 600;
					width: fit-content;
				}
			}
			&.increase-zIndex {
				.react-select__menu {
					z-index: 2;
				}
			}
			&.add-zIndex {
				.react-select__menu {
					z-index: 11010;
				}
			}
			&.schedule-job-viewby-select {
				min-width: 147px;
				max-width: 147px;
				.react-select__control {
					min-width: 147px;
					max-width: 147px;
				}
			}
		}
	}
	.team-header {
		position: sticky;
		top: 35px;
		z-index: 11008;
	}
}
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		.safari-only {
			position: -webkit-sticky !important;
			top: 0px !important;
		}
	}
}
.schedule-worker-menu-list {
	max-height: 300px;
	overflow-y: scroll;
}
