@import './lineItem.scss';
@import './subTotal.scss';

.item-type, .column-type{
    flex: 1;
    min-width: 120px
}

.item-name, .column-search {
    flex: 2
}

.item-warehouse, .column-warehouse {
    flex: 1.75
}

.item-rate, .item-quantity, .item-total, .column-rate, .column-quantity, .column-total {
    flex: 1.25;
};

.item-total, .column-total {
    flex: 1.30;
}

.item-tax-checkbox, .column-tax {
    flex: 0.3;
    align-self: center;
    min-width: 30px
}

.item-trash, .column-trash {
    align-self: center;
    min-width: 20px;
}

.item-trash {
    color: #ADAFB3;
};

.items-container-header {
    .items-columns{
        display: flex;
        width: 100%;
        gap: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid #EEEEEE;
    }
    .action-container {
        width: 100%
    };
}

.add-item-button {
    padding: 0 30px;
    width: 120px
}

.items-container-footer {
    display: flex;
    justify-content: space-between;    
}


// Helper classes
.ml-5 {
    margin-left: 5px;
}

.mt-20 {
    margin-top: 20px
}

.mr-10 {
    margin-right: 10px
}

.mr-20 {
    margin-right: 20px
}

.mr-30 {
    margin-right: 30px
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.padding-20 {
    padding: 20px;
}
