//Form row:

.formRow {
	margin-bottom: 15px;
	&.formRow--no-margin-bottom {
		margin-bottom: 0;
	}
	&.formRow--required {
		.formRow_key {
			&:after {
				content: "*";
				color: $az_maroon;
				font-size: 16px;
				line-height: 1;
			}
		}
	}
	.formRow_actions {
		display: flex;
		justify-content: flex-end;
		button,
		.button {
			& ~ button,
			& ~ .button {
				margin-left: 15px;
			}
		}
	}
	.formRow {
		margin-bottom: 0;
	}
	.countinput {
		position: relative;
		input {
			padding-right: 30px;
		}
		.charCounter {
			font-size: 10px;
			color: $az_gray_4;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			right: 5px;
			display: flex;

			align-items: center;
		}
	}
	label {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.fileItem {
		display: flex;
		align-items: center;
		margin-top: 10px;
		.icon {
			position: relative;
			width: 25px;
			margin-right: 10px;
			img {
				width: 25px;
			}
			> span {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				color: #fff;
				transform: translateY(3px);
				font-size: 7px;
				font-weight: 600;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				justify-content: center;
			}
		}
		svg {
			height: 30px;
			width: 30px;
			cursor: pointer;
		}
		.details {
			width: calc(100% - 60px);
			display: flex;
			flex-direction: column;
			padding: 0;
			.name {
				color: $az_cyan;
				font-size: 13px;
				max-width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin-bottom: 5px;
			}

			.size {
				display: flex;
				align-items: center;
				.size_num {
					width: 20%;
					font-size: 10px;
					color: $az_gray_5;
					margin-right: 10px;
				}
				.progress {
					position: relative;
					background: $az_gray_2;
					width: calc(80% - 10px);
					height: 5px;
					border-radius: 5px;
					overflow: hidden;
					.progress_status {
						background: $az_cyan;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						z-index: 9;
						width: 0;
					}
				}
			}
		}
	}
	.filelist {
		width: 100%;
		height: 100%;
	}
	.formRow_key {
		display: block;
		margin-bottom: 7px;
		color: $az_gray_4;
		font-size: 13px;
		padding-right: 15px;
	}
	&.darkLabel {
		.formRow_key {
			color: $az_black;
			font-weight: 500;
		}
	}
	&.noLabelMargin {
		&.formRow--flex_row {
			.formRow_key {
				margin-top: 0px;
			}
		}
	}
	.formRow_val {
		position: relative;
		.input_label {
			margin-top: 2px;
		}
		&.textareaRel {
			position: relative;

			.charCounter {
				padding: 7px 10px;
				font-size: 13px;
				display: block;
				text-align: right;
				color: $az_gray_4;
			}
		}
	}

	.formRow_val_label {
		color: $az_black;
		line-height: 2;
		font-size: 13px;
		p {
			line-height: 1.5;
		}
	}
	&.formRow_key_right {
		.formRow_key {
			text-align: right;
		}
	}
	&.formRow--flex_row {
		display: flex;
		flex-wrap: wrap;
		.formRow_desc {
			width: 100%;
			font-size: 13px;
			font-weight: normal;
			margin-top: 10px;
		}
		.formRow_key {
			width: 40%;
			flex-grow: 1;

			margin-bottom: 0px;
			margin-top: 5px;
			font-size: 13px;
			line-height: 1.5;
			& + .formRow_val,
			& + .formRow_val_label {
				width: 40%;
				min-width: 150px;
				flex-grow: 1;
				// @include respondToBelow(xs) {
				//   flex-basis: 100%;
				// }
			}
		}

		&.formRow--flex_row_rev {
			flex-direction: row-reverse;
			.formRow_val {
				width: calc(60% - 15px);
				margin-right: 10px;
				margin-left: 0 !important;
			}
		}
		@include respondToBelow(sm) {
			.formRow_desc,
			.formRow_key,
			.formRow_val,
			.formRow_val_label,
			.formRow--flex_row_rev {
				width: 100%;
			}

			.formRow_key {
				margin-bottom: 5px;
				margin-top: 0;
			}
		}
	}
}

// inputs:
.file-custom,
input {
	&[type="text"],
	&[type="password"],
	&[type="number"],
	&[type="email"],
	&[type="date"],
	&[type="url"],
	&[type="time"],
	&.input {
		@include input($bg_color: $az_white, $border_color: rgba($az_black, 0.3));
		@include input-placeholder-font(rgba($az_black, 0.3));
	}
	.gray &,
	&.gray {
		background: $az_gray_1;
		border-color: $az_gray_1;
	}
	[error*=" active"] & {
		border-left: solid $az_red 6px;
	}
	[error*=" invalid active"] & {
		border-left: solid $az_gold 6px;
	}
	.solid &,
	&.solid {
		background: $az_cyan;
		border-color: $az_cyan;
	}
}

//formGroup
.formGroup {
	> .formRow_val {
		display: flex;
		> .formRow {
			& ~ .formRow {
				margin-left: 10px;
			}

			> .formRow_key {
				width: auto;
				&:after {
					content: none;
				}

				& ~ .formRow_val,
				& ~ .formRow_valLabel {
					margin-left: 10px;
				}
			}

			> .formRow_val {
				flex-grow: 1;
			}
			input {
				width: 100%;
			}
			.react-select-container {
				min-width: 60px;
			}
			&.small {
				flex: 0.3 0 0;
			}
			&.phoneType {
				flex: 0.8 0 0;
			}
			&:not(.small) {
				flex: 1 0 0;
			}
		}
	}
}

//Hide input number's arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

//textArea:
textarea {
	@include input($bg_color: $az_white, $border_color: rgba($az_black, 0.3));
	@include input-placeholder-font(rgba($az_black, 0.3));
	font-family: inherit;
	.gray &,
	&.gray {
		background: $az_gray_1;
		border-color: $az_gray_1;
	}
	height: 100px !important;
	&.small,
	.small & {
		height: 60px !important;
	}
	[error*=" active"] & {
		border-left: solid $az_red 6px;
	}
	[error*=" invalid active"] & {
		border-left: solid $az_gold 6px;
	}
}

//Select:
.react-select-container {
	min-width: 150px;

	.react-select__control {
		border-radius: 25px;
		padding-left: 10px;
		padding-right: 5px;
		background: $az_white;
		&.react-select__control--is-disabled {
			background: $az_gray_1;
			.react-select__indicators .react-select__dropdown-indicator:before {
				background-image: url(/icons/downarrow_lightblue.svg);
			}
		}
		.small & {
			border-radius: 3px;
			min-height: 30px;
			input {
				height: 30px;
			}
			.react-select__indicator {
				&.react-select__clear-indicator {
					svg {
						display: none;
					}
					&:after {
						cursor: pointer;
						background-color: #216ba5;
						border-radius: 50%;
						height: 16px;
						width: 16px;
						line-height: 1;
						text-align: center;
						display: table-cell;
						vertical-align: middle;
						content: "\D7";
						color: #bbbbbb;
						background: transparent;
						padding: 0;
						font-size: 20px;
						transform: translateY(-3px);
					}
				}
				&.react-select__dropdown-indicator {
					padding: 0;
					width: 15px;
				}
			}
			.react-select__placeholder,
			.react-select__single-value {
				font-size: 14px;
				line-height: 1.5;
				white-space: nowrap;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.gray & {
			background: $az_gray_1;
			border: none;
			&.react-select__control--is-focused {
				border: none;
			}
		}
		// .xsmall & {
		//   height: 28px;
		//   min-height: 28px;
		// }
		.solid & {
			background: $az_white;
			border-color: $az_cyan;
			.react-select__placeholder,
			.react-select__single-value {
				color: $az_cyan;
			}
			&.react-select__control--is-focused {
				border-color: $az_cyan;
			}
		}
		.rounded & {
			border-radius: 30px;
		}
		[error*=" active"] & {
			border-left: solid $az_red 6px;
		}
		[error*=" invalid active"] & {
			border-left: solid $az_yellow 6px;
		}
		&.react-select__control--is-focused {
			box-shadow: none;
			border-color: #999999;
		}
		.react-select__indicators {
			.react-select__indicator-separator {
				display: none;
			}
			.react-select__dropdown-indicator {
				height: 25px;
				width: 25px;
				position: relative;
				&:before {
					content: "";
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					height: 30px;
					width: 15px;
					margin: auto;
					background: url(/icons/downarrow_blue.svg) center no-repeat;
					background-size: 13px;
					transition: all 0.25s;
				}
				svg {
					display: none;
				}
			}
		}
		&.react-select__control--menu-is-open {
			.react-select__indicators {
				.react-select__dropdown-indicator {
					&:before {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.react-select__value-container {
		&.react-select__value-container--is-multi {
			padding-top: 5px;
			// .xsmall & {
			//   padding-top: 2px;
			// }
		}
		> div {
			margin: 0;
			padding: 0;
			&.react-select__multi-value {
				padding: 2px;
				padding-left: 5px;
				display: flex;
				align-items: center;
				margin: 5px;
				margin-left: 0;
				margin-top: 0;
				> div {
					padding: 0;

					// .xsmall & {
					//   font-size: 12px;
					//   line-height: 1;

					//   svg {
					//     height: 14px;
					//     width: 14px;
					//   }
					// }
					~ div {
						margin-left: 5px;
					}
				}
			}
		}
		padding: 0;
		.react-select__input {
			input {
				height: 28px;
				box-shadow: none;
				margin: 0 !important;
				border: none !important;
			}
		}
		& + .react-select__indicators {
			> * {
				margin: 0;
				padding: 0;
			}
		}
	}
}
body {
	.react-select__menu {
		height: auto;
		.react-select__group-heading {
			font-size: 13px;
			font-family: inherit;
			text-transform: none;
			font-weight: normal;
			color: #a0a0a0;
			margin-bottom: 10px;
		}
	}
	.react-select__menu-list {
		.react-select__option {
			&.react-select__option--is-focused {
				background: $az_cyan;
				color: $az_white;
			}
		}
	}
}
.tagSelector {
	.labelRow {
		width: 60%;
	}
	.formRow.formRow--flex_row {
		align-items: center;
		flex-wrap: nowrap;
		.formRow_key {
			width: 17%;
			margin: 0;
			padding: 0;
			margin-right: 25px;
			.azTitle {
				display: flex;
				margin: 0 !important;
				align-items: center;
				color: $az_black;
				svg {
					margin-right: 5px;
					margin-left: -5px;
				}
			}
		}
	}
	.tagClear {
		font-size: 13px;
		font-weight: normal;
		color: $az_cyan;
		display: inline-block;
		margin-left: 15px;
		cursor: pointer;
	}
	.tagList {
		.tagItem {
			background: $az_gray_2;
			color: $az_black;
			padding: 8px 10px;
			margin-top: 8px;
			margin-right: 8px;
			border-radius: 3px;
			display: inline-block;
			font-size: 13px;
			cursor: pointer;
			line-height: 1;
			&.tagItem_closable {
				display: inline-flex;
				align-items: center;
				.dot {
					margin-right: 7px;
					height: 12px;
					min-width: 12px;
					border-radius: 12px;
				}
				&:after {
					content: "X";
					margin-left: 7px;
					transform: scaleY(0.8);
					font-weight: 600;
					cursor: pointer;
				}
			}
			&.tagItem_readOnly {
				margin-top: 10px;
				margin-right: 10px;
				display: inline-flex;
				align-items: center;
				background: #fff;
				border-radius: 25px;
				cursor: default;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
				display: inline-flex;
				max-width: 100%;
				.dot {
					margin-right: 7px;
					height: 12px;
					min-width: 12px;
					border-radius: 12px;
				}
				.tagLabel {
					white-space: pre-wrap;
					word-break: break-all;
					line-height: 1.1;
					overflow: hidden;
					max-width: 100%;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
	.react-select-container {
		.react-select__value-container {
			div.react-select__multi-value {
				display: none;
			}
			&.react-select__value-container--is-multi {
				padding-top: 0;
			}
		}
	}
}

// Switch:

.switch {
	display: inline-block;
	width: auto;
	&.disabled {
		@include opacity(0.5);
		pointer-events: none;
	}
	.input_label {
		display: flex !important;
		cursor: pointer;
		align-items: flex-start;
		.input_title {
			margin-top: 6px !important;
			line-height: 1;
		}
		&.textLabel {
			.switchBool {
				margin-right: 7px;
			}
		}
		.input_icon {
			position: relative;
			height: 25px;
			width: 25px;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 25px;
				border-radius: 30px;
				height: 15px;
				border: solid 2px #aaa;
				margin-top: 5px;
				transition: all 0.25s;
			}
			&:after {
				content: "";
				position: absolute;
				width: 15px;
				top: 0px;
				left: 0;
				bottom: 0;
				margin: auto;
				transition: all 0.25s;
				border-radius: 30px;
				height: 15px;
				border: solid 2px #aaa;
			}
			[error*=" active"] & {
				&:before,
				&:after {
					border: solid $az_red 2px;
				}
			}
			[error*=" invalid active"] & {
				&:before,
				&:after {
					border: solid $az_gold 2px;
				}
			}
		}
		.switchBool {
			display: flex;
			align-items: center;
			.bool {
				margin-left: 7px;
			}
		}
	}
	&.right {
		.switchBool {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			.bool {
				margin-right: 7px;
				margin-left: 0;
			}
		}
		.input_label {
			flex-direction: row-reverse;
			justify-content: flex-end;
			&.textLabel {
				.switchBool {
					margin-left: 10px;
					margin-right: 0px;
				}
			}
		}
	}
	input {
		@include opacity(0);
		position: absolute;
		pointer-events: none;
		&:checked + span {
			.input_icon {
				&:before {
					background: $az_cyan;
					border-color: $az_cyan;
				}
				&:after {
					background: $az_white;
					border-color: $az_cyan;
					left: 10px;
				}
			}
		}
	}
}

.checkbox {
	display: flex !important;
	align-items: center;
	&.disabled {
		@include opacity(0.5);
		pointer-events: none;
		.input_label {
			.input_icon {
				&:before {
					content: "";
					border: solid 1px $az_gray_3;
					background: $az_gray_3;
				}
			}
		}
		&:checked + span {
			.input_icon {
				&:before {
					background: $az_cyan;
					border-color: $az_cyan;
				}
			}
		}
	}
	.input_label {
		display: flex !important;
		cursor: pointer;
		align-items: flex-start;
		&.textLabel {
			.input_icon {
				margin-right: 7px;
			}
		}
		.input_title {
			margin-top: 3px;
			line-height: 1;
		}
		.input_icon {
			position: relative;
			height: 20px;
			width: 20px;
			[error*=" active"] & {
				&:before {
					border: solid $az_red 2px;
				}
			}
			[error*=" invalid active"] & {
				&:before {
					border: solid $az_gold 2px;
				}
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 20px;
				border-radius: 5px;
				height: 20px;
				border: solid 1px #aaa;
				background: #fff;
				transition: all 0.25s;
				left: 0;
				right: 0;
			}
			&:after {
				content: "";
				position: absolute;
				width: 10px;
				height: 5px;
				top: -2px;
				left: 23%;
				bottom: 0;
				right: 4px;
				margin: auto;
				transition: all 0.25s;
				border-radius: 0;
				border: solid 1.5px #fff;
				border-top: 0;
				border-right: 0;
				transition: all 0.25s;
				transform: scaleX(0) rotate(-45deg);
			}
		}
	}
	&.right {
		.input_label {
			flex-direction: row-reverse;
			&.textLabel {
				.input_icon {
					margin-left: 5px;
					margin-right: 0px;
				}
			}
		}
	}
	input {
		@include opacity(0);
		position: absolute;
		pointer-events: none;
		&:checked + span {
			.input_icon {
				&:before {
					background: $az_cyan !important;
					border-color: $az_cyan !important;
				}
				&:after {
					transform: scaleX(1) rotate(-45deg);
				}
			}
		}
	}
}

.radio {
	display: flex !important;
	align-items: center;
	&.disabled {
		@include opacity(0.5);
		pointer-events: none;
	}
	.input_label {
		display: flex !important;
		cursor: pointer;
		align-items: flex-start;
		&.textLabel {
			.input_icon {
				margin-right: 7px;
			}
		}
		.input_title {
			display: inline-block;
			margin-top: 6px;
			line-height: 1;
		}
		.input_icon {
			position: relative;
			height: 25px;
			width: 25px;
			margin-right: 7px;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				width: 15px;
				border-radius: 15px;
				height: 15px;
				border: solid 1px #aaa;
				background: #fff;
				transition: all 0.25s;
			}
			[error*=" active"] & {
				&:before {
					border: solid $az_red 2px;
				}
			}
			[error*=" invalid active"] & {
				&:before {
					border: solid $az_gold 2px;
				}
			}
			&:after {
				content: "";
				position: absolute;
				width: 7px;
				height: 7px;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				border-radius: 15px;
				transition: all 0.25s;
				transform: scale(0);
			}
		}
	}
	&.right {
		.input_label {
			flex-direction: row-reverse;
			&.textLabel {
				.input_icon {
					margin-left: 10px;
					margin-right: 0px;
				}
			}
		}
	}
	input {
		@include opacity(0);
		position: absolute;
		pointer-events: none;
		&:checked + span {
			.input_icon {
				&:after {
					background: $az_cyan;
					transform: scaleX(1);
				}
			}
		}
	}
}

.formSection {
	position: relative;
	padding: 15px 0;
	.title {
		color: $az_gray_6;
		margin-bottom: 15px;
		display: block;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		.action {
			display: flex;
			flex-basis: 100%;
			cursor: pointer;
			svg {
				height: 25px;
				width: 25px;
			}
		}
		.contactActions {
			display: flex;
			flex-basis: 100%;
			margin-left: 35px !important;
			flex-wrap: wrap;

			@include respondToBelow(sm) {
				margin-left: 0px !important;
			}
			.formRow {
				margin: 0;
				.formRow_key {
					margin-right: 10px;
					width: auto;
				}
				& ~ .formRow {
					margin-left: 15px;
					@include respondToBelow(xs) {
						margin-left: 0px !important;
					}
				}
			}
		}
	}
	& ~ .formSection {
		border-top: solid 2px $az_border_color;
	}
}

.file {
	position: relative;
	display: inline-block;
	cursor: pointer;
	height: 2.5rem;
	width: 100%;
	input {
		margin: 0;
		@include opacity(0);
		display: none;
	}

	.file-custom {
		padding: 0 10px !important;
		border-color: rgba($az_black, 0.3) !important;
		display: flex !important;
		align-items: center;
		position: relative;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		width: 100%;
		user-select: none;
		&:after {
			content: attr(title-label);
		}
		&:before {
			position: absolute;
			top: -1px;
			right: -1px;
			bottom: -1px;
			z-index: 6;
			padding: 0 10px;
			content: "Browse";
			display: flex;
			align-items: center;
			color: white;
			background-color: $az_cyan;
			border-radius: 0 3px 3px 0;
		}
	}
	[error*=" active"] .file-custom {
		border-left: solid $az_red 6px !important;
	}
}

/*
 * Progress
 */

.progress {
	display: inline-block;
	height: 1rem;
}
.progress[value] {
	/* Reset the default appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Remove Firefox and Opera border */
	border: 0;
	/* IE10 uses `color` to set the bar background-color */
	color: #0074d9;
}
.progress[value]::-webkit-progress-bar {
	background-color: #eee;
	border-radius: 0.2rem;
}
.progress[value]::-webkit-progress-value {
	background-color: #0074d9;
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}
.progress[value="100"]::-webkit-progress-value {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.noWrap {
	white-space: nowrap;
}

.tabCheckList {
	display: flex;

	.tabCheckItem {
		flex: 1 0 0;
		position: relative;
		cursor: pointer;
		& ~ .tabCheckItem {
			margin-left: 10px;
			@include respondToBelow(sm) {
				margin-left: 7px;
			}
		}

		span {
			background: $az_gray_2;
			padding: 5px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			color: $az_gray_5;
		}
		input {
			position: absolute;
			left: 0;
			right: 0;
			pointer-events: none;
			opacity: 0;
			visibility: hidden;
			&:checked + span {
				background: $az_cyan;
				color: #fff;
			}
		}
	}
}

.searchSelect {
	.react-select-container .react-select__control {
		padding-left: 28px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 28px;
			width: 28px;
			background: url(/icons/search.svg) center no-repeat;
			background-size: 15px;
		}
	}
}

.input-button {
	width: 300px;
	display: flex;
	border: 1px solid rgba($az_black, 0.3);
	overflow: hidden;
	border-radius: 3px;

	&--required {
		border-left: solid $az_red 6px;
	}

	&--invalid {
		border-left: solid $az_yellow 6px;
	}

	> button {
		min-width: 50px;
		height: auto;
		line-height: initial;
		border-radius: 0px;
	}

	> input {
		height: 28px;
		border-width: 0px;
		border-radius: 0px;
	}
}

//AutoSuggest Styles
.small {
	.react-autosuggest__container {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 28px;
			width: 28px;
			background: url(/icons/search.svg) center no-repeat;
			background-size: 15px;
			bottom: 0;
			margin: auto;
		}
	}

	.react-autosuggest__input {
		padding: 10px 20px 10px 28px;
		font-weight: 300;
	}

	.react-autosuggest__input--focused {
		outline: none;
	}

	.react-autosuggest__input--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		background-color: #fff;
		font-size: 13px;
		z-index: 2;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		max-height: 40vh;
		overflow: auto;
		> div {
			&:first-child {
				padding: 5px 15px;
				color: $az_gray_4;
			}
		}
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 5px 15px;
		span[style*="font-weight"] {
			font-weight: 500;
		}
	}

	.react-autosuggest__suggestion--highlighted {
		background: $az_gray_2;
	}
}

body > div[class*="css-"] {
	z-index: 99999;
}

.input_icon {
	input {
		padding-right: 30px;
	}

	&__icon {
		position: absolute;
		top: 7px;
		right: 10px;
		color: $az_cyan;
	}
}

.isJobsNewUI {
	.jobs_details_view .formRow.divideView_items.formRow--flex_row {
		.formRow_key {
			width: 18%;
		}
	}

	.formRow.formRow--flex_row .react-select-container .react-select__control {
		border-radius: 3px;
	}
}
