.numbering-settings-container {
	margin: 10px 0;
    display: flex;

	.numbering-layout-wrap{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.numbering-layout {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0px 10px;

		.numbering_cell{
			width: 70px;
		}
	}
}

.add-numbering-btn{
	margin-top: 10px;
}

