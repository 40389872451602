.menu-item{
    padding:8px 16px;
    text-align: left;
    letter-spacing: 0.78px;
    transition: 0.1s all ease-in-out ;
    cursor: pointer;
    &:hover{
        background:$az_cyan;
        color:$az_white;
    }
    &:active{
        background:$az_accent_active;
    }
    &.show-all-menu-items {
        display: inline-block;
        position: relative;
        width: 100%;
    }
}
