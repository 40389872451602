@import "./jobSchedule";
@import "./workerSelection";
@import "./jobWorker";
.schedule-job-modal {
	width: 50vw !important;
	.modal-body {
		min-height: 250px;
	}
	.header {
		font-size: 18px;
		letter-spacing: 1.08px;
		color: $az_gray_6;
		font-weight: 500;
	}
	.grid-container {
		display: grid;
		grid-template-columns: 50% 50%;
	}
	.key-value-container {
		display: grid;
		color: $az_gray_6;
		grid-template-columns: 40% 60%;
		padding: 7px 0px;
		align-items: center;
		.key {
			font-weight: 500;
		}
		p {
			width: 100%;
			font-size: 14px;
		}
		.tag-view-container {
			flex-wrap: nowrap;
		}
	}
	.footer {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		.footer-link-container {
			display: flex;
			align-items: baseline;
			color: $az_gray_6;
			.click-here {
				text-decoration: underline;
				cursor: pointer;
				color: $az_blue;
				margin-right: 8px;
			}
		}
		.modal-action-container {
			button {
				margin-left: 8px;
			}
		}
	}
}
