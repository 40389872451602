.add-job-container{
    .label-value-container{
        gap:8%;
        .label{
            min-width: 165px;
        }
    }
    .check-job-container{
        display: flex;
        gap: 8%;
    }
}