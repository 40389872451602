.easy-link-button{
    margin-left: 15px;
    border: 1px solid $az_white;
    border-radius: 5px;
    .easy-link-menu{
        button{
            height:30px;
            border-radius: 5px;
            padding: 0px 12px
        }
        .menu-list{
            min-width: 130px;
            margin-top: 1px;
            border-radius: 5px;
            background: #75777d;
            color: $az_white;
            padding:0px;
            text-transform: capitalize;
        }
    }
}