.skills {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;
	.skills-listing-page {
		max-width: 75%;;
		width: 100%;

		.rdt_Table {
			padding: 12px 0;
		}

		.rdt_TableCell {
			position: relative;
			.delete-icon {
				position: absolute;
				right: 0;
				top: 10px;
				color: $az_gray_9;
				display: flex;
				align-items: center;
				margin-right: 30%;
			}
		}

		.rdt_TableRow:hover {
			.delete-icon {
				svg {
					path {
						fill: $az_white;
					}
				}
			}
		}

		.skills-listing-table {
			margin-left: -25px;
		}

		.formRow_actions {
			button {
				background-color: $az_maroon;
			}
		}
	}
}

.new-styles {
	.skill-close-icon {
		svg {
			color: $az_black !important;
		}
	}

	.skills-input-container {
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 16px 0;
		width: 30%;

		label {
			white-space: nowrap;
		}
	}

	.skills-search-container {
		display: flex;
		gap: 12px;
		padding: 16px 0;
	}

	.skills-users-container {
		display: inline-block;
		padding: 24px 0;
		max-height: 250px;
		min-width: 100%;
		overflow-y: scroll;
	}

	.skills-users-container_item {
		padding: 16px 0;
		width: 25%;
		display: inline-flex;
		align-items: center;
		gap: 12px;

		.skill-checkbox {
			width: 15px;
			height: 15px;
		}

		.skill-label {
			text-align: left;
			font-size: 14px;
			letter-spacing: 0.96px;
			color: $az_gray_6;
			opacity: 1;
			&.font-weight-medium {
				font-weight: 500;
			}
		}
	}

	.buttons-container {
		display: flex;
		padding-top: 32px;
		gap: 16px;
		justify-content: flex-end;
	}
}

.animate {
	margin-top: 64px;
	visibility: hidden;
	animation: entryAnimation 0.3s ease-in forwards;
	@keyframes entryAnimation {
		from {
			margin-top: 64px;
			visibility: hidden;
		}
		to {
			visibility: visible;
		}
	}
}
