.payments-settings {
	&__container {
		display: flex;
	}
	&__status-container {
		width: 100%;
	}
	&__activity-log {
		width: 100%;
	}
	&__info {
		width: 600px;
		text-align: center;
	}

	&__icons {
		margin: 40px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__fatt-logo {
		margin-top: 20px;
		height: 45px;
	}

	&__arrows {
		width: 85px;
		margin: 18px 25px 0;
	}

	&__azuga-logo {
		width: 126px;
		align-self: flex-end;
	}

	&__link_container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__link {
		margin: 10px 6px 0;
		font-family: inherit;
	}

	&__enrollment-container {
		margin: 20px 0 10px;
		padding-top: 15px;
		border-top: 2px solid $az_gray_1;
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 55px !important;

		&--animated {
			opacity: 0;
			color: $az_cyan;
			transition: all 0.25s;
			transform: translateX(10px);
		}
	}

	&__icon {
		cursor: pointer;

		& ~ & {
			margin-left: 15px;
		}

		&--enrollment {
			width: 22px;
			height: 18px;
		}
	}

	&__row--hover:hover &__actions--animated {
		opacity: 1;
		transform: translateX(0px);
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	&__validation-block {
		margin-bottom: 15px;

		& > .alertBlock {
			margin-bottom: 0px;
		}
	}

	&__input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		margin-bottom: 15px;

		.formRow_key {
			width: 40%;
			min-width: 140px;
		}

		.formRow_val {
			flex: 1;
			width: 60%;
			min-width: 150px;

			&--label {
				margin-left: 10px;
			}
		}
	}

	&__enrollment-footer {
		display: flex;
		margin-top: 20px;
		padding-top: 10px;
		border-top: 1px solid $az_gray_1;
	}

	&__redirect {
		color: $az_cyan;
		cursor: pointer;
		font-size: 14px;
		align-self: center;
	}

	&__btn {
		margin-left: auto;
	}

	&__btn-back {
		display: flex !important;
		align-items: center;
		padding-left: 5px !important;
		padding-right: 10px !important;
	}

	&__back-icon {
		margin-right: 5px;
	}

	&__status {
		font-size: 17px;
		font-weight: 500;
		text-align: center;

		&--group {
			font-size: 14px;
			text-align: left;
		}

		.incomplete,
		.pending {
			color: $az_yellow;
		}

		.pended,
		.rejected {
			color: $az_red;
		}

		.approved,
		.active {
			color: $az_green;
		}

		.copied {
			color: $az_gray_4;
		}
	}

	&__status-info {
		margin-top: 12px;
		text-align: center;
		color: $az_gray_5;

		&--link {
			display: block;
			color: $az_cyan;
			cursor: pointer;
			width: fit-content;
			margin: 12px auto 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__obs {
		font-size: 13px;
		color: $az_gray_5;
	}

	&__table {
		overflow: auto;
		max-height: 35vh;
		border: 1px solid $az_gray_3;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__row {
		display: flex;
		min-width: fit-content;

		gap: 25px;
		margin: 3px 0;

		font-size: 13px;
		padding: 15px 30px;

		&--header {
			margin: 0;
			color: $az_white;
			font-weight: bold;
			padding: 15px 40px;
			background: $az_gray_6;
		}

		&--form {
			padding: 9px 30px;

			.groupName {
				margin-top: 5px;
			}
		}

		&--hover {
			margin: 3px 10px;

			& ~ & {
				border-top: 2px solid $az_gray_1;
			}

			&:hover {
				background: transparent;
				position: relative;
				border-radius: 8px;
				box-shadow: 0 0 0 1.5px #00a3e0, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
					0 7px 10px -5px rgba(0, 0, 0, 0.25);
			}
		}

		.formRow {
			margin-bottom: 0;
		}

		> div {
			flex: 1;
			min-width: 120px;
		}
	}

	&__loader {
		margin: 0 5px 5px;
	}

	&__scroll {
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 11px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 11px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid $az_white;
			background-color: rgba(0, 0, 0, 0.5);
		}

		&::-webkit-scrollbar-track {
			border-radius: 8px;
			background-color: $az_white;
		}
	}
}

.stax-enrollment {
	.form-heading {
		text-align: center;
		margin-bottom: 40px;
		font-weight: 500;
	}

	.enrollment-form {
		display: flex;
		gap: 10%;
		margin-top: 24px;
		justify-content: center;

		.form-left {
			flex-basis: 30%;
		}

		.form-right {
			flex-basis: 30%;
		}

		.field-row {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 15px;
			.field-key {
				flex: 1;
				white-space: nowrap;
			}
			.field-value {
				flex: 1;
				min-width: 200px;
				.input-box-container {
					input {
						border-radius: 5px;
					}
				}
			}
		}
	}

	.enroll-to-stax-button {
		left: calc(50% - 125px);
		margin: 20px 0 10px;
		padding: 0 20px;
		text-transform: uppercase;
		font-size: 12px;
	}
}

.stax-enroll-initiated {
	text-align: center;
	h3 {
		font-weight: 500;
	}
	.para {
		font-size: 18px;
		width: 600px;
		text-align: center;
		margin-bottom: 30px;
	}
}

.stax-payment-enabled {
	text-align: center;
	h3 {
		font-weight: 500;
	}
	.para {
		font-size: 18px;
		width: 600px;
		text-align: center;
	}
	.link-stax-button {
		margin-top: 30px;
		text-transform: uppercase;
		font-size: 12px;
		margin-bottom: 30px;
		padding: 0px 20px;
	}
}
