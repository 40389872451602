@import "./menuButton";
@import "./menuList";
@import "./menuItem";
@import "./menuSubItem";
.menu{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    color:$az_gray_6;
    &.center{
        align-items:center;
    }
    &.right{
        align-items:flex-end;
    }
}