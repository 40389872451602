.line-item-container {
	.line-item-container__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
		.line-item-container__header-label {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			color: $az_gray_6;
			letter-spacing: 0.72px;
			margin: 0px;
		}
		.line-item-container__header-action {
            button{
                padding:0px
            }
		}
	}
    .line-item__grid{
        display: grid;
        grid-template-columns: 14% 24% 22% 10% 4% 14% 5%;
        grid-column-gap: 1.2%;
        .heading{
            font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			color: $az_gray_6;
			letter-spacing: 0.72px;
			margin: 8px 0px;
        }
    }
}
