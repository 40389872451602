.invoice-to-print{
    display: flex;
    justify-content: center;
    .invoice-view{
        width: 90%;
    }
    .invoice-heading{
        display:flex;
        justify-content:center;
        align-items:center;
        padding: 16px;
        .heading{
            font-size:40px
        }
    }
    .invoice-primary-details{
        .invoice-heading-container{
            display:flex;
            .invoice-number{
                font-size:28px;
                line-height:34px;
                letter-spacing:1.68px;
                margin-right: 16px;
            }
            .green{
                color:$az_green;
            }
            margin-bottom: 16px;
        }
        .primary-details-container{
            display: grid;
            grid-template-columns: auto auto;
            .column{
                .label-value-container{
                    display: grid;
                    grid-template-columns: 150px max-content;
                    grid-column-gap: 50px;
                    grid-row-gap:8px;
                    label{
                        font-size:  13px;
                        line-height: 28px;
                        letter-spacing: 0.78px;
                        color: #343741;
                        opacity: 0.4;
                    }
                    h4{
                        font-size:  13px;
                        line-height: 28px;
                        letter-spacing: 0.78px;
                        margin:0px;
                    }
                }
            }
        }
        .label-value-container{
            display: flex;
            flex-direction: column;
            label{
                font-size:  13px;
                line-height: 28px;
                letter-spacing: 0.78px;
                color: #343741;
                opacity: 0.4;
            }
            h4{
                font-size:  13px;
                line-height: 28px;
                letter-spacing: 0.78px;
            }
        }
    }
    .invoice-line-items{
        margin-top: 8%;
        .line-item__grid{
            display: grid;
            grid-template-columns: 50% 10% 10% 11% 15%;
            grid-column-gap: 8px;
            margin-top: 8px;
        }
        .header-container{
            .heading{
                text-transform: uppercase;
                font-size: 12px;
                line-height: 18px ;
                font-weight: 700;
                letter-spacing: 0.72px;
                margin: 0px;
                margin-bottom: 8px;
            }
        }
        .border-separator{
            height:2px;
            border-radius:25px;
            background:$az_gray_2;
        }
        .line-item-container{
            .value{
                padding:8px 0px;
                font-size: 13px;
                line-height: 18px ;
                letter-spacing: 0.72px;
                margin: 0px;
            }
        }

    }
    .invoice-total{
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        .total-container{
            width:215px;
            .label-value-container{
                display: grid;
                grid-template-columns:50% 50%;
                padding:4px 6px 4px 0px;
                label{
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: 0.78px;
                }
                h4{
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: 0.78px;
                    text-align: right;
                    margin:0px;
                }
            }
        }
    }
}