.schedule-dispatch-job-card-tag {
	display: flex;
	justify-content: flex-end;
	label {
		font-size: 12px;
	}
	.full-tag-container {
		max-width: 250px;
		display: flex;
		flex-wrap: wrap;
		padding-left: 8px;
	}
	.full-tag {
		display: flex;
		grid-gap: 8px;
		align-items: center;
		padding: 4px 8px;
		border-radius: 50px;
		background-color: $az_gray_1;
		border: 1px solid $az_gray_3;
		margin-right: 4px;
		margin-bottom: 4px;
	}
	.dot-container {
		display: flex;
		grid-gap: 4px;
		margin-left: 4px;
		padding-left: 2px;
	}
	.dots {
		height: 12px;
		min-height: 12px;
		max-height: 12px;

		width: 12px;
		min-width: 12px;
		max-width: 12px;
		border-radius: 50%;
	}
	&.vertical {
		position: absolute;
		top: 2px;
		left: -1px;
		.dot-container {
			flex-direction: column;
			label {
				font-size: 8px;
				line-height: 4px;
			}
		}
		.dots {
			height: 8px;
			width: 8px;
			border-radius: 50%;
		}
	}
}
