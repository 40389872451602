.tabs-body {
	margin: 20px 0px;
	span {
		padding: 10px 30px;
		cursor: pointer;
		border-radius: 30px;
		&.active {
			background-color: $az_cyan;
			color: white;
		}
	}
}

.recipients-section {
	margin-top: 50px;
	.heading {
		font-weight: 500;
		color: $az_gray_6;
		margin-bottom: -10px;
	}
	.add-Recipient {
		margin-top: -20px;
		color: $az_cyan;
		cursor: pointer;
		font-weight: 500;
	}
	.is-4 {
		.select__control {
			border-bottom-right-radius: 0%;
			border-top-right-radius: 0%;
			height: 50px !important;
			background: $az_very_light_blue;
		}
	}
	.recipients-area {
		align-items: center;
		margin: 30px 0px;
		.remove-row {
			margin-left: 10px;
			svg {
				color: $az_gray_4;
				cursor: pointer;
			}
		}
	}
	.selected-recipients-area {
		.select__control {
			border-bottom-left-radius: 0%;
			border-top-left-radius: 0%;
			height: 50px !important;
		}
		.multiValue-select-box .select__value-container--has-value {
			overflow: scroll;
			height: -webkit-fill-available;
			max-height: 90px;
		}

		.single-pill {
			margin: 1px 3px;
		}
		.badge-options {
			position: absolute;
			right: -9px;
			top: -9px;
			font-size: 14px;
			color: #d8d8d8;
			text-align: center;
			font-weight: 500;
			cursor: pointer;
		}
		.badge {
			right: 0px;
			top: -7px;
			svg {
				width: 12px;
				height: 12px;
			}
		}
	}

	.user-recipient {
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
		border-radius: 13px;
		margin: 2px;
		.label {
			font-weight: 500;
			font-size: 13px;
			margin-left: 0px;
		}
		.email {
			color: $az_gray_5;
			font-size: 10px;
			margin-top: 1px;
		}
	}
}

.subject-line-section,
.notification-message-section {
	.header-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.heading {
		font-weight: 500;
		color: $az_gray_6;
		margin-bottom: 20px;
	}
	.sub-heading {
		color: $az_gray_4;
		font-weight: 400;
	}
	.options {
		margin-left: 10px !important;
	}
}

.notification-action-section {
	display: flex;
	justify-content: flex-end;
}
