.groups-modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.groups-search-wrap {
		display: flex;
		align-items: center;
	}
}
.groups-body {
	margin: 35px 10px 20px;
	.group-wrap {
		align-items: center;
		width: 25%;
		display: inline-flex;
		margin: 10px 0;
		.group-checkbox {
			width: 15px;
			height: 15px;
			margin-right: 8px;
		}
	}
}

.label {
	margin-left: 12px;
	text-align: left;
	font-size: 13px;
	letter-spacing: 0.96px;
	color: $az_gray_6;
	opacity: 1;
	&.font-weight-medium {
		font-weight: 500;
	}
}
.groups-footer {
	float: right;
}

.group-modal-title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
