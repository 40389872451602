.tax-delete {
	.dialogContent {
		position: relative;
		padding: 15px 30px;
		border-radius: 5px;

		.dialogHeader {
			padding: 10px 0;
			border-bottom: 1px solid $az_gray_3;
			font-weight: 500;
			.close {
				position: absolute;
				top: 10px;
				right: 10px;
				height: 20px;
				width: 20px;
				color: $az_gray_4;
			}
		}
		.dialogBody {
			.azTitle.azTitle_secondary {
				font-weight: 400 !important;
			}
		}
		.dialogTail {
			border: none;
			.formRow {
				margin-bottom: 20px;
			}
		}
	}
}
