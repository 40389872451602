.is-helper {
	color: #343741;
	position: absolute;
	bottom: 0;
	left: 25px;
}
.text-danger {
	color: #be3131; //TODO change color name to theme variable
}

.is-dark {
	background-color: $az_gray_2 !important;
	border: none !important;
}

.is-link {
	color: $az_cyan;
	cursor: pointer;
	text-decoration: underline;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 2;
}

.tag {
	background: #ccd3ec 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
	padding: 5px 4px;
	letter-spacing: 0.78px;
	color: #343741;
	opacity: 1;
	margin: 1px;
	text-overflow: ellipsis;
	font-size: 12px;

	/* Required for text-overflow to do anything */
	white-space: nowrap;
	overflow: hidden;
	min-width: 20px;
	max-width: 65px;
	&.azCyan {
		background: $az_cyan;
		color: $az_white;
	}
}

.location-tile-header-right {
	margin-right: "20px";
	display: "flex";
	align-items: "center";
}

.is-flex {
	display: flex !important;
}

.is-margin-5 {
	margin: 5px;
}

.is-margin-v-5 {
	margin: 5px 0px;
}
.is-margin-h-5 {
	margin: 0px 5px;
}

.is-margin-v-10 {
	margin: 10px 0px;
}
.is-margin-h-10 {
	margin: 0px 10px;
}
.mr-5 {
	margin-right: 5px;
}
.mr-6 {
	margin-right: 6px;
}
.mr-7 {
	margin-right: 7px;
}
.mr-8 {
	margin-right: 8px;
}
.mr-9 {
	margin-right: 9px;
}
.mr-10 {
	margin-right: 10px;
}

.mr-60 {
	margin-right: 60px;
}

// margin top

.mt-1 {
	margin-top: 1px;
}
.mt-2 {
	margin-top: 2px;
}
.mt-3 {
	margin-top: 3px;
}
.mt-4 {
	margin-top: 4px;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-6 {
	margin-top: 6px;
}
.mt-7 {
	margin-top: 7px;
}
.mt-8 {
	margin-top: 8px;
}
.mt-9 {
	margin-top: 9px;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-50 {
	margin-top: 50px !important;
}

// margin bottom
.mb-1 {
	margin-bottom: 1px;
}
.mb-2 {
	margin-bottom: 2px;
}
.mb-3 {
	margin-bottom: 3px;
}
.mb-4 {
	margin-bottom: 4px;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-6 {
	margin-bottom: 6px;
}
.mb-7 {
	margin-bottom: 7px;
}
.mb-8 {
	margin-bottom: 8px;
}
.mb-9 {
	margin-bottom: 9px;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}

.ml-5 {
	margin-left: 5px;
}
.ml-10 {
	margin-left: 10px;
}

// padding style start
.p-r-10 {
	padding-right: 10px;
}
.p-l-10 {
	padding-left: 10px;
}

// padding style end

// hide

.not-visible {
	display: none !important;
}
.is-hidden {
	visibility: hidden !important;
}

.remove-margin-customer {
	margin-top: -15px;
}

// map

.azMap {
	margin: 0 -15px -15px;
	overflow: hidden;
	width: calc(100% + 30px);
	display: flex;
	height: 300px;
	position: relative;
	bottom: 15px;
	transform: translateY(18px);
	border-radius: 4px;
	button {
		min-width: 0;
	}
}

// weekly-element

.weekly-element {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid $az_cyan;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px 3px;
	cursor: pointer;
	&.disable-event {
		pointer-events: none;
		background-color: $az-gray_3;
		border: 1px solid $az_gray_3;
		&.is-filled {
			background-color: $az_cyan;
			color: $az_white;
		}
	}
}

.is-filled {
	background-color: $az_cyan;
	color: $az_white;
}

.is-bold {
	font-weight: bold;
}

.is-semiBold {
	font-weight: 600;
}

.after-end-type {
	display: flex;
	width: 150%;
	align-items: center;
}
