.out-off-office-styles {
	@import "./oooList";
	@import "./createOrEditModal";
}

.react-datepicker {
	border: 1px solid $az_border_color;
	.react-datepicker__year--container {
		.react-datepicker__header {
			padding: 10px;
			background: white;
		}
		.react-datepicker__year-wrapper {
			max-width: 205px;
			.react-datepicker__year-text {
				padding: 8px 12px;
				border-radius: 3px;
			}
		}
	}
}
