#catalog {
	margin: 10px -20px;
	width: 100%;

	.side_pane {
		width: 100%;
		max-width: 95%;
	}
	.main-page {
		background: #fff;
		box-shadow: 0 1px 5px 0 grey, 0 7px 10px -5px grey;
		border-radius: 10px;
		padding: 10px !important;
	}
	.catalog-scroll-bar{
		height: 87vh;
		overflow: auto;
	}
	#product {
		.listing_header {
			color: #040000;
			list-style: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 20px 0;
			border-bottom: 3px solid #f6f6f6;
		}
		.title_wrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: 10px;
			li {
				display: flex;
				flex-direction: column;
			}
			.title_count {
				font-size: 28px;
				padding: 5px 0;
			}
			.company_name {
				color: #c4c4c4;
			}

			label {
				display: block;
				white-space: nowrap;
				font-weight: 500;
			}
		}
		.actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			color: #0069b1;
			margin-left: auto;
			li {
				cursor: pointer;
				line-height: normal;
				padding: 2px 5px;
			}
			.groupItem {
				color: #6e6e6e;
			}
		}
	}
}
.hidden-block {
	display: none;
	margin: 0px;
	height: 0px;
}
@import "./product";
@import "./image";
@import "./main-landing";
@import "./sortAndFilter";
