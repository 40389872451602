.config-modal {
    max-width: 500px;
    .modal_header {
        display: flex;
        margin-bottom: 20px;
        button{
            margin-right: 16px;
            margin-left: 0px;
        }
        svg{
            width: 26px;
            height: 26px;
            margin-top: 4px;
            margin-right: 16px;
            cursor: pointer;
            font: normal normal normal 23px/24px fontello;
            letter-spacing: 0px;
            color: $az_gray_3;
            opacity: 1;
        }
        .react-select__control{
            height: 30px;
        }
    }
    .select-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }
    .select-label .select-menu {
        width: 200px;
    }
}
