// all styles for logs content
.logs {
    width: 40%; 
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;    
    /* Container around content */
    .date_dot {
        left: 41%;
        padding: 10px 40px;
        position: relative;
        background-color: inherit;
        width: 50%;
    }
    .logs_date {
        width: 32%;
        padding: 10px 0;
        color: $az_gray_6;
        text-align: right;
    }
    
    /* The circles on the timeline */
    .date_dot::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        left: -7px;
        background: $az_gray_3 0% 0% no-repeat padding-box;
        border: 1px solid $az_white;
        opacity: 1;
        top: 20px;
        border-radius: 50%;
        z-index: 4;
    }
    .logs_time {
        padding: 5px 0;
        color: $az_gray_4;
        font-size: 11px;
        width: 32%;
        text-align: right;
        &.left {
            left: 20%;
        }
    }
    /* The actual timeline (the vertical ruler) */
    .timeline {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
    }
    
    /* The actual timeline (the vertical ruler) */
    .timeline::after {
        z-index: 1;
        content: '';
        position: absolute;
        width: 3px;
        background: $az_gray_3 0% 0% no-repeat padding-box;
        top: 22px;
        bottom: 0;
        left: 40%;
        border-radius: 5px;
    }

    /* Container around content */
    .content {
        z-index: 2;
        padding: 10px 40px;
        position: relative;
        background-color: inherit;
        width: 60%;
        font-size: 13px;
    }
    
    /* The circles on the timeline */
    .content::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: -6px;
        background: $az_cyan 0% 0% no-repeat padding-box;
        border: 1px solid $az_white;
        opacity: 1;
        top: 15px;
        border-radius: 50%;
        z-index: 1;
    }
    .entry {
        text-align: left -30px;
        margin-left: -36%;
        min-width: 220px;
        padding: 5px 10px 7px 30px;
    }
    .entry:hover {
        background: $az_white;
        box-shadow: 0px 6px 11px $az_border_shadow;    
        border-radius: 5px;
        z-index: 3;
        cursor: pointer;
    }
    .is_active {
        background: $az_white;
        box-shadow: 0px 6px 11px $az_border_shadow;    
        padding: 5px 10px 7px 30px;
        border-radius: 5px;
        z-index: 3;
    }
    .entry::after {
        left: -29px;
    }
    .right {
        left: 40%;
        margin-top: -33px;
    }
}


.activity-log-wrapper {
    
    width: 100%; 
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;    
    /* Container around content */
    .date_dot {
        left: 152px;
        padding: 10px 40px;
        position: relative;
        background-color: inherit;
        width: 50%;
    }
    .logs_date {
        width: 115px;
        padding: 10px 0;
        color: $az_gray_6;
        text-align: right;
    }
    
    /* The circles on the timeline */
    .date_dot::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        left: -7px;
        background: $az_gray_3 0% 0% no-repeat padding-box;
        border: 1px solid $az_white;
        opacity: 1;
        top: 20px;
        border-radius: 50%;
        z-index: 4;
    }
    .logs_time {
        padding: 5px 0;
        color: $az_gray_4;
        font-size: 11px;
        width: 120px;
        text-align: right;
        &.left {
            left: 100px;
        }
        .corrected {
            position:relative;
            left: 50px;
        }
    }
    /* The actual timeline (the vertical ruler) */
    .timeline {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
    }
    
    /* The actual timeline (the vertical ruler) */
    .timeline::after {
        z-index: 1;
        content: '';
        position: absolute;
        width: 3px;
        background: $az_gray_3 0% 0% no-repeat padding-box;
        top: 22px;
        bottom: 0;
        left: 148px;
        border-radius: 5px;
    }

    /* Container around content */
    .content {
        z-index: 2;
        padding: 10px 25px;
        position: relative;
        background-color: inherit;
        width: 60%;
        font-size: 13px;
        .logHeader{
            margin-top: 5px;
        }
        .logContent {
            display: flex;
			align-content: center;
			align-items: center;
            min-height: 50;
            padding: 10px 0px;
            margin-top: 10px;
            background: #FAFDFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 11px #B5B5B53D;
            border-radius: 5px;
            opacity: 1;
            color: #343741;
            font-size: 13px;
        }

        .contentBox {
            display: flex;
            align-items: center;
            margin-left: 0px 17.5px;
            width: 100%;
            .oldValue{
                margin: 0px 10px;
              
            }
            .newValue{
                margin: 0px 10px;
            }

            .activity-tag{
                list-style-type: none;
                .tag-view-pill{
                    padding: 2px 6px;
                }
            }
            .media {
                display: flex;
                gap: 10px;
                padding:10px;
                font-size: 10px;
                .details {
                    display: flex;
                    flex-direction: column;
                    .name {
                         font-size: 12px;
                         margin-bottom: 5px;
                    }
                }
                .icon {
                    display: flex;
                    flex-direction: column;
                    .icon-name {
                        margin-top: -18px;
                        color: white;
                        font-size: 10px;
                        margin-left: 4px;
                    }
                }
            }
            
        }

        
        
       
    }
    
    /* The circles on the timeline */
    .content::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: -6px;
        background: $az_cyan 0% 0% no-repeat padding-box;
        border: 1px solid $az_white;
        opacity: 1;
        top: 15px;
        border-radius: 50%;
        z-index: 1;
    }
   
    .is_active {
        background: $az_white;
        box-shadow: 0px 6px 11px $az_border_shadow;    
        padding: 5px 10px 7px 30px;
        border-radius: 5px;
        z-index: 3;
    }
    .entry::after {
        left: -29px;
    }
    .right {
        left: 148px;
        margin-top: -33px;
        width: calc(100% - 148px);
    }

    .logContainer {
        padding: 10px 0px;
    }

}