.rate-body {
    display: flex;
    height: 260px;
    .combo-box-container {
        width: 20%;
        align-items: flex-start;
        margin-top: 44px;
        margin-right: 24px;
    }
    .rateAndSkillsTable {
        width: 80%;
        margin-top: 8px;
        overflow: hidden;
        .rdt_Table {
            height: 280px;
            .rdt_TableHeadRow {
                min-height: 40px;
            }
            .rdt_TableBody {
                overflow-x: hidden;
                .rdt_TableRow {
                    border-radius: 0px;
                }
            }
        }
        .button {
            width: 40px;
        }
    }
}
