.list-of-days {
	display: flex;
	.day-list-container {
		display: flex;
		flex-direction: column;
		margin-right: 24px;
	}
	.set-hoo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px;
		background: transparentize($color: $az_cyan, $amount: 0.9);
		border: 1.5px dashed $az_cyan;
		border-radius: 5px;
		cursor: pointer;
	}
	.checkbox-hoo {
		margin-left: 8px;
	}
	.day-row-container {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		.day-label {
			align-self: baseline;
		}
	}
	.time-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		.add-remove-button {
			margin-left: 7px;
			padding: 0px;
			min-width: 25px;
			height: 25px;
			line-height: 25px;
			font-size: 20px;
			color: white;
		}
	}
	.hoo-time-picker-container {
		height: 25px;
		margin-bottom: unset;
		.formRow_key {
			display: none !important;
		}
		.hoo-time-picker {
			input {
				height: 25px;
			}
		}
	}
}
