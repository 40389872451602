.menu-list{
    background:$az_white;
    box-shadow: 0px 6px 11px #0000003D;
    border-radius: 5px;
    position: absolute;
    width:max-content;
    max-height:0px;
    top: 110%;
    transition: 0.3s all ease-in-out ;
    opacity: 0;
    visibility: hidden;
    padding:8px 0px;
    overflow: hidden;
    &.overide-overflow{
        overflow: visible;
    }
    &.open-menu{
        max-height:200px;
        opacity: 1;
        visibility: visible;
        z-index: 1;
    }
    .item-list{
        max-height: 184px;
        overflow-y: auto;
    }
}