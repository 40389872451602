.time-picker-component {
	.time-picker-value {
		opacity: 1;
		height: 35px;
		width: inherit;
	}
	.rc-time-picker-input {
		border-radius: 5px;
		border: 0;
		font-size: 13px;
		color: inherit;
	}
	.rc-time-picker-input-border {
		border: 1px solid $az_gray_7;
		border-radius: 5px;
	}
	.rc-time-picker-input-error-border {
		border: 1px solid $az_red;
		border-radius: 5px;
	}
	.close_icon {
		position: absolute;
		right: 5px;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		color: #ccc;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		svg {
			height: 15px;
			width: 15px;
		}
	}
}
