.skillMap-container {
	position: relative;
	width: 55vw !important;
	.header,
	.table-head {
		font-weight: 500;
	}
	.header {
		margin-bottom: 20px;
	}
	.skill-selector {
		display: flex;
		align-items: center;
		& > div {
			margin-right: 30px;
		}
	}
	.skill-table {
		margin-top: 20px;
		.td {
			&.type {
				flex: 0.1;
			}
			&.internal {
				overflow: hidden;
				flex: 0.5;
				max-width: 50%;
				display: flex;
				justify-content: flex-end;
			}
			&.invoice {
				flex: 0.2;
			}
			&.profit {
				flex: 0.2;
			}
		}

		.table-head {
			display: flex;
			align-items: center;
			padding: 5px 0;
			border-bottom: 1px solid $az_gray_2;
			.internal {
				span {
					padding-right: 40px;
				}
			}
		}
		.table-data {
			li {
				margin-top: 10px;
				display: flex;
				align-items: center;
				.td,
				.td > section {
					display: flex;
					align-items: center;

					.standard-rate {
						margin-right: 10px;
					}
					.input-area {
						width: 55%;
						margin-right: 10px;
						input {
							padding: 0 5px;
                            text-overflow: ellipsis;
						}
					}
					&.internal {
						& > section {
							width: 100%;
							justify-content: flex-end;
							padding-right: 40px;
						}
						span {
							white-space: nowrap;
							margin-right: 10px;
						}
						.input-area {
							max-width: 25%;
							min-width: 25%;
						}
					}
				}
			}
		}
	}
	.close {
		position: absolute;
		right: 30px;
		top: 20px;
		cursor: pointer;
	}
	.skill-buttons {
		display: flex;
		justify-content: flex-end;
		margin: 30px 0 10px 0;
		.outline {
			margin-right: 10px;
		}
		button {
			display: flex;
			justify-content: center;
			width: 80px;
		}
	}
}
