.workflowTimeTracking-settings {
	@import "./timeTrackingSettings";

	.job-status-settings {
		.add-substatus-text {
			color: $az_cyan;
			text-decoration: underline;
			white-space: nowrap;
			cursor: pointer;
			&.disabled {
				cursor: not-allowed;
				color: $az_gray_4;
			}
		}
		.icons {
			display: flex;
			align-items: center;
			color: $az_gray_4;
			margin-left: 5px;
			cursor: pointer;
			.accept {
				margin-left: 10px;
			}

			button,
			span {
				min-width: 0;
				cursor: pointer;
				display: flex;
				align-items: center;
				background: none;
				color: $az_gray_4;
				padding: 0;
				width: fit-content;
			}
			&.disabled {
				svg {
					color: $az_gray_1;
				}
				cursor: not-allowed;
			}
		}
	}
}
