#autocomplete {
    background-color: $az_white; 
    color: $az_white;
   
	.panel {
		border-radius: 6px;
		box-shadow: 1px 1px 1px 1px $az_gray_2;
		max-width: 500px;
        z-index: 20;
        position: absolute;
		overflow: hidden !important;
        background-color: $az_white;
        color: $az_white;
        cursor: pointer;
    
       
	}
	.panel-block:not(:last-child),
	.panel-block:not(:last-child) {
		border-bottom: 1px solid $az_gray_2;
	}

	.panel-block {
		padding-right: 3rem;
		text-align: inherit;
		white-space: nowrap;
		width: 100%;
		color: $az-black;
		display: block;
		line-height: 1.5;
		padding: 0.375rem 1rem;
		position: relative;
	}
	.divider {
		background-color: $az_gray_2;
		border: none;
		display: block;
		height: 1px;
		margin: 0.5rem 0;
	}

	.text-primary {
		color: $az_cyan;
	}
	.text-black {
		color:$az_black;
	}
}

.location-textBox > div:first-of-type {
	width: 61% !important;
}
.location-textBox-old-job > div:first-of-type {
	width: 50% !important;
}
