@import "./components/skillMap";

.rates-container {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;

	.rates-content {
		width: 75%;
		.group-rate-body {
			display: flex;

			.group-rate-table {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.add-rate-button {
					width: max-content;
					margin-left: 44px;
					margin-bottom: 8px;
				}
			}

			.rdt_TableBody {
				overflow-y: unset;
			}

			.rdt_TableCell {
				position: relative;
				.table-profit {
					.delete-icon {
						position: absolute;
						right: 0;
						top: 10px;
						color: $az_gray_9;
						display: flex;
						align-items: center;
						margin-right: 20%;
					}
				}
			}
			.rdt_TableRow:hover {
				.delete-icon {
					svg {
						path {
							fill: $az_white;
						}
					}
				}
			}
		}

		.rates {
			.rate-table {
				margin: 20px 0;
				width: 80%;
				* {
					font-size: 13px;
				}
				.rdt_TableRow {
					margin-top: 10px;
				}
				.rdt_TableRow:hover {
					.delete-icon {
						svg {
							path {
								fill: $az_white;
							}
						}
					}
				}
				.rdt_TableHeadRow {
					min-height: 0;
					padding: 10px 0;
				}

				.rdt_TableCell {
					position: relative;
					.table-profit {
						.delete-icon {
							position: absolute;
							right: 0;
							top: 10px;
							color: $az_gray_9;
							display: flex;
							align-items: center;
							margin-right: 20%;
							&:hover {
								color: $az_white;
							}
						}
					}
				}
			}
			.add-rate-button {
				margin-left: 25px;
			}
		}

		.edit-rate {
			cursor: pointer;
			color: $az_cyan;
			font-weight: 500;
			text-align: right;
		}

		.rdt_TableRow {
			border-radius: 0px;
		}
	}
}
