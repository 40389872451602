// FormInLine component
.inline-form-list {
	width: 100%;

	.list-head {
		padding: 15px 0;
		border-bottom: 1px solid $az_gray_3;
		display: flex;
		.listFields {
			font-weight: 500;
			white-space: nowrap;
			color: #343741;
		}
	}
	.tags_colorPicker {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
	}
	.form-list-content {
		padding: 10px 10px 0 0;
		overflow: scroll;

		.td {
			position: relative;
		}

		.tr {
			display: flex;
			margin-bottom: 10px;
			align-items: center;
			position: relative;
		}

		.icons {
			display: flex;
			align-items: center;

			.accept {
				margin-left: 10px;
			}

			button,
			span {
				min-width: 0;
				cursor: pointer;
				display: flex;
				align-items: center;
				background: none;
				color: $az_gray_4;
				padding: 0;
				width: fit-content;
			}
			&.disabled {
				svg {
					color: $az_gray_1;
				}
				cursor: not-allowed;
			}
		}
	}

	.job-status-formList {
		.tr {
			display: flex;
			margin-bottom: 10px;
			align-items: baseline;
			position: relative;
		}
	}

	.form-list-scroll {
		height: 230px;
		overflow-y: scroll;
		margin: 0px 0px 10px 0px;
	}
	.no-data-area {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 7px;
		padding: 7px 15px;
		font-size: 13px;
		border-radius: 5px;
		color: $az_black;
		background: $az_gray_1;
		border: 1px solid $az_gray_2;
		min-height: 70px;
	}
	.header-with-icon {
		svg {
			width: 13px;
			margin-top: 0px;
			position: relative;
			margin-left: 5px;
			color: $az_cyan;
			cursor: pointer;
		}
	}
}

.scroll-width {
	width: 120% !important;
	overflow: auto;
	white-space: nowrap;
}
