.worker {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;
	.all-groups-container {
		background: #f6f6f6;
		width: 24%;
		padding: 4px 0;
		margin: 48px 16px 12px 0px;
		height: initial;
		overflow-y: auto;
	}
	.listing-page {
		.search-box-container {
			width: 50%;
		}
	}
	.listing-page-background {
		background: #ffffff;
		padding-right: 25px;
		padding-left: 25px;
		height: 100%;
	}
	.list-value-icon{
		display: flex;
		.list-value{
			width: 100%;
    		margin-right: 60px;
		}
		.list-icon{
			color: $az_gray_9;
		}
	}
	.rdt_TableRow:hover {
		.list-icon{
			color: $az_white;
			cursor: pointer;
		}
	}
}

.permission-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 16%;
}
