.labelMore .mainLabel > span {
    max-width: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.labelMore {
    .mainLabel {
        width: 100%;
    }
}
.mainLabel,
.popOver {
    width: 100%;
}
.popOverTrigger {
    z-index: 9999;
    display: block;
    line-height: 1.25;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.locationTooltip {
    min-width: 100%;
    width: 100%;
    .flexIt {
        > span {
            white-space: nowrap;
            max-width: calc(100% - 25px);
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
        }
        .lister {
            white-space: nowrap;
            display: block;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            line-height: 1.5;
        }
    }
}