.multiday-listing {
    max-width: 126px;
    max-height: 146px;
    min-height: 100px;
    margin: 5px;
    background: $az_white 0% 0% no-repeat padding-box;
    border: 1px solid $az_gray_7;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    :hover {
        cursor: pointer;
        color: $az_black !important;
    }
    &.has-error {
        border: 1px solid $az_maroon;
    }
    .multiday-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        &:hover {
            background: $az_gray_7 0% 0% no-repeat padding-box;
        }
    }
    .is_multi_active {
        color: $az_black !important;
        background: $az_gray_7 0% 0% no-repeat padding-box;
    }
    .multiday-date {
        margin-left: 10px;
        width: 75px;
        text-align: left;
        font: normal normal normal 13px/35px Roboto;
        letter-spacing: 0.78px;
        color: $az_gray_6;
    }
    .multiday-list-icon {
        color: $az_gray_4;
        width: 17px;
        height: 17px;
        text-align: left;
        font: normal normal normal 18px/19px fontello;
        letter-spacing: 0px;
        opacity: 1;
    }
    .strike {
        text-decoration: line-through;
        color: $az_gray_7;
    }
    .date_active {
        color: $az_black;
    }
    .label {
        min-width: 100px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.78px;
        color: $az_gray_6;
        background-color: az_gray_7;
        padding: 5px;
    }
}