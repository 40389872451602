.tag-label {
	padding: 4px 7px;
	width: fit-content;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	min-width: 0 !important;
	white-space: nowrap;
	overflow: hidden;
	border: 1px solid $az_gray_3;
	border-radius: 50px;
	background: $az_gray_1;
	color: $az_gray_6 !important;
	.dot {
		height: 12px;
		min-height: 12px;
		max-height: 12px;
		min-width: 12px;
		max-width: 12px;
		width: 12px;
		border-radius: 50%;
		margin-right: 8px;
	}
}
