.tag-select-pill{
    padding:4px 10px;
    color:$az_gray_6;
    background:$az_border_color;
    border-radius:5px;
    margin-right:8px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    max-width: 80px;
    p{
        line-height: unset;
        font-size:13px;
        letter-spacing: 0.78px;
        text-overflow: ellipsis;
        max-height: 22px;
        overflow: hidden;
        white-space: nowrap;
    }
    .close-icon{
        width:12px;
        height:12px;
        margin-left: 4px;
        cursor: pointer;
    }
}

.tag-view-pill{
    padding:5px 10px;
    font-size:13px;
    letter-spacing: 0.78px;
    color:$az_gray_6;
    border:1px solid;
    background:$az_white;
    border-radius:5px;
    margin-right:8px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
    .close-icon{
        visibility:hidden;
        opacity: 0;
        width:0px;
        height:0px;
        margin-left: 0px;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
    }
    &:hover{
        .close-icon{
            visibility:visible;
            opacity: 1;
            width: 12px;
            height:12px;
            margin-left: 4px;
        }
    }
}