.data-sync-preference{
    color: $az_gray_6;
    display: flex;
    margin-left: 16px;
    .label{
        width:25%;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.78px;
    }
    .value{
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.78px;
        display: flex;
        width:51%;
        justify-content: space-between;
        .sync-settings{
            height:85px;
        }
        .switch-group{
            display: flex;
            flex-direction: column;
            height:100%;
            justify-content: space-between;
            .custom-switch{
                margin-bottom: 16px;
                &.add-margin{
                    margin-left: 20px;
                }
            }
        }
        .radio-group{
           display: flex;
           flex-direction: column;
           height:100%;
           justify-content: space-between;
        }
    }
}