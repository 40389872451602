.hoo-body {
    display: flex;
    height: 500px;
    .hoo-days-schedule {
        margin: 26px 20px 0px 20px;
        width: 80%;
        .hoo-days {
            height: 400px;
            overflow: auto;
            .hoo-days-schedule-day {
                display: flex;
                align-items: flex-start;;
                margin-top: 10px;
                .day-is-active-switch {
                    width: 68px;
                    margin-top: 8px;
                }
                .hoo-day-name {
                    width: 140px;
                    margin-top: 12px;
                    font-size: 13px;
                    color: $az_gray_6;
                }
                .hoo-time-row-component {
                    display: flex;
                    flex-direction: column;
                    .hoo-time-row {
                        display: flex;
                        margin-bottom: 8px;
                        .hoo-time-picker-component {
                            width: 131px;
                            margin-right: 32px;
                        }
                        .mb-8 {
                            margin-top: 8px;
                        }
                        .add-button {
                            height: 24px;
                            padding: 0px 6px;
                            min-width: 24px;
                            font-size: 18px;
                            border-radius: 2px;
                            margin-right: 8px;
                        }
                        .remove-button {
                            height: 24px;
                            padding: 0px 4px 5px 5px;
                            min-width: 24px;
                            font-size: 26px;
                            border-radius: 2px;
                            color: $az_cyan;
                            background: white;
                            border-style: solid;
                            border-width: revert;
                            margin-right: 12px;
                            &[disabled] {
                                color: $az_gray_4;
                            }
                        }
                    }
                }
            }
        }
        .hoo-days-schedule-header {
            .hoo-header-column {
                text-align: left;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.72px;
                color: $az_gray_6;
                opacity: 1;
            }
            .mb-8 {
                margin-left: 68px;
            }
            .mb-120 {
                margin-right: 120px;
            }
            .mb-124 {
                margin-right: 124px;
            }
            .mb-136 {
                margin-right: 136px;
            }
        }
        .hoo-total-hours {
            text-align: left;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.78px;
            color: $az_gray_6;
            opacity: 1;
        }
    
        .hoo-divider {
            border-radius: 3px;
            border: 1px solid $az_inactive;
        }
    
        .mt-20 {
            margin-top: 20px;
        }
    
        .mb-20 {
            margin-bottom: 20px;
        }
    
        .hoo-days-button-wrapper {
            margin-top: 34px;
            margin-right: 0;
            margin-left: auto;
            width: fit-content;
        }
    
        .hoo-schedule-error-message {
            font-size: 13px;
            letter-spacing: 0.78px;
            color: $az_red;
            opacity: 1;
            text-align: center;
            margin-top: 10px;
        }
    }    
}
