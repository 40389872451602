.subtotal-rows {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    .column-right {
        width: 140px;
    }
    .discount-select-box, .tax-select-box {
        .select__control{
            min-width: 120px;
            min-height: 35px;
        }
    }
    .icons-color {
        color: #ADAFB3;
    }
}

.subtotal-component {
    margin-right: 39px;
}

.discount-percent {
    width: 80px
}

.tax-percent{
    width: 80px
}

.add-jobs-select-box {
    label {
        white-space: nowrap;
    }
    .dual-selectBox {
        position: relative;
        min-width: 300px;
        .tag{
            max-width: 135px;
        }
        .select__control {
            min-width: 300px;
        }
        .select__menu {
            margin-top: 3px
        }
    }
}