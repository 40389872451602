@use "sass:math";
// https://sass-lang.com/documentation/breaking-changes/slash-div
$siderBarWidth: 200px;
$newSideBarWidth: 240px;

.container {
	max-width: 1250px;
	margin: auto;
}

.flymenu {
	display: flex;
}
span {
	line-height: 1.5;
}
.row {
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
	// padding: 0 15px;
	flex-wrap: wrap;
	&.middle {
		align-items: center;
	}
	&.bor {
		.col {
			padding: 15px;
			border: solid 1px #ddd;
		}
	}
	&.largeSpacing {
		margin-left: -25px;
		margin-right: -25px;
		&.bor {
			.col {
				padding: 25px;
				border: solid 1px #ddd;
			}
		}
		.col {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
	&.smallSpacing {
		margin-left: -7.5px;
		margin-right: -7.5px;
		&.bor {
			.col {
				padding: 7.5px;
				border: solid 1px #ddd;
			}
		}
		.col {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
	.col {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		@for $i from 1 through 12 {
			&.l#{$i} {
				-ms-flex: 0 0 (math.div($i, 12)) * 100%;
				flex: 0 0 (math.div($i, 12)) * 100%;
				max-width: (math.div($i, 12)) * 100%;
				@include respondToBelow(sm) {
					max-width: 100%;
					flex-grow: 1;
				}
			}
		}
		@include respondTo(sm) {
			@for $i from 1 through 12 {
				&.m#{$i} {
					-ms-flex: 0 0 (math.div($i, 12)) * 100%;
					flex: 0 0 (math.div($i, 12)) * 100%;
					max-width: (math.div($i, 12)) * 100%;
				}
			}
		}
		@include respondTo(xs) {
			@for $i from 1 through 12 {
				&.s#{$i} {
					-ms-flex: 0 0 (math.div($i, 12)) * 100%;
					flex: 0 0 (math.div($i, 12)) * 100%;
					max-width: (math.div($i, 12)) * 100%;
				}
			}
		}
	}
}

.sticky-header {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 9999;
}
.new-styles {
	.wrapper {
		display: flex;
		.side_pane {
			width: $newSideBarWidth;
			max-width: $newSideBarWidth;
			background: $az_white;
			height: calc(100vh - 101px);
			// height: 100vh;
			position: relative;
			overflow: auto;
			position: sticky;
			top: 100;
			left: 0;
			z-index: 2;
			.table-chevron {
				position: fixed;
				bottom: 0;
				left: 0;
				width: $newSideBarWidth;
			}
			& + .content_pane {
				max-width: calc(100vw - #{$newSideBarWidth});
			}
			.content {
				height: calc(100% - 67px);
				overflow: auto;
				padding: 0 15px;
				> ul {
					margin-top: 0;
				}
			}
			.title {
				height: 67px;
				background: #fff;
				border: solid 1px $az_gray_1;
				padding: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.main-heading {
					font-size: 28px;
					font-weight: 800;
					margin: 0;
					line-height: normal;
				}
				.nav-header {
					cursor: pointer;
				}
				.nav-header:hover {
					color: $az_navy;
				}

				.actions {
					.fly_toggle {
						height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid $az_gray_3;
						border-radius: 25px;
						padding: 0 10px;
						&.sq {
							padding: 0;
							width: 30px;
						}
						svg {
							height: 25px;
							width: 25px;
						}
						.arrow {
							height: 20px;
						}
					}
				}
			}
			.filter_button {
				margin-bottom: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				button {
					width: 100%;
					border-width: 1.5px;
					font-weight: bold;
				}
			}
		}
		.content_pane {
			padding: 10px 20px;
			flex: 1 0 0;
			max-width: 100%;
			height: calc(100vh - 101px);
			overflow: auto;
		}
		.side_menu_pane {
			background: white;
			width: 200px;
			height: max-content;
			margin: 25px 0px 0px 25px;
			padding: 0px 15px;
			border-radius: 4px;
			position: sticky;
			top: 130px;
			li {
				text-transform: uppercase;
			}
			&.active {
				.listLabel {
					background: $az_cyan;
					color: $az_white;
					&::before {
						width: 3px;
					}
				}
			}
		}
	}
}

.old-styles {
	.wrapper {
		display: flex;
		// &#settingsWrapper {
		// 	background: linear-gradient(
		// 		to left,
		// 		#eeeeee calc(100% - 200px),
		// 		#fff calc(100% - 200px)
		// 	);
		// }
		.side_pane {
			width: $siderBarWidth;
			max-width: $siderBarWidth;
			background: $az_white;
			height: calc(100vh - 101px);
			// height: 100vh;
			position: relative;
			overflow: auto;
			position: sticky;
			top: 0;
			left: 0;
			z-index: 2;
			.table-chevron {
				position: fixed;
				bottom: 0;
				left: 0;
				width: $siderBarWidth;
			}
			& + .content_pane {
				max-width: calc(100vw - #{$siderBarWidth});
			}
			.content {
				height: calc(100% - 67px);
				overflow: auto;
				padding: 0 15px;
				> ul {
					margin-top: 0;
				}
			}
			.title {
				height: 67px;
				background: #fff;
				border: solid 1px $az_gray_1;
				padding: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.main-heading {
					font-size: 28px;
					font-weight: 800;
					margin: 0;
					line-height: normal;
				}
				.nav-header {
					cursor: pointer;
				}
				.nav-header:hover {
					color: $az_navy;
				}

				.actions {
					.fly_toggle {
						height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid $az_gray_3;
						border-radius: 25px;
						padding: 0 10px;
						&.sq {
							padding: 0;
							width: 30px;
						}
						svg {
							height: 25px;
							width: 25px;
						}
						.arrow {
							height: 20px;
						}
					}
				}
			}
			.filter_button {
				margin-bottom: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				button {
					width: 100%;
					border-width: 1.5px;
					font-weight: bold;
				}
			}
		}
		.content_pane {
			padding: 10px 20px;
			flex: 1 0 0;
			max-width: 100%;
			height: calc(100vh - 101px);
			overflow: auto;
		}
	}
}

.flexIt {
	display: flex !important;
	.flexIt_item {
		& ~ .flexIt_item {
			margin-left: 15px;
		}
	}
	&.full {
		width: 100%;
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.vcenter {
		align-items: center;
	}
	&.vright {
		align-items: flex-end;
	}
	&.vleft {
		align-items: flex-start;
	}

	&.hcenter {
		justify-content: center;
	}
	&.hright {
		justify-content: flex-end;
	}
	&.hleft {
		justify-content: flex-start;
	}

	&.hsplit {
		justify-content: space-between;
	}

	&.col {
		flex-direction: column;
	}

	&.col-i {
		flex-direction: column-reverse;
	}

	&.row-i {
		flex-direction: row-reverse;
	}

	&.wrap-it {
		flex-wrap: wrap;
	}

	.flex-grow-full {
		flex: 1 0 0;
	}

	.flex-grow-half {
		flex: 0.5 0 0;
	}

	.flex-grow-twice {
		flex: 2 0 0;
	}
}
