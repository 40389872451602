#schedule-job-card {
	border-radius: 0px;
	padding: 8px 16px;
	border-bottom-right-radius: 25px;
	border-left: 8px solid;
	margin-bottom: 8px;
	cursor: grab;
	&#schedule-job-card:active {
		cursor: grabbing;
	}
	div {
		padding: 0px;
	}
	.job-details {
		display: flex;
		align-items: baseline;
		.job-service-types {
			font-weight: 500;
			width: auto;
			text-align: right;
		}
	}
	.job-time-details {
		display: flex;
		margin: 8px 0px;
		align-items: center;
		.job-time {
			font-size: 16px;
			width: auto;
		}
		.job-day {
			width: auto;
		}
		.job-estimated-time {
			width: auto;
		}
	}
	.job-cust-details {
		display: flex;
		margin: 8px 0px;
		.job-location {
			font-size: 16px;
		}
	}
	.font-style {
		font-size: 16px;
	}
	.job-type {
		font-weight: 400;
		width: auto;
	}
	.service-type-card {
		margin-left: auto;
	}
	.top-header-font {
		font-size: 18px;
	}
}

#schedule-job-card-day {
	border-radius: 0px;
	padding: 8px 10px 8px 14px;
	border-bottom-right-radius: 25px;
	border-left: 8px solid;
	height: inherit;
	max-height: inherit;
	min-height: inherit;
	background: #fff;
	// overflow-y: auto;
	cursor: grab;
	position: relative;
	&#schedule-job-card-day:active {
		cursor: grabbing;
	}
	@include box-shadow;
	div {
		padding: 0px;
	}
	.day {
		height: 94px;
		display: flex;
		flex-direction: column;
		// width: fit-content;
		.jobNumberSchedule {
			width: fit-content;
			width: max-content;
			min-width: 60px;
		}
		.job-cust-name {
			margin-top: -3px;
			line-height: 24px;
		}
		.font-style {
			font-size: 14px;
			font-weight: 400;
		}
		.job-location {
			width: auto;
			margin-top: -10px;
			font-size: 16px;
		}
		.job-type {
			font-weight: 400;
			width: auto;
		}
		.tagContainer {
			height: 17px;
			position: relative;
			bottom: 7px;
		}
	}
	.block {
		display: block;
	}
	&.none {
		display: none;
	}
}

#schedule-job-card-three-day {
	border-radius: 0px;
	padding: 0px 16px;
	border-bottom-right-radius: 16px;
	border-left: 8px solid;
	margin-bottom: 8px;
	max-height: 35px;
	cursor: grab;
	position: relative;
	&#schedule-job-card-three-day:active {
		cursor: grabbing;
	}
	@include box-shadow;
	div {
		padding: 0px;
	}
	.job-details {
		display: flex;
		align-items: center;
		.jobNumberSchedule {
			width: fit-content;
			width: max-content;
		}
		.job-cust-name {
			color: $az_blue;
			line-height: 24px;
			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}

#schedule-job-card-week {
	border-radius: 0px;
	padding: 4px 16px;
	border-bottom-right-radius: 16px;
	border-left: 6px solid;
	margin-bottom: 8px;
	max-height: 35px;
	cursor: grab;
	width: 100%;
	position: relative;
	&#schedule-job-card-week:active {
		cursor: grabbing;
	}
	@include box-shadow;
	div {
		padding: 0px;
	}
	.jobNumberSchedule {
		width: fit-content;
		width: max-content;
	}
}

#schedule-job-card-month {
	border-radius: 0px;
	padding: 4px 16px;
	border-bottom-right-radius: 12px;
	border-left: 10px solid;
	margin-bottom: 4px;
	max-height: 25px;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	.jobNumberSchedule {
		width: fit-content;
		width: max-content;
		min-width: fit-content;
	}
	@include box-shadow;
	div {
		padding: 0px;
	}
}

.text-ellipsis-card {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.blue-cust-name {
	color: $az_cyan;
	font-size: 16px;
	font-weight: 500;
	width: auto;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.jobNumberSchedule {
	width: auto;
	min-width: fit-content;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.tagToolTip {
	.tagSelector {
		.tagList {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.tagItem {
				margin: 0 !important;
				& ~ .tagItem {
					margin-top: 8px !important;
				}
			}
		}
	}
}
.tooltip-container.tagToolTip {
	max-width: none;
	min-width: 0;
}
