.viewRates {
	display: flex;
	flex-direction: column;
	margin-left: 24px;
	width: 80%;
	* {
		font-size: 13px;
	}
	hr {
		margin: 0;
	}
	.viewHeader {
		display: flex;
		padding: 10px;
		.viewColumn {
			width: 25%;
			font-weight: 500;
			color: $az_gray_6;
		}
	}
	.viewBody {
		display: flex;
		flex-direction: column;
		.viewRow {
			display: flex;
			padding: 12px;
			.viewData-1 {
                width: 26%;
            }
            .viewData-2 {
                width: 16%;
            }
		}
		.view-no-data-available {
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 20px;
			margin-top: 70px;
		}
	}
}

.rdt_ExpanderRow {
	.viewRow {
		background-color: $az_gray_1;
		display: flex;
		padding: 12px 12px 12px 40px;
		.viewData-1 {
			width: 26%;
		}
		.viewData-2 {
			width: 16%;
		}
	}
}
