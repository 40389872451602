.payments-activity-log{
    color: $az_rpt_black;
    width: 50%;
    margin-inline: auto;
    .log-list{
        max-height: 50vh;
        overflow-y: scroll;
    }
    .log-item{
        display: flex;
    }
    .log-left{
        text-align: right;
        margin-right: 16px;
        .logs_date {
            width: 32%;
            padding: 10px 0;
            font-weight: 700;
            color: $az_gray_6;
            text-align: right;
        }

        .logs_time {
            padding: 0;
            // color: $az_gray_4;
            font-size: 11px;
            text-align: right;
            &.left {
                left: 20%;
            }
        }
    }
    .log-right{
        padding-left: 16px;
        border-left:1px dashed $az_gray_3 ;
        position: relative;
        width: 80%;
    }
    .circle{
        position:absolute;
        height: 9px;
        width: 9px;
        border-radius: 9px;
        background: $az_gray_6;
        margin-top: 20px;
        left: -5px;
    }
    .activity-content-container{
        background: #fff;
        border-radius: 10px;
        @include box-shadow;
        position: relative;
        margin: 12px 4px 8px 0;
        padding: 16px;
    }
    .activity-content-container::after{
        content: "";
        position: absolute;
        top: 16px;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent white transparent transparent;
    }
    .activity-content{
        display: flex;
        flex-direction: column;
        padding: 0px;
        .sub-text{
            font-size: 12px;
            font-weight: 400;
            color: $az_gray_5;
        }
    }

    .sub-text-status{
        color:$az_gray_5;
        display: block;
        font-size: 13px;
        margin: 20px 0;
        line-height: 1.5;
        margin: 0;

        &.bold{
            color:$az_rpt_black;
            font-weight: 500;
        }
    }
}