//This is a hack to share variables between SASS and JS Files
//Make sure both the files have the same code
//Path to the code of JS Variable defination is "../../config/theme.js"

$az_red: #be3131;
$az_maroon: #982323;
$az_yellow: #d29f13;
$az_gold: #b78b20;
$az_green: #6ca639;
$az_green_2: #6ba539;
$az_blue: #00a3e0;
$az_very_light_blue: #f6fcff;
$az_light_blue: #d9e9f3;
$az_faded_blue: #7fa5c3;
$az_cyan: #0069b1;
$az_navy: #004b87;
$az_gray_1: #f6f6f6;
$az_gray_2: #eeeeee;
$az_gray_3: #d8d8d8;
$az_gray_4: #979797;
$az_gray_5: #75777d;
$az_gray_6: #343741;
$az_gray_7: #ccd3ec;
$az_gray_8: #adafb3;
$az_gray_9: #c4c4c4;
$az_white: #ffffff;
$az_white_transparent: rgba(white, 0.1);
$az_black: #040000;
$az_black_transparent: rgba(black, 0.1);
$az_border_color: $az_gray_2;

$az_accent_active: #174361;
$az_border_color: #ccd3ec;
//Reports Color
$az_rpt_black: #2e2e2e;
$az_rpt_table_text: #333333;
$az_focus_blue: #274275;
$az_pill_container_border: #d4d5d6;

//schedule-dispatch
$az_present_time_color: #00ba4a;

// catalog

$az_low_quantity: orange;
$az_normal_quantity: green;
$az_tag_color: #43c087;
$az_inactive: #eeeeee;
$az_empty_folder_text: #bbbbbb;
$az_badge_background: #0069b1;
$az_product_container_border: #f6f6f6;

// draggable comp colors
$az_half_black: rgba(0, 0, 0, 0.5);
$az_border_shadow: #0000003d;
