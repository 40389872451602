.time-card {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;
	.listing-page {
		.search-box-container {
			width: 25%;
		}
	}
	.listing-page-background {
		background: #ffffff;
		padding-right: 25px;
		padding-left: 25px;
		height: 100%;
	}

	.date-select-label {
		display: flex;
		width: max-content;
		.date-label {
			width: 100px;
			margin-top: 10px;
		}
        .formRow_val {
            width: 90% !important;
        }
	}
}
