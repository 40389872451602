.teams-list {
	width: 1042.5px;
	.teams-list-body {
		.teams-list-header {
			background: $az_white;
			.teams-count-description {
				display: flex;
				align-items: center;
				gap: 10%;
				margin-bottom: 27px;
				padding-left: 32px;
				.teams-list-count {
					display: block;
					white-space: nowrap;
					font-weight: 500;
					.team-list-name {
						font-size: 28px;
						padding: 5px 0;
						color: $az_gray_6;
					}
					.teams-count {
						letter-spacing: 0.72px;
						display: flex;
						justify-content: center;
						label {
							font-size: 12px;
							margin-top: 4px;
							color: $az_gray_6;
						}
					}
				}
				.teams-description {
					max-width: 415px;
					color: $az_gray_6;
					letter-spacing: 0.72px;
					font-size: 13px;
					line-height: 20px;
				}
			}
		}
		.teams-search-bar {
			display: flex;
			justify-content: space-between;
			.teams-list-search-box {
				width: 250px;
			}
		}
		.teams-list-container {
			padding: 20px;
			background: white;
			.teams-delete-icon {
                color: $az_gray_9;
				svg {
					path {
						fill: $az_gray_9;
					}
				}
			}
		}
		.rdt_TableRow:hover {
			.teams-delete-icon {
				svg {
					path {
						fill: $az_white;
					}
				}
			}
			.rdt_TableRow:hover {
				svg {
					path {
						fill: $az_white;
					}
				}
			}
		}
	}
}
