.tax-settings-container {
	margin: 10px 0;

	*,
	input {
		font-size: 12px;
	}

	.tax-rate-table {
		width: 75%;
		.tax-head {
			padding: 15px 0;

			border-bottom: 1px solid $az_gray_3;
			display: flex;
			.taxFields {
				font-weight: 500;
				white-space: nowrap;
				color: #343741;
			}
			.name {
				flex: 0.2;
				span {
					padding-left: 10px;
				}
			}
			.tax {
				flex: 0.12;
			}
			.desc {
				flex: 0.53;
			}
			.active {
				flex: 0.15;
			}
		}
		.taxBody {
			padding: 10px 0 0 0;

			.tr {
				display: flex;
				margin-bottom: 10px;
				align-items: center;
			}
			.cell_in {
				padding-right: 30px;
			}
			.name {
				flex: 0.2;
			}
			.tax {
				flex: 0.12;
			}
			.desc {
				flex: 0.53;
			}
			.active {
				flex: 0.15;

				.cell_in {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0;
				}
				.switch {
					margin-right: 10px;
				}
			}
			.icons {
				display: flex;
				align-items: center;

				.accept {
					margin-left: 10px;
				}

				button,
				span {
					min-width: 0;
					cursor: pointer;
					display: flex;
					align-items: center;
					background: none;
					color: $az_gray_4;
					padding: 0;
					width: fit-content;
				}
			}
		}
	}
}
.combine.tax-settings-container {
	.tax-head {
		.name {
			flex: 0.3;
		}
		.tax {
			flex: 0.15;
		}
		.desc {
			flex: 0.4;
		}
		.active {
			flex: 0.15;
		}
	}

	.taxBody {
		.cell_in {
			padding-right: 20px;
		}
		.name {
			flex: 0.3;
		}
		.tax {
			flex: 0.15;
		}
		.desc {
			flex: 0.4;
			max-width: 40%;
		}
		.active {
			flex: 0.15;
		}
	}
}
