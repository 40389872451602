.jobs {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;

	.listing-page {
		.select__control {
			width: 30%;
		}
		.status-select-label {
			min-width: fit-content;
			text-align: right;
		}
		.select__control {
			margin-left: 10px;
		}
	}
}
