.line-item-row {
    display: flex;
    align-items: center;
    gap: 20px;
    .item-type {
        .select__control{
            min-width: 120px;
            min-height: 35px;
        }
    }
    .item-name, .item-warehouse {
        .search-box-container {
            .input {
                border-radius: 5px;
            }
        }
    }
    .icons-color {
        color: #ADAFB3;
    }
}
