.quickbooks-desktop-container{
    color: $az_gray_6;
    display: flex;
    justify-content: center;
    .heading{
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.78px;
    }
    .content-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 3;
        padding: 0px 25px;
        justify-content: space-around;
    }
    .connect-to-qbd-container{
        margin: 16px 0px;
    }
    .qbd-button{
        margin: 0px 0px 16px 0px;
    }
    .status-container{
        display: flex;
        justify-content: center;
        margin-top: 24px;
        .status-label{
            margin: 0px;
            font-size: 14px;
            font-weight: 500;
        }
        .status{
            display: flex;
            align-items: center;
            margin: 0px;
            font-size: 14px;
        }
    }
    .action-container{
        display: flex;
        flex-direction: column;
        margin-top:24px ;
        align-items: center;
    }
    .button-container{
        width: 60%;
        button{
            width:100%;
            justify-content: center;
        }
    }
    .passcode-container{
        display: flex;
        align-items: center;
        width: 60%;
        .passcode-field{
            border-radius: 5px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border: 1.5px solid $az_cyan;
            font-size: 18px;
            font-weight: bold;
            color:$az_cyan;

        }
        .copy-button{
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 50px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
        }
    }
    .previous-container{
        padding: 8px 36px;
        margin: 24px 0px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 100%;
        .heading{
            text-align: center;
        }
        .key-value-container{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .key{
                font-size: 14px;
                font-weight: 700;
                width: 45%;
            }
            .value{
                font-size: 14px;
                font-weight: 400;
            }
        }

    }
}