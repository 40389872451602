.all-groups-container {
	background: $az_gray_1;
	width: 24%;
	padding: 4px 0;
	margin: 48px 16px 12px 0px;
	height: 50vh;
	overflow-y: auto;

	.no-group-msg {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 8px;
		padding: 12px;
	}

	.individual-group {
		position: relative;
		font-size: 13px;
		padding: 12px 0;
		text-indent: 20%;
	}

	.individual-group:hover {
		background: $az_white;
		cursor: pointer;
	}

	.active {
		background: $az_white;
	}

	.active::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		background-color: $az_cyan;
		height: 100%;
		width: 4px;
	}
}
