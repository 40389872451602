.combo-box__checkbox_container {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 15px;
	height: 15px;
	.combo-box__checkbox {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 15px;
		width: 15px;
		background-color: $az_border_color;
		border-radius: 3px;
        transition: all 0.1s ease-in;
	}
	.disabled{
		background-color: $az_gray_2;
	}
	&:hover {
		.checkmark {
			background-color: $az_white;
		}
		.disabled{
			background-color: $az_gray_2;
		}
	}
	.combo-box__checkbox[type="checkbox"]:checked + .checkmark {
		background-color: $az_cyan;
	}
	.combo-box__checkbox[type="checkbox"]:checked + .checkmark::after {
		border-color: $az_white;
	}

	.combo-box__checkbox[type="checkbox"]:disabled + .checkmark {
		background-color: $az_gray_2;
	}

	.combo-box__checkbox[type="checkbox"]:disabled + .checkmark::after {
		border-color: $az_gray_2;
	}

	.checkmark::after {
		content: "";
		position: absolute;
		display: block;
		left: 5px;
		top: 1px;
		width: 5px;
		height: 10px;
		border: solid $az_border_color;
		border-width: 0px 2px 2px 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
        transition: all 0.1s ease-in;
	}
}
