.estimate-config-block {
    display: flex;
    margin-top: 28px;
    .config-label {
        margin-left: 12px;
        width: 28%;
    }
    .form {
        width: 65%;
        .input-box-field {
            margin: 0px;
            height: 35px;
            width: 208px;
            display: inline-flex;
            align-items: center;
            border-color: $az_border_color;
            transition: 0.2s all ease-in-out;
            &.input-container__estimate:focus-within {
                border-color: $az_cyan;
            }
            input {
                height: 30px;
            }
            input::placeholder {
                color: $az_gray_4;
            }
            &.is-error {
                border-color: $az_maroon;
            }
        }
    
        .estimate-field {
            display: flex;
            margin-left: 44px;
            margin-top: 12px;
            width: 100%;
            .field-label {
                margin-top: 10px;
                width: 64%;
                color: $az_gray_6;
            }
        }
    
        .switch {
            .input_label {
                .input_title.active {
                    color: $az_gray_6;
                    font-weight: normal;
                }
                .input_title {
                    color: $az_gray_6;
                    font-weight: normal;
                }
            }
        }
    }
}