.worker-selection-container {
	display: flex;
	align-items: center;
	grid-column-gap: 24px;
    margin-bottom: 8px;
	.close-icon {
		color: $az_gray_4;
		cursor: pointer;
	}
}
