.quickbooks-online-container{
    color: $az_gray_6;
    display: flex;
    justify-content: center;
    .heading{
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.78px;
    }
    .content-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 3;
        padding: 0px 25px;
        justify-content: space-around;
    }
    .admin-text{
        font-weight: 500;
        margin: 0px 0px 16px 0px;
    }
    .connect-to-qbo-container{
        margin: 16px 0px;
    }
    .qbo-button{
        margin: 16px 0px;

    }
    .previous-container{
        padding: 8px 36px;
        margin: 24px 0px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 40%;
        .heading{
            text-align: center;
        }
        .key-value-container{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .key{
                font-size: 14px;
                font-weight: 700;
                width: 45%;
            }
            .value{
                font-size: 14px;
                font-weight: 400;
            }
        }

    }
}