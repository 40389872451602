// all styles for activity content
.activity_content {
    width: 59%;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    &.top {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.bottom {
        justify-content: flex-end;
    }
    .content_title {
        font-size: 12px;
        font-weight: 500;
        color: $az_gray_6;
        letter-spacing: 0.72px;
    }
    .content {
        max-width: 490px;
        overflow: auto;
        width: 90%;
        height: 173px;
        background: $az_white 0% 0% no-repeat padding-box;
        border: 1px solid $az_gray_4;
        border-radius: 5px;
        line-height: 20px;
        padding: 20px;
        font-size: 13px;
    }
    .content li {
        line-height: 1rem;
    }
    .activity-tag{
        .tag-view-pill{
            padding: 2px 6px;
        }
    }
}