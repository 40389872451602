.job-workers {
	.workers {
		align-self: flex-start;
		margin: 4px 0;
	}
	.plus-button {
		min-width: unset;
		padding: 8px;
		height: 25px;
		svg {
			margin: unset;
			height: 12px;
			width: 12px;
		}
	}
	.lead-error {
		color: $az_red;
		text-align: center;
		padding: 10px;
	}
}
