.job-form {
	max-height: 100%;
	max-width: 1200px;
	width: 100%;
	.job-form-with-menu {
		display: flex;
		justify-content: space-between;
	}
	.job-form-components {
		width: 100%;
		max-width: 978px;
	}
	.duration {
		display: flex;
		align-items: center;
		.hrs {
			margin-right: 10px;
		}
	}
	.jobType {
		.radio-btn {
			margin-left: 0px;
		}
		align-items: flex-start;
		.label {
			padding-top: 6px;
		}
	}
	.mutipleDaysJob {
		.dual-selectBox {
			min-width: 100%;
		}
	}
	.worker-tile-toggle {
		margin-left: 20px;
	}
	.worker-tile-entity-wrapper {
		display: flex;
		align-items: center;
	}
	.multi-day-list {
		max-width: 20%;
	}
	.action-container {
		display: flex;
	}
	.action-header {
		margin: 0px;
		border-radius: 5px;
		position: fixed;
		top: 110px;
		z-index: 5;
		background: $az_white;
		width: inherit;
		max-width: inherit;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
}
