.ooo-list-container {
	display: flex;
	justify-content: center;
	padding: 25px 25px 0px 25px;
	height: 86vh;
	overflow-y: scroll;
	.select-year-picker {
		display: flex;
		gap: 20px;
		.react-datepicker__input-container {
			width: 100px;
		}
		.icon-class {
			right: 90px;
			top: 0;
		}
	}
	.ooo-action-icons {
		width: 50px;
		.delete-icon {
            color: $az_gray_9;
			position: relative;
			float: right;
		}
		svg {
			path {
				fill: $az_gray_9;
			}
		}
	}
	.rdt_TableRow:hover {
		svg {
			path {
				fill: $az_white;
			}
		}
	}
}
