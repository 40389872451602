.tooltip-modal {
	.multiList {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
            &.link {
				color: $az_cyan;
                :hover {
                    color: $az_white;
                }
            }
        &.header {
            background-color: black;
        }
		
	}
}