//********************************* Input box style Start********************************************//
.input-area {
	display: flex;
	align-items: center;
	width: 100%;
}
.input-box-container {
	position: relative;
	border: 1px solid $az_border_color;
	border-radius: 5px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	background: #fff;
	height: 35px;
	.loader {
		margin-right: 10px;
		margin-top: 8px;
	}
	.subText {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
	&.fileItem {
		border: 1px dashed $az_border_color;
		width: 200px;
	}
	.right {
		position: absolute;
		right: 5px;
		top: 4px;
	}
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}
.input {
	border-radius: 0px;
	border: none;
	background: transparent;
	color: $az_gray_6;
	padding: 5px 10px;
	margin-left: 2px;
	width: 100%;
	height: 100%;
}

.has-icon {
	padding-left: 6px;

	svg {
		margin-top: 10px;
		width: 15px;
		height: 15px;
		color: $az_cyan;
		opacity: 0.5;
	}
}
.input-box-container:focus-within {
	border: 1px solid $az_cyan; //TODO change color name to theme variable
	svg {
		opacity: 1;
	}
	.input {
		color: $az_gray_6;
	}
}

.view-mode {
	background: $az_white;
	color: $az_gray_3;
}
.disabled-input-box {
	background-color: $az_gray_2;
}
.input:disabled {
	color: $az_gray_6 !important;
	background-color: transparent;
}

.customIcon {
	display: flex;
	align-items: center;
	justify-content: center;
}

// .input:focus .input-box-container {
// 	border-color: $az_cyan !important;
// }

.is-error {
	border: 1px solid $az_red !important;
}

.is-warning {
	border: 1px solid $az_gold !important;
}

//********************************* Input box style End********************************************//

//********************************* React select style start ********************************************//
.select-box-area {
	display: flex;
	align-items: center;
	width: 100%;
	.select__menu {
		z-index: 10;
	}
}
.select__indicator-separator {
	visibility: hidden;
}
.select__indicators {
	margin-right: 10px;
	svg {
		color: $az_cyan;
		opacity: 0.7;
	}
}
.css-b8ldur-Input {
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 !important;
	padding: 0 !important;
}
.select__control--is-focused {
	box-shadow: none !important;
	border: 1px solid $az_cyan !important;
	svg {
		opacity: 1;
	}
	max-height: 100px !important;
	height: auto !important;
}
.has-error {
	.select__control {
		border: 1px solid #be3131;
	}
}

.mock-select-box {
	align-items: center;
	background-color: #fff;
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 35px;
	outline: 0 !important;
	position: relative;
	transition: all 100ms;
	box-sizing: border-box;
	.icon {
		margin: 8px 10px 10px 0px;
	}
	svg {
		color: $az_cyan;
	}
}

.select-box-tags-area {
	padding: 2px 5px 7px 2px;
	display: flex;
	align-items: center;
}
.select__value-container {
	padding: 0px 8px !important;
	height: 100%;
}

.mock-container {
	.css-2b097c-container {
		position: absolute;
		margin-top: 2px;
		z-index: 4;
	}
}

.mock-container-option:hover {
	color: #fff;
	background-color: $az_cyan;
	cursor: pointer;
}

.select__control {
	width: 100%;
	min-width: 180px;
	height: 35px !important;
}

.multiValue-select-box {
	.select__control {
		height: auto !important;
		max-height: 100px;
	}
	.select__value-container--has-value {
		overflow: scroll;
		height: auto;
		max-height: 90px;
	}
	.css-b8ldur-Input {
		height: 10px;
	}
}

.small-width {
	.select__control {
		min-width: auto;
	}
}

.disabled-select-box {
	.select__control--is-disabled {
		background: $az_gray_2;
	}
}
.select__single-value--is-disabled {
	color: $az_gray_6 !important;
}
.dual-selectBox {
	width: 100%;
}

.css-13s85zz-control {
	height: auto;
}
.select__value-container--is-multi {
	max-height: 100px;
	overflow: auto;
}
.select__multi-value {
	background-color: #f6f6f6;
	border: 0.5px solid #979797;
	border-radius: 50px;
	display: flex;
	margin: 1px;
	min-width: 0;
	box-sizing: border-box;
}

.drag-select-box {
	.select__value-container {
		padding: 5px !important;
	}
	.select__multi-value__label {
		border-radius: 2px;
		color: #2e2e2e;
		font-size: 60%;
		overflow: hidden;
		padding: 3px 1px;
		padding-left: 4px;
		text-overflow: ellipsis;
		white-space: nowrap;
		box-sizing: border-box;
	}

	.select__multi-value__remove {
		right: 7px;
		top: 0px;
		font-size: 7px;
		color: #d8d8d8;
		text-align: center;
		font-weight: 400;
		cursor: pointer;
		svg {
			width: 12px;
			height: 12px;
		}
	}
	.select__indicators {
		margin-right: 10px;
	}
}

.mock-container-box {
	.select__value-container--is-multi {
		height: 35px;
	}
}

.custom_select__option {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	.email {
		color: $az_gray_5;
		font-size: 11px;
		font-weight: 500;
	}
	&.focused {
		background: $az_cyan;
		cursor: pointer;
		span {
			color: white;
		}
	}
	&:hover {
		background: $az_cyan;
		cursor: pointer;
		span {
			color: white;
		}
	}
}

.creatable-select-box {
	.css-2b097c-container {
		width: 100%;
	}
}

//********************************* React select style End********************************************//

//********************************* Search box style Start********************************************//
.search-box {
	.icon {
		margin: auto 14px;
	}
	svg {
		color: $az_cyan;
	}
	.loader {
		margin: 10px 0px;
	}
}

.search-box-dropdown {
	position: relative;
}

.search-box-dropdown-content {
	position: absolute;
	background-color: #fff;
	min-width: 160px;
	-webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
	box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
	padding: 5px 0px;
	z-index: 1;
	width: 100%;
	margin-top: 1px;
	border-radius: 5px;
	max-height: 300px;
	overflow-y: scroll;
	visibility: hidden;
	transition-property: visibility;
	transition-delay: 0.2s;
	transition-timing-function: ease-in;

	.item {
		height: 30px;
		padding: 10px 15px;
		font-weight: 300;
		color: #343741;
	}
	.item:hover {
		background-color: $az_cyan;
		color: #fff;
		cursor: pointer;
	}
	.no-option {
		text-align: center;
		height: 30px;
		padding: 10px 15px;
		font-weight: 300;
		color: #343741;
	}
	.is-active {
		background-color: $az_cyan;
		color: #fff;
		cursor: pointer;
	}
}
.search-box-container:focus-within {
	.search-box-dropdown-content {
		visibility: visible !important;
	}
}

//********************************* Search box style End********************************************//

//********************************* Form layout style Start********************************************//

.label-value-wrapper {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	margin: 0px 10px;
	label {
		margin: 5px 10px 5px 0;
		letter-spacing: 0.78px;
		color: #343741;
		opacity: 1;
		align-self: flex-start;
		margin-top: 15px;
	}
	.jobs {
		.value {
			width: 250px;
		}
	}
	.value {
		vertical-align: middle;
		margin: 5px 10px 5px 0;
		align-items: center;
		width: 220px;
	}
	.has-error {
		display: flex;
		align-items: center;
	}
}

//********************************* Form layout style End********************************************//

//********************************* CheckBox style Start********************************************//
input[type="checkbox"] {
	cursor: pointer;
	width: 19px;
	height: 23px;
	background: $az_white;
	color: $az_cyan;
	border: 1px solid #00000000;
}

//********************************* CheckBox style End********************************************//

//********************************* Description Box style Start********************************************//
.desc-area {
	display: flex;
	align-items: center;
	width: 100%;
}
.desc-style {
	max-height: 750px;
	border-radius: "5px !important";
	border: 1px solid $az_gray_7;
	opacity: 1;
}
.desc-style:focus {
	border: 1px solid $az_cyan;
}
//********************************* Description Box style End********************************************//

// react icons default classes start
.react-icons {
	vertical-align: middle;
}

// react-icons default class end

.icon-class {
	&.left {
		position: relative;
		display: inline-table;
		left: auto;
		right: 190px;
		top: -2px;
	}
	&.right {
		position: relative;
		display: inline-table;
		left: auto;
		right: 42px;
		top: -2px;
	}
}

// textarea style

textarea {
	height: auto !important; // remove important once old styles are deprecated
}

.textarea-container {
	.label {
		margin-top: 12px;
	}
	.value {
		width: 72.6% !important;
	}
	.textarea {
		resize: none;
		border-radius: 5px;
		border-color: $az_border_color;
		transition: 0.2s all ease-in-out;
		&::placeholder {
			color: $az_gray_4;
		}
		&:focus {
			border-color: $az_cyan;
		}
	}
}

//***** file input style sheet ***********

.filebox {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 100%;
	margin-top: 5px !important;
	input {
		margin: 0;
		@include opacity(0);
		display: none;
	}

	.uploadIconGroup {
		font-family: inherit;
		color: $az_blue;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		.icon {
			margin-right: 5px;
		}
		.text {
			font-weight: 500;
		}
	}
}

.fileContainer {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	gap: 10px;
	.upload-link {
		justify-content: flex-start !important;
		text-decoration: underline;
		@extend .uploadIconGroup;
	}
}

.dot-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.fileBoxContainer {
	display: flex;

	.imagePreview {
		display: flex;
		gap: 10px;
		width: 80px;
		img {
			@extend .dot-text;
			width: 80px;
			height: 60px;
		}
	}
	.file-details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		gap: 5px;
		font-size: 12px;
		margin-left: 10px;
		margin: 10px 10px;

		.size {
			margin-top: 5px;
			font-size: 10px;
			.progress {
				position: relative;
				background: $az_gray_2;
				width: calc(80% - 10px);
				height: 5px;
				border-radius: 5px;
				overflow: hidden;
				.progress_status {
					background: $az_cyan;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					z-index: 9;
					width: 0;
				}
			}
		}
		.name {
			@extend .dot-text;
		}
		.link {
			width: 70px;
			@extend .dot-text;
		}
		.file-size {
			display: flex;
			flex-direction: column;
			gap: 3px;
		}
	}
}

.fileInfoSection {
	display: flex;
	flex-wrap: wrap;
	.fileInfoBox {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		align-items: center;
		max-height: 213px;
		overflow-y: auto;
		.fileInfoBox__content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			width: 210px;
			border-radius: 5px;
			background: transparent;
			color: $az_cyan;
			margin-left: 2px;
			border-radius: 5px;
			border: 1px solid $az_badge_background;
			.fileItem {
				width: 80%;
				display: flex;

				@extend .fileBoxContainer;
				a {
					color: $az_cyan;
					font-size: 13px;
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			&.disabled-file {
				background: $az_gray_2;
				border: 1px solid $az_gray_7;
			}
			.deleteIcon {
				margin-right: 5px;
				color: $az_gray_5;
			}
		}
		.uploadIcon {
			display: flex;
		}
	}
}

.value-container {
	.value {
		width: 72.6% !important;
	}
}

.current-location {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 57px;
	margin-bottom: 5px;
	font-size: 12px;
	color: $az_cyan;
	font-weight: 400;
	cursor: pointer;
	&.disabled {
		color: $az_gray_4;
	}
}
.job-copy-icon {
	position: absolute;
	left: 44%;
	cursor: pointer;
}
