.listingHeader {
	color: $az_gray_6;
	list-style: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 32px;
	.description {
		margin: 0 52px;
		width: 60%;
		letter-spacing: 0.72px;
		font-size: 13px;
		line-height: 20px;
	}
	label {
		display: block;
		white-space: nowrap;
		font-weight: 500;
	}
	.totalCount {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		.count {
			font-size: 28px;
			padding: 5px 0;
		}
		.countLabel {
			margin-top: 3px;
			font-size: 12px;
			letter-spacing: 0.72px;
		}
		.countLabel-subModule {
			position: absolute;
			left: 0;
			font-size: 10px;
		}
	}
}

.listingHeader-subModule {
	position: absolute;
	left: 0;
	top: -10%;
}

.listingHeader-subModule {
	position: absolute;
	left: 0;
	top: -10%;
}

.listingActions {
	padding: 25px 0 0;
	display: flex;
	gap: 16px;
	justify-content: space-between;
	align-items: center;

	.export-download-btn {
		margin-right: 16px;
		cursor: pointer;
	}

	.btn-action-container {
		display: flex;
		align-items: center;
		.cofiguration {
			margin: 0px 16px;
			cursor: pointer;
		}
	}
}

.listingActions-subModule {
	position: absolute;
	left: 0;
	top: -6%;
}

.rdt_Pagination {
	button {
		min-width: 0px;
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		width: 30px;
		padding: 4px;
		margin-right: 4px;
		border-radius: 50%;
		box-sizing: border-box;
		svg {
			margin: auto;
		}
		&:hover {
			background: $az_cyan !important;
			svg {
				fill: $az_white;
			}
		}
	}
	button[disabled] {
		background: none !important;
		fill: auto;
		svg {
			margin: auto;
		}
		&:hover {
			svg {
				fill: rgba(0, 0, 0, 0.18);
				margin: auto;
			}
		}
	}
}
