.multi-list-item {
	padding: 8px 16px;
	text-align: left;
	letter-spacing: 0.78px;
	transition: 0.1s all ease-in-out;
	cursor: pointer;
	display: flex;
	align-items: center;

	.label {
		margin-left: 10px;
		width: fit-content;
	}

	&:hover {
		background: $az_cyan;
		color: $az_white;
		.combo-box__checkbox[type="checkbox"]:checked + .checkmark {
			background-color: $az_white;
		}
		.combo-box__checkbox[type="checkbox"]:checked + .checkmark::after {
			border-color: $az_cyan;
		}

		.combo-box__checkbox[type="checkbox"] + .checkmark {
			background-color: $az_white;
		}
		.combo-box__checkbox[type="checkbox"] + .checkmark::after {
			border-color: $az_cyan;
		}
	}
	&:active {
		background: $az_accent_active;
	}
	&.show-all-menu-items {
		display: inline-block;
		position: relative;
		width: 100%;
	}
}
