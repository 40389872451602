.menu-sub-item{
    padding:4px 16px 4px 32px;
    text-align: left;
    letter-spacing: 0.78px;
    transition: 0.1s all ease-in-out ;
    cursor: default;
    &:hover{
        background:$az_cyan;
        color:$az_white;
    }
    &:active{
        background:$az_accent_active;
    }
}

.menu-sub-items {
    display: none;
    background: #ffffff;
    -webkit-box-shadow: 0px 6px 11px #0000003d;
    box-shadow: 0px 6px 11px #0000003d;
    border-radius: 5px;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    padding: 8px 0px;
}

.menu-contents:hover {
    .show-all-menu-items {
        background:$az_cyan;
        color:$az_white;
    }

    .menu-sub-items {
        display: inline-block;
        vertical-align: top;
        position: absolute;
        width: 100%;
        left: 101%;
    }
}
