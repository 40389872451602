.tile-footer{
    display: flex;
    align-items:flex-start;
    justify-content:space-between;
    h4{
        font-size:12px;
        font-weight:500;
        text-transform: uppercase;
        color:$az_gray_6;
        letter-spacing:0.72px;
        margin:0px;
    }
}