.modal-class {
    z-index: 20000;
    display: block;
    top: 0;
    position: absolute;
    [data-reach-dialog-overlay] {
        display: flex;
        flex-direction: column;
        background: $az_half_black;
        padding: 25px 0;
    }
    [data-reach-dialog-content] {
        border-radius: 10px;
        padding: 20px 20px 10px 0;
        width: 488px;
    }
}