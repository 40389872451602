body {
	.listing_table_wrapper {
		// overflow: auto;
		// width: 100%;
	}
	.listing_table_wrap {
		position: relative;
		color: $az_black;
		background: $az_white;
		border-radius: 5px;
		margin-bottom: 25px;
		padding: 0px 0px !important;

		&:not(.noscroll) {
			thead,
			.thead {
				display: block;
				position: sticky;
				top: 0;
				z-index: 9;
				min-width: fit-content;
			}
			tbody,
			.tbody {
				min-width: fit-content;
			}
		}
		table,
		.table {
			width: 100%;
			margin: 0;
			min-height: 150px;
			height: 45vh;
			overflow-y: auto;
			overflow-x: auto;
			display: block;
			min-width: 0 !important;
			border-bottom: 3px solid $az_gray_1;
			tr,
			.tr {
				display: flex;
				flex: 1 0 auto;
				min-width: fit-content !important;
				.inActive {
					background-color: #eeeeee;
				}
				.mr30 {
					margin-right: 30px;
				}
				.itemSelector {
					visibility: hidden;
					opacity: 0;
					transition: visibility 0s linear 50ms, opacity 50ms;
					align-self: center;
				}
				.visible {
					visibility: visible; 
					opacity: 1;
					transition: visibility 0s linear 0s, opacity 50ms;
				}

				td,
				.td,
				th,
				.th {
					min-width: 0px;
					width: auto !important;
					flex: 1 0 auto;
					.cell_in {
						font-size: 13px;
						margin-left: 0;
						.flexIt {
							> span {
								white-space: nowrap;
								max-width: calc(100% - 25px);
								overflow: hidden;
								text-overflow: ellipsis;
								line-height: 1.5;
							}
							.lister {
								white-space: nowrap;
								display: block;
								max-width: 250px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							p {
								line-height: 1.5;
							}
						}
						&.serviceLocations,
						&.serviceLocation,
						&.workerNames,
						&.cityState,
						&.administrators {
							overflow: visible;
							text-overflow: unset;
							.labelMore .mainLabel > span {
								max-width: 100%;
								width: 100%;
								display: block;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							.labelMore {
								.mainLabel {
									width: 100%;
								}
							}
							.mainLabel,
							.popOver {
								width: 100%;
							}
							.popOverTrigger {
								z-index: 9999;
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
					span,
					p {
						line-height: 1.5;
					}
				}
				&:hover {
					.itemSelector {
						visibility: visible;
						opacity: 1;
						transition: visibility 0s linear 0s, opacity 50ms;
					}
				}
			}
			thead,
			.thead {
				position: relative;
				display: block;
				tr,
				.tr {
					border-bottom: 3px solid $az_gray_1;
					th,
					.th {
						width: auto !important;
						font-weight: 700;
						color: $az_white !important;
						background-color: $az_gray_6;
						vertical-align: middle;
						.cell_in {
							display: flex;
							align-items: center;

							font-size: 13px;
							.sort_icon {
								width: 15px;
								line-height: 1;
								margin-left: 10px;
								svg {
									height: 12px;
									width: 12px;
								}
								&.sortable {
									display: flex;
									justify-content: center;
									flex-direction: column;
									svg {
										~ svg {
											margin-top: -7px;
										}
									}
								}
							}
						}
					}
				}
			}
			tbody,
			.tbody {
				position: relative;
				display: inline-block;
				min-width: 100% !important;
				.with-margin {
					&:hover {
						margin-top: 2px;
					}
				}
				tr,
				.tr {
					align-items: center;
					position: relative;
					cursor: pointer;
					> * {
						position: relative;
						z-index: 2;
					}
					&:before {
						content: "";
						z-index: 1;
						pointer-events: none;
						position: absolute;
						left: 2px;
						width: calc(100% - 4px);
						top: 0;
						bottom: 0;
						border-radius: 7px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
						transform: scale(1);
						@include opacity(0);
					}
					&:hover {
						&:before {
							@include opacity(1);
						}
						.drag {
							.drag_icon {
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
								filter: alpha(opacity=100);
								-moz-opacity: 1;
								-khtml-opacity: 1;
								opacity: 1;
							}
						}
					}
					& + .loading {
						.skell {
							height: 50px !important;
						}
					}
					&.loading {
						display: flex;
						flex: 1 0 auto;
						min-width: 0px;
						text-align: center;
						cursor: auto;
						&::before {
							content: none;
						}
						td,
						.td {
							text-align: center;
							font-size: 16px;
							padding: 25px;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							.cell_in {
								&:after {
									content: "";
									animation: dots 1s infinite;
								}
							}
						}
					}
					&.error {
						td,
						.td {
							.cell_in {
								color: $az_red;
								font-size: 18px;
								&::after {
									content: none;
									animation: none;
								}
							}
						}
						& + .loading {
							display: none !important;
						}
					}
					&.inActive {
						background-color: #eeeeee;
					}
				}
			}
			tr,
			.tr {
				td,
				.td,
				th,
				.th {
					text-align: left;
					color: $az_black;
					border: 0;
					padding: 10px 15px;
					user-select: none;
					font-size: 13px;
					.cell_in {
						display: block;
						line-height: 1.25;
						display: block;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						.low {
							color: $az_low_quantity;
						}
						.medium {
							color: $az_normal_quantity;
						}
						.zeroQuantity {
							color: red;
						}

						&.drag {
							position: relative;
							overflow: visible;
							> span:not(.drag_icon) {
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.drag_icon {
								cursor: grab;
								position: absolute;
								left: -7px;
								transform: translateX(-100%);
								top: -2px;
								bottom: 0;
								margin: auto;
								@include opacity(0);
								transition: all 0.25s;
							}
						}
					}
				}
				.th,
				.actionsHover {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					@include opacity(0);
					transition: all 0.25s;
					transform: translateX(10px);
					svg {
						& ~ svg {
							margin-left: 15px;
						}
					}
					.button {
						margin-left: -15px;
					}
				}
				&:hover {
					.actionsHover {
						transform: translateX(0);
						@include opacity(1);
						right: 10px;
					}
				}
			}
		}

		table tr.no-data {
			margin-top: 15vh;
			cursor: default !important;
			&:before {
				content: none !important;
			}
			border: none !important;
			> td {
				width: 100% !important;
				max-width: 90vw;
				position: sticky;
				left: 0;
				width: 100%;
				text-align: center;
			}
		}
		.ratesListing {
			.ratesListing {
				display: flex;
				p {
					margin-left: 0px;
					margin-right: 5px;
				}
			}
		}
	}
	.oofTable {
		position: relative;
		color: $az_black;
		background: $az_white;
		border-radius: 5px;
		margin-bottom: 25px;
		padding: 0px 0px !important;

		&:not(.noscroll) {
			thead,
			.thead {
				display: block;
				position: sticky;
				top: 0;
				z-index: 9;
				min-width: fit-content;
			}
			tbody,
			.tbody {
				min-width: fit-content;
			}
		}
		table,
		.table {
			width: 100%;
			margin: 0;
			min-height: 150px;
			height: 45vh;
			overflow-y: auto;
			overflow-x: auto;
			display: block;
			min-width: 0 !important;
			border-bottom: 3px solid $az_gray_1;
			tr,
			.tr {
				display: flex;
				flex: 1 0 auto;
				min-width: fit-content !important;
				td,
				.td,
				th,
				.th {
					min-width: 0px;
					width: auto !important;
					flex: 1 0 auto;
					.cell_in {
						font-size: 13px;
						margin-left: 0;
					}
					span,
					p {
						line-height: 1.5;
					}
				}
			}
			thead,
			.thead {
				position: relative;
				display: block;
				tr,
				.tr {
					border-bottom: 3px solid $az_gray_1;
					th,
					.th {
						width: auto !important;
						font-weight: 700;
						color: $az_white !important;
						background-color: $az_gray_6;
						vertical-align: middle;
						.cell_in {
							display: flex;
							align-items: center;

							font-size: 13px;
							.sort_icon {
								width: 15px;
								line-height: 1;
								margin-left: 10px;
								svg {
									height: 12px;
									width: 12px;
								}
								&.sortable {
									display: flex;
									justify-content: center;
									flex-direction: column;
									svg {
										~ svg {
											margin-top: -7px;
										}
									}
								}
							}
						}
					}
				}
			}
			tbody,
			.tbody {
				position: relative;
				display: inline-block;
				.with-margin {
					&:hover {
						margin-top: 2px;
					}
				}
				tr,
				.tr {
					align-items: center;
					position: relative;
					cursor: pointer;
					> * {
						position: relative;
						z-index: 2;
					}
					&:before {
						content: "";
						z-index: 0;
						pointer-events: none;
						position: absolute;
						left: 2px;
						width: calc(100% - 4px);
						top: 0;
						bottom: 0;
						border-radius: 7px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
						transform: scale(1);
						@include opacity(0);
					}
					&:hover {
						&:before {
							@include opacity(1);
						}
						.drag {
							.drag_icon {
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
								filter: alpha(opacity=100);
								-moz-opacity: 1;
								-khtml-opacity: 1;
								opacity: 1;
							}
						}
					}
					& + .loading {
						.skell {
							height: 50px !important;
						}
					}
					&.loading {
						display: flex;
						flex: 1 0 auto;
						min-width: 0px;
						text-align: center;
						cursor: auto;
						&::before {
							content: none;
						}
						td,
						.td {
							text-align: center;
							font-size: 16px;
							padding: 25px;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							.cell_in {
								&:after {
									content: "";
									animation: dots 1s infinite;
								}
							}
						}
					}
					&.error {
						td,
						.td {
							.cell_in {
								color: $az_red;
								font-size: 18px;
								&::after {
									content: none;
									animation: none;
								}
							}
						}
						& + .loading {
							display: none !important;
						}
					}
				}
			}
			tr,
			.tr {
				td,
				.td,
				th,
				.th {
					text-align: left;
					color: $az_black;
					border: 0;
					padding: 10px 15px;
					user-select: none;
					font-size: 13px;
					.cell_in {
						display: block;
						line-height: 1.25;
						display: block;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						&.drag {
							position: relative;
							overflow: visible;
							> span:not(.drag_icon) {
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.drag_icon {
								cursor: grab;
								position: absolute;
								left: -7px;
								transform: translateX(-100%);
								top: -2px;
								bottom: 0;
								margin: auto;
								@include opacity(0);
								transition: all 0.25s;
							}
						}
					}
				}
				.th,
				.actionsHover {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					@include opacity(0);
					transition: all 0.25s;
					transform: translateX(10px);
					svg {
						& ~ svg {
							margin-left: 15px;
						}
					}
					.button {
						margin-left: -15px;
					}
				}
				&:hover {
					.actionsHover {
						transform: translateX(0);
						@include opacity(1);
						right: 10px;
					}
				}
			}
		}

		table tr.no-data {
			margin-top: 15vh;
			cursor: default !important;
			&:before {
				content: none !important;
			}
			border: none !important;
			> td {
				width: 100% !important;
				max-width: 90vw;
				position: sticky;
				left: 0;
				width: 100%;
				text-align: center;
			}
		}
		.ratesListing {
			.ratesListing {
				display: flex;
				p {
					margin-left: 0px;
					margin-right: 5px;
				}
			}
		}
	}
	.oofTable {
		position: relative;
		color: $az_black;
		background: $az_white;
		border-radius: 5px;
		margin-bottom: 25px;
		padding: 0px 0px !important;

		&:not(.noscroll) {
			thead,
			.thead {
				display: block;
				position: sticky;
				top: 0;
				z-index: 9;
				min-width: fit-content;
			}
			tbody,
			.tbody {
				min-width: fit-content;
			}
		}
		table,
		.table {
			width: 100%;
			margin: 0;
			min-height: 150px;
			height: 45vh;
			overflow-y: auto;
			overflow-x: auto;
			display: block;
			min-width: 0 !important;
			border-bottom: 3px solid $az_gray_1;
			tr,
			.tr {
				display: flex;
				flex: 1 0 auto;
				min-width: fit-content !important;
				td,
				.td,
				th,
				.th {
					min-width: 0px;
					width: auto !important;
					flex: 1 0 auto;
					.cell_in {
						font-size: 13px;
						margin-left: 0;
					}
					span,
					p {
						line-height: 1.5;
					}
				}
			}
			thead,
			.thead {
				position: relative;
				display: block;
				tr,
				.tr {
					border-bottom: 3px solid $az_gray_1;
					th,
					.th {
						width: auto !important;
						font-weight: 700;
						color: $az_white !important;
						background-color: $az_gray_6;
						vertical-align: middle;
						.cell_in {
							display: flex;
							align-items: center;

							font-size: 13px;
							.sort_icon {
								width: 15px;
								line-height: 1;
								margin-left: 10px;
								svg {
									height: 12px;
									width: 12px;
								}
								&.sortable {
									display: flex;
									justify-content: center;
									flex-direction: column;
									svg {
										~ svg {
											margin-top: -7px;
										}
									}
								}
							}
						}
					}
				}
			}
			tbody,
			.tbody {
				position: relative;
				display: inline-block;
				.with-margin {
					&:hover {
						margin-top: 2px;
					}
				}
				tr,
				.tr {
					align-items: center;
					position: relative;
					cursor: pointer;
					> * {
						position: relative;
						z-index: 2;
					}
					&:before {
						content: "";
						z-index: 0;
						pointer-events: none;
						position: absolute;
						left: 2px;
						width: calc(100% - 4px);
						top: 0;
						bottom: 0;
						border-radius: 7px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
						transform: scale(1);
						@include opacity(0);
					}
					&:hover {
						&:before {
							@include opacity(1);
						}
						.drag {
							.drag_icon {
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
								filter: alpha(opacity=100);
								-moz-opacity: 1;
								-khtml-opacity: 1;
								opacity: 1;
							}
						}
					}
					& + .loading {
						.skell {
							height: 50px !important;
						}
					}
					&.loading {
						display: flex;
						flex: 1 0 auto;
						min-width: 0px;
						text-align: center;
						cursor: auto;
						&::before {
							content: none;
						}
						td,
						.td {
							text-align: center;
							font-size: 16px;
							padding: 25px;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							.cell_in {
								&:after {
									content: "";
									animation: dots 1s infinite;
								}
							}
						}
					}
					&.error {
						td,
						.td {
							.cell_in {
								color: $az_red;
								font-size: 18px;
								&::after {
									content: none;
									animation: none;
								}
							}
						}
						& + .loading {
							display: none !important;
						}
					}
				}
			}
			tr,
			.tr {
				td,
				.td,
				th,
				.th {
					text-align: left;
					color: $az_black;
					border: 0;
					padding: 10px 15px;
					user-select: none;
					font-size: 13px;
					.cell_in {
						display: block;
						line-height: 1.25;
						display: block;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						&.drag {
							position: relative;
							overflow: visible;
							> span:not(.drag_icon) {
								display: block;
								line-height: 1.25;
								display: block;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.drag_icon {
								cursor: grab;
								position: absolute;
								left: -7px;
								transform: translateX(-100%);
								top: -2px;
								bottom: 0;
								margin: auto;
								@include opacity(0);
								transition: all 0.25s;
							}
						}
					}
				}
				.th,
				.actionsHover {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					@include opacity(0);
					transition: all 0.25s;
					transform: translateX(10px);
					svg {
						& ~ svg {
							margin-left: 15px;
						}
					}
					.button {
						margin-left: -15px;
					}
				}
				&:hover {
					.actionsHover {
						transform: translateX(0);
						@include opacity(1);
						right: 10px;
					}
				}
			}
		}
		.no-data-available {
			position: absolute;
			width: 90%;
			text-align: center;
			top: 15vh;
		}
	}
	.ReactTable {
		border: none;
		.rt-tr-group {
			padding: 0 5px;
		}
		.rt-thead {
			border-bottom: 3px solid $az_gray_1;
			box-shadow: none !important;
			.rt-tr {
				padding: 0 5px;
			}
		}
		.rt-tbody {
			padding: 5px 0;
		}
		.rt-tbody,
		.rt-thead {
			.rt-tr.-odd {
				background: #fff;
			}
			.rt-td,
			.rt-th {
				color: $az_black;
				border: 0;
				padding: 12px;
				font-size: 13px;
			}
			.rt-th {
				font-weight: 500;
				color: $az_gray_5;
				text-align: left;

				&.-cursor-pointer {
					padding-right: 30px;
					.rt-resizable-header-content {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						&:after {
							content: "";
							display: inline-block;
							height: 30px;
							width: 10px;
							margin: 2px 0 0 20px;
							background: url(/icons/downarrow_black.svg) center no-repeat;
							background-size: 10px;
						}
					}
					&.-sort-asc,
					&.-sort-desc {
						border: none;
						background: $az_gray_1;
						box-shadow: none;
						&:after {
							content: "";
							height: 3px;
							position: absolute;
							right: 0;
							top: 0;
							left: 0;
							background: $az_cyan;
						}
						&:before {
							background-image: url(/icons/downarrow_blue.svg);
						}
					}
					&.-sort-desc {
						&:before {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	.Table__pagination {
		display: flex;
		margin: 10px;
		justify-content: center;
		align-content: center;
		button {
			background: transparent;
			color: $az_cyan;
			padding: 0;
			min-width: 0;
			width: 35px;
			display: flex;
			justify-content: center;
			align-content: center;
		}
	}
	.Table__visiblePagesWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			margin: 0 10px;
			border: solid 1px #ddd;
			color: #333;
			padding: 0;
			width: 35px;
			display: flex;
			justify-content: center;
			align-content: center;
			&.Table__pageButton--active {
				background: $az_cyan;
				color: #fff;
				border-color: $az_cyan;
			}
		}
	}

	.ReactTable {
		.rt-table {
			display: table;
			.rt-thead {
				display: table-header-group;
			}
			.rt-tbody {
				display: table-row-group;
				.rt-tr-group {
					display: contents;
					z-index: 0;
					position: relative;
					&:hover {
						z-index: 1;
					}
				}
				.rt-tr {
					background: transparent;
					&:hover {
						cursor: pointer;
						box-shadow: none;
						background: transparent;
						position: relative;
						border-radius: 8px;
						box-shadow: 0 0 0 1.5px $az_blue, 0 4px 4px 0 rgba(0, 0, 0, 0.14),
							0 7px 10px -5px rgba(0, 0, 0, 0.25);
					}
				}
			}
			.rt-tr {
				display: table-row;
				z-index: 0;
				position: relative;
				&:hover {
					z-index: 1;
				}
				.rt-th,
				.rt-td {
					padding: 10px;
					min-width: 0 !important;
					width: auto !important;
					max-width: none !important;
					display: table-cell;
				}
			}
		}
	}

	.listing-bottom-chevron-container {
		background: linear-gradient(
			0deg,
			rgba(128, 124, 124, 0.4) 0%,
			rgba(79, 79, 79, 0) 68.75%
		);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		text-align: center;
	}

	.listing_table_wrapper {
		display: flex;
		position: relative;
		margin-top: 25px;
		border-left: 2px solid $az_gray_2;
		border-top: 2px solid $az_gray_2;
		border-right: 2px solid $az_gray_2;

		.table-left-chevron-container {
			left: 0;
			text-align: center;
			background: linear-gradient(
				90deg,
				rgba(128, 124, 124, 0.4) 0%,
				rgba(79, 79, 79, 0) 68.75%
			);
			border-bottom-right-radius: 3px;
			.black-color {
				height: 37px;
				background-color: $az_gray_6;
				margin-left: -3.5px;
				width: 24px;
			}
			.left-arrow {
				margin-left: 10px;
				cursor: pointer;
			}
			z-index: 10;
		}

		.table-right-chevron-container {
			right: 0;
			text-align: center;
			background: linear-gradient(
				270deg,
				rgba(128, 124, 124, 0.4) 0%,
				rgba(79, 79, 79, 0) 68.75%
			);
			margin-right: 1px;
			border-bottom-right-radius: 3px;
			.black-color {
				height: 37px;
				background-color: $az_gray_6;
				margin-left: -3.5px;
				width: 24px;
			}
			.right-arrow {
				margin-left: -10px;
				cursor: pointer;
			}
			z-index: 10;
		}
	}

	.with-bottom-border {
		border-bottom: 2px solid $az_gray_2;
	}
}

@keyframes dots {
	0% {
		content: ".";
	}
	40% {
		content: "..";
	}
	75% {
		content: "...";
	}
	100% {
		content: "";
	}
}

.ellipsis {
	display: block;
	line-height: 1.25;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.table-chevron {
	z-index: 9;
	position: absolute;
	top: 0;
	bottom: 0;
	pointer-events: none;
	img {
		pointer-events: auto;
	}
	&:not(.listing-bottom-chevron-container) {
		width: 20px;
		display: flex;
		align-items: center;
		padding-top: 35px;
		justify-content: center;
		.black-color {
			display: none;
		}
		img {
			margin: 0 !important;
		}
	}
	&.listing-bottom-chevron-container {
		top: auto;
		left: 0;
		right: 0;
	}
}

.error-dropDown {
	border-left: "red 6px solid";
}
