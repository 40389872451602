.side_menu_pane {
	background: white;
	width: 200px;
	height: max-content;
	padding: 0px 15px;
	border-radius: 4px;
	position: sticky;
	top: 0;
	li {
		text-transform: uppercase;
	}
	&.active {
		.listLabel {
			background: $az_cyan;
			color: $az_white;
			&::before {
				width: 3px;
			}
		}
	}
	.list-item-button {
		background: none;
		color: $az_gray_6;
		width: 100%;
		font-size: 12px;
		height: 20px;
		font-weight: inherit;
		padding: 0px 0px 5px;
	}
	.list-button-active {
		color: $az_cyan;
	}
}
