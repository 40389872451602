.new-menu-styles{
    .nav-menu-list{
        min-width: 130px;
        margin-top: 1px;
        border-radius: 5px;
        li{
            height:29px;
            letter-spacing: 0.78px;
            color: $az_white;
            opacity: 1;
            
            a{
                height:29px;
                font-size: 13px;
                padding-left: 16px;
                padding-top: 4px;
                padding-bottom: 4px;
                letter-spacing: 0.78px;
                text-transform: capitalize;
            }
        }
    }
}