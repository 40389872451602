.date-picker {
    position: relative;
    display: flex;
	align-items: center;
    .react-datepicker-wrapper {
        display: inline-block;
        input[disabled] {
            background-color: $az_gray_2;
        }
       
    }
    &.has-error {
        .react-datepicker__input-container {
            input {
                border: 1px solid $az_red !important;
            }
        }
    }
    &.has-warning {
        .react-datepicker__input-container {
            input {
                border: 1px solid $az_gold  !important
            }
        }
    }
    input {
        padding-right: 25px;
        font-family: inherit;
        background: $az_white 0% 0% no-repeat padding-box;
        border: 1px solid $az_gray_7;
        border-radius: 5px;
        opacity: 1;
        width: 200px;
        height: 35px;
        font-size: 13px;
        padding-left: 35px;
        &.left {
            padding-left: 30px;
        }
        &.right {
            padding-right: 30px;
        }
    }
    :focus {
        border: 1px solid $az_cyan;
    }

    .dateIco,
    .react-datepicker__close-icon {
        position: absolute;
        color: $az_cyan;
        height: 18px;
        width: 18px;
        top: 10px;
        right: 0px;
        background-color: transparent;
        pointer-events: none;
    }
    button {
        min-width: 0;
    }
    button:hover {
        background-color: transparent;
    }
    .react-datepicker__close-icon {
        right: 5px;
        padding: 0;
        pointer-events: auto;
        cursor: pointer;
        &::after {
            cursor: pointer;
            background-color: $az_empty_folder_text;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            line-height: 1.2;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            content: "x";
            color: $az_empty_folder_text;
            background: transparent;
            padding: 0;
            font-size: 16px;
            text-transform: lowercase;
            transform: translateY(-3px);
            &.right {
                padding-right: 50px;
            }
        }
    }
    .react-datepicker-popper {
        .react-datepicker {
            display: flex;
            button {
                top: 15px;
                &.react-datepicker__navigation--next {
                    border-left-color: $az_cyan;
                    border-width: 0.4em;
                }
                &.react-datepicker__navigation--previous {
                    border-right-color: $az_cyan;
                    border-width: 0.4em;
                }
            }
            .react-datepicker__header {
                background: $az_white;
                .react-datepicker__current-month {
                    padding: 5px 0 10px;
                }
            }
        }
        &[data-placement="bottom-start"] {
            .react-datepicker {
                .react-datepicker__triangle {
                    border-bottom-color: $az_white;
                }
            }
        }
        &[data-placement="top-start"] {
            .react-datepicker {
                .react-datepicker__triangle {
                    border-top-color: $az_white;
                }
            }
        }
    }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
display: none;
width: 120%;
}
}
.date-picker-component {
    display: flex;
        border:1px solid $az_border_color;
        border-radius: 5px;
        transition: 0.2s all ease-in-out;
        align-items: center;
        .react-datepicker-wrapper{
            width:90%;
            input{
                border: none;
                width:100%;
                padding:0px;
                padding-left: 8px;
                font-size:14px;
            }
        }
        svg{
            margin:0px 4px 0px 10px;
            color:$az_cyan;
        }
        &:focus-within{
            border-color: $az_cyan;
        }
        &.error{
            border-color: $az_maroon;
        }
}
.react-datepicker__input-time-container 
    &react-datepicker-time__input-container 
        &react-datepicker-time__input 
        &input {
    margin: 0 5px;
    padding: 5px;
    width: 130px;
}

.date-picker::-webkit-scrollbar {
    display: none;
}
.date-picker {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}