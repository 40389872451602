.tagListWrapper {
	max-width: 100%;
	overflow: auto;
	.tagList {
		display: flex;
		flex-wrap: wrap;
	}
	.scheduleWorkerScrollBar.tagList {
		display: inline-flex;
		flex-wrap: nowrap;
	}
}

.scheduleWorkerScrollContainer {
	position: relative;

	.tagListWrapper {
		max-width: 700px;
		margin: auto;
	}

	.scrollBtn {
		position: absolute;
		cursor: pointer;
		z-index: 5;
		top: 50%;

		&.left {
			left: 0;
		}

		&.right {
			right: 0;
		}
	}
}

.tagListItem {
	padding: 7px 10px;
	background: $az_gray_2;
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-top: 10px;
	border-radius: 3px;
	&.addingSection {
		padding: 2px 10px;
		[error*=" active"] & {
			border-left: solid $az_red 2px;
		}
		[error*=" invalid active"] & {
			border-left: solid $az_gold 2px;
		}
	}
	cursor: grab;
	.tagClose {
		margin-left: 15px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 13px;
			width: 13px;
		}
	}
	> div {
		display: flex;
		align-items: center;
		> span {
			line-height: 1;
			font-size: 13px;
			white-space: nowrap;
			& ~ span {
				margin-left: 7px;
			}
			&.tagDrag {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				span {
					transform: translateY(-4px);
					line-height: 0.3;
					letter-spacing: 1px;
				}
			}
		}
	}
}

.tagListItem[style*="fixed"] {
	z-index: 9999999999 !important;
	cursor: grabbing !important;
	pointer-events: auto !important;
}

.tagListItem[style*="transform"] {
	pointer-events: none;
}

.assignWorkerMultiDayList {
	.tagListItem {
		width: 200px;
		min-height: 170px;
		padding: 10px;
		background: #fff;
		align-items: inherit;
		display: block;
		cursor: grab;
		border-radius: 4px;
		margin: 5px 10px;
		box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.25);

		&.addingSection {
			background: white;
			display: flex;
			justify-content: space-between;
		}
	}

	.tagListItem.addingSection > div {
		flex-flow: wrap;
		align-items: end;
	}

	.schedule-worker-menu-list {
		height: 300px;
		width: 150px;
		overflow-y: scroll;
	}

	.skillsBlock {
		margin: 30px 0;
		display: block;
		text-align: center;

		.workerTag {
			margin-bottom: 15px;
		}

		.blocksAlign {
			margin: auto;
			/*width: 85%;*/
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.workerType {
		margin-left: 5px;
	}

	.workerRoleContainer {
		margin-bottom: 8px;
		border: 1px solid #d3d3d3;
		border-radius: 3px;
		margin-right: 10px;
		padding: 7px;
		cursor: pointer;

		&.error {
			color: #be3131;
			border: 1px solid #be3131;
		}

		&.active {
			color: $az_white;
			background: #0069b1;
			border: 1px solid #0069b1;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
		}
	}

	.workerRoleTag {
		padding: 7px;
		font-size: 11px;
		border-radius: 3px;
		margin-bottom: 5px;

		&.green {
			color: $az_white;
			background: #52c4b0;
			line-height: initial;
		}

		&.orange {
			color: $az_white;
			background: #fe5f5f;
		}
	}

	.workerRole {
		font-size: 11px;
		border-radius: 3px;
		margin-bottom: 5px;
	}

	.react-select-container .react-select__control {
		border-radius: 5px;
	}
}

.multiday .assignWorkerMultiDayList .workerRoleContainer {
	padding: 0 7px;
}

.addAssignWorkers {
	margin: 40px;
	vertical-align: sub;
}

.staticBox {
	margin: 5px;
	padding: 5px 10px;
	font-size: 13px;
	color: $az_black;
	background: $az_white;
	border-radius: 5px;
	border: 1px solid #ccd3ec;

	&.red {
		border: 1px solid #ee2a7a;
	}

	&.green {
		border: 1px solid #3bb73f;
	}

	&.blue {
		border: 1px solid #2aabee;
	}

	&.orange {
		border: 1px solid #ee592a;
	}
}
