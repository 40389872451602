.rows {
	display: flex;
	height: auto;
}

.multiple-row {
	flex-wrap: wrap;
}

.column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 15%;
	box-sizing: border-box;
}

.is-centered {
	justify-content: center;
}
.is-1 {
	flex: none;
	width: 100%;
}
.is-3by4 {
	flex: none;
	width: 75% !important;
}
.is-2 {
	flex: none;
	width: 50% !important;
}
.is-3 {
	flex: none;
	width: 33.3% !important;
}
.is-4 {
	flex: none;
	width: 25%;
}
.is-5 {
	flex: none;
	width: 20%;
}
.is-6 {
	flex: none;
	width: 16.6%;
}

.is-width-55-per {
	width: 55% !important;
}

.align-center {
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.rows {
		display: block;
	}
	.column {
		width: 100%;
	}
	.is-2 {
		width: 100% !important;
	}
}
