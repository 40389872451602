.view-invoice-wrapper {
	overflow: scroll;
	height: 85vh;
	margin-bottom: 60px;
}

.view-invoice {
	max-height: 100%;
	max-width: 978px;
	min-width: 978px;
	width: 100%;
	margin: auto;
	&.show-payment-gateway {
		max-width: 100%;
		min-width: 100%;
		margin-bottom: 0px;
	}
	.view-invoice-header {
		.tenant-logo {
			display: flex;
			justify-content: center;
		}
		h3 {
			font-size: 28px;
			line-height: 34px;
			letter-spacing: 1.68px;
			margin-right: 16px;
		}
		.paid-h3 {
			margin-left: 20px;
			color: $az_green_2;
			font-weight: 500;
		}
	}
	.customer-details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 60px;
		gap: 10%;
		.sub-section-row {
			display: flex;
			flex-direction: row;
			.sub-section {
				flex: 2;
				margin-right: 40px;
				.customer-details-row {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					margin: 10px 0;
					.field-key {
						opacity: 0.4;
					}
					label {
						flex: 1;
						color: $az_gray_6;
						font-size: 13px;
						line-height: 28px;
						letter-spacing: 0.78px;
					}
				}
			}
		}
		.sub-section-column {
			display: flex;
			flex-flow: column wrap;
			margin: 10px 0;
			.field-key {
				opacity: 0.4;
			}
			.field-value {
				margin-top: 10px;
				word-break: break-word;
			}
		}
	}
	.dashed-border {
		border: 1px solid #eeeeee;
		border-style: dashed;
		margin-bottom: 10px;
	}
	.mt-10 {
		margin-top: 10px;
	}
	.items-details {
		margin-bottom: 30px;
		.items {
			.item-header {
				display: inherit;
				padding-bottom: 7px;
				border-bottom: 1px solid #eeeeee;
				h4 {
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					color: $az_gray_6;
					letter-spacing: 0.72px;
					margin: 0px;
				}
				.header-columns {
					display: inline-flex;
					width: 100%;
				}
			}
			.item-body {
				display: flex;
				margin: 10px 0;
				label {
					color: $az_gray_6;
					padding: 5px 0;
					font-size: 13px;
					line-height: 18px;
					letter-spacing: 0.72px;
				}
			}
			.item-name {
				flex: 3;
			}
			.item-unit,
			.item-cost,
			.item-quantity,
			.item-amount,
			.item-type {
				flex: 1;
			}
			.item-amount,
			.item-quantity,
			.item-cost {
				text-align: right;
			}
		}
	}
	.items-subtotal {
		margin: 10px 0px 10px auto;
		max-width: 250px;
		.subtotal-row {
			display: flex;
			justify-content: space-between;
			margin: 10px 0;
			label {
				color: $az_gray_6;
				font-size: 13px;
				line-height: 18px;
				letter-spacing: 0.72px;
			}
		}
	}
	.payment-details {
		display: flex;
		gap: 20%;
		margin-top: 50px;
		.payment-to {
			margin-top: 5px;
			flex: 1;
			.payment-details-row {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				.payment-details-label,
				.payment-details-value {
					font-size: 13px;
					color: $az_gray_6;
					flex: 1;
				}
				.payment-details-label {
					opacity: 0.4;
					// flex-basis: 40%;
				}
				.payment-details-value {
					margin: 0;
					max-width: 200px;
				}
				.payment-to-value {
					display: inline-grid;
				}
			}
		}
		.payment-form {
			flex: 1;
		}
	}
}

.manual-payment-form {
	max-width: 400px;
	.select-payment-method {
		color: $az_gray_6;
		font-size: 13px;
		text-align: left;
		opacity: 0.4;
	}
	.payment-method {
		width: 180px;
	}
	.field {
		display: flex;
		align-items: center;
		.field-key {
			flex: 1;
			color: $az_gray_6;
			font-size: 13px;
			text-align: left;
		}
		.field-value {
			flex: 1;
		}
		.description-box {
			max-width: 180px;
		}
		.payment-date {
			max-width: 146px;
			margin-right: 34px;
		}
	}
	.submit-button {
		float: right;
		right: 20px;
		margin-top: 10px;
		padding: 0 30px;
	}
}
