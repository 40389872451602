.single-tax-and-discount-modal {
	.tax-and-discount-header {
		font-weight: 500;
		font-size: 13px;
		color: $az_gray_6;
		letter-spacing: 0.72px;
	}
	.tax-and-discount-description {
		font-size: 13px;
		color: $az_gray_5;
		letter-spacing: 0.72px;
	}
	.action-buttons {
		width: fit-content;
		position: relative;
		float: right;
		margin-top: 20px;
	}
	.tax-and-discount {
		margin: 30px 0 10px;
		.field-tax-and-discount {
			display: flex;
			align-items: center;
			color: $az_gray_6;
			margin-top: 10px;
		}
		.status-wrapper {
			width: 50px;
			margin: 0 15px;
		}
		.tax-and-discount-status {
			border: 1px solid $az_cyan;
			background: none;
			padding: 2px 6px;
			border-radius: 30px;
			font-size: 11px;
		}
	}
}
