.tagSelector {
	.tagList {
		display: flow-root;
		align-items: flex-start;
		flex-direction: column;
		.tagItem {
			margin: 1px !important;
			padding: 5px 10px;

			&.solid {
				color: $az_cyan;
			}
			& ~ .tagItem {
				margin-top: 8px !important;
			}
			.tagIcon {
				color: #43c087;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transform: scale(0.9) rotateY(180deg);
				margin-right: 5px;
			}
		}
	}
	.labelRow {
		width: 60%;
	}
	.formRow.formRow--flex_row {
		align-items: center;
		flex-wrap: nowrap;
		.formRow_key {
			width: 17%;
			margin: 0;
			padding: 0;
			margin-right: 25px;
			.azTitle {
				display: flex;
				margin: 0 !important;
				align-items: center;
				color: $az_black;
				svg {
					margin-right: 5px;
					margin-left: -5px;
				}
			}
		}
	}
	.tagClear {
		font-size: 13px;
		font-weight: normal;
		color: $az_cyan;
		display: inline-block;
		margin-left: 15px;
		cursor: pointer;
	}
	.tagList {
		.tagItem {
			background: $az_gray_2;
			color: $az_black;
			padding: 8px 10px;
			margin-top: 8px;
			margin-right: 8px;
			border-radius: 3px;
			display: inline-block;
			font-size: 13px;
			cursor: pointer;
			line-height: 1;
			&.tagItem_closable {
				display: inline-flex;
				align-items: center;
				.dot {
					margin-right: 7px;
					height: 12px;
					min-width: 12px;
					border-radius: 12px;
				}
				&:after {
					content: "X";
					margin-left: 7px;
					transform: scaleY(0.8);
					font-weight: 600;
					cursor: pointer;
				}
			}
			&.tagItem_readOnly {
				margin-top: 10px;
				margin-right: 10px;
				display: inline-flex;
				align-items: center;
				background: #fff;
				border-radius: 25px;
				cursor: default;
				box-shadow: none;
				border: 2px solid $az_gray_3;
				background-color: $az_gray_1;

				display: inline-flex;
				max-width: 100%;
				.dot {
					margin-right: 7px;
					height: 12px;
					min-width: 12px;
					border-radius: 12px;
				}
				.tagLabel {
					white-space: pre-wrap;
					word-break: break-all;
					line-height: 1.1;
					overflow: hidden;
					max-width: 100%;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
}
