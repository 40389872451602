.basic-configure{
    color: $az_gray_6;
    display: flex;
    margin-left: 16px;
    margin-bottom: 40px;
    .label{
        width:25%;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.78px;
    }
    .value{
        height:85px;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.78px;
        width: 75%;
        .radio-group{
           display: flex;
           flex-direction: column;
           height:100%;
           justify-content: space-between;
        }
        .quickbooks-account-type{
            display: flex;
            margin-left: 24px;
            width: 60%;
            justify-content: space-between;
        }
    }
}