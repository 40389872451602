.time-card-modal{
    .title-big {
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		padding: 100px;
		padding: 12px 0 24px 0;
	}

	.timestamp-date{
		font-size: 14px;
    	margin-top: 5px;
	}

	.pencil-btn{
        color: $az_cyan;
		cursor: pointer;
	}

	.pre-head {
		display: flex;
		justify-content: space-between;

		.pre-head-icon {
			cursor: pointer;
		}
	}

    .divider--horizontal{
        border-top-width: 1px;
        border-top-color: $az_gray_2;
        border-top-style: solid;
    }

    .rdt_TableHead,.rdt_TableHeadRow{
        height: 40px;
        min-height: 20px;
    }

    .rdt_TableRow:hover {
		.pencil-btn {
			color: $az_white;
		}
	}

    .divider-bottom{
        margin-top: 10px;
        padding-bottom: 40px;
    }
}