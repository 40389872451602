.combo-box-list {
	background: $az_white;
	box-shadow: 0px 6px 11px #0000003d;
	border-radius: 5px;
	position: absolute;
	width: max-content;
	max-height: 0px;
	top: 110%;
	transition: 0.3s all ease-in-out;
	opacity: 0;
	visibility: hidden;
	padding: 8px 0px;
	overflow: hidden;
	width: 100%;
	&.overide-overflow {
		overflow: visible;
	}
	&.open-menu {
		max-height: 200px;
		opacity: 1;
		visibility: visible;
		z-index: 1;
	}
	.item-list {
		max-height: 184px;
		overflow-y: scroll;
	}
	.options-loading {
		color: rgb(172, 172, 172);
		text-align: center;
	}
}
