.export-modal {
    width: 550px;
    padding: 25px 30px;
    border-radius: 5px;
    .export-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        label{
            font-size:12px;
            font-weight:500;
            text-transform: uppercase;
            color:$az_gray_6;
            letter-spacing:0.72px;
            margin:0px;
        }
        svg{
            cursor: pointer;
            letter-spacing: 0px;
            color: $az_gray_3;
            opacity: 1;
        }
    }

    .export-modal-body {
        .modal-field{
            margin-top: 20px;
            label{
                font-size:12px;
                font-weight:500;
                text-transform: uppercase;
                color:$az_gray_6;
                letter-spacing:0.72px;
                margin:0px;
            }
            .field-value {
                font-size: 13px;
                font-weight: 400;
                color: $az_gray_6;
                letter-spacing: 0.72px;
                margin: 0px;
            }
        }
    }

    .export-submit-button {
        margin-top: 20px;
        .button {
            position: relative;
            float: right;
        }
    }
}
