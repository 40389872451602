.listing-tags-container {
	.dot-container {
		margin: 0;
		display: flex;
		align-items: center;
	}
	.dots {
		border: 1px solid $az_white;
	}
}
