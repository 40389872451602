.border {
	border: 1px solid lightgray;
	width: 100%;
	flex-wrap: wrap;
	padding-right: 1px;
	h3{
		margin: 20px 0px 0px 20px;
	}
}

.template {
	border: 1px solid lightgray;
}

.single-row {
	flex-wrap: nowrap !important;
}

.key-value {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0px;
	label {
		margin: 5px 10px 5px 0;
		letter-spacing: 0.78px;
		color: gray;
		opacity: 1;
	}
	.value {
		vertical-align: middle;
		margin: 5px 10px 5px 0;
		align-items: center;
		width: 56%;
		max-width: 220px;
		line-height: 1.2;
		text-align: left;
	}
}

.images-cont-wrap{
	margin: 0;
	padding: 0;
}

.images-cont{
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: -30px;
	padding: 0;
}

.img-cont:nth-child(n+13):nth-child(-n+15){
	margin-top:80px;
}

.img-cont{
	display: flex;
	flex-direction: column;
	width: 33%;
	height: 25%;
	margin: 15px 0px;
	padding: 0px 10px;
}

.img-label {
	margin: 0px 10px 0px 0;
	letter-spacing: 0.78px;
	color: gray;
	opacity: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.media-img{
	height: 150px;
	margin: 10px 0px;
}

.print-logo{
	height: 80px;
	width: 100px;
	align-self: flex-end;
	margin: 5px;
}

.margin-bottom {
	margin-bottom: 30px;
}

.header-comp-wrap{
	height: 97%;
	page-break-after: always;
}

.header-comp{
    display: flex;
    align-items:center;
    justify-content:space-between;
	padding: 0px 2px;
	h3{
		margin: 0;
		padding: 0;
	}
}

.margin-top{
	margin-top: 30px;
}

.vertical-line {
	border-right: thin solid white;
	position: relative;
}

.vertical-line:after {
	content: "";
	background: lightgray;
	position: absolute;
	top: 7.5%;
	height: 85%;
	width: 1px;
}

@media print {
	html,
	body {
		height: initial !important;
		-webkit-print-color-adjust: exact;
		font-size: small;
	}
}

@media print {
	@page {
		margin: 0 !important;
	}
}

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	.page-break {
		display: block;
		page-break-before: always;
	}
	.page-break-after {
		display: block;
		page-break-after: always;
	}
}
