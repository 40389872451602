.general-layout {
	display: flex;
	.general-info {
		flex: 100%;
		.label-value-wrapper {
			justify-content: flex-start !important;
			label {
				width: 37% !important;
			}
			.label-required {
				&:after {
					content: "*";
					color: $az_maroon;
					font-size: 14px;
					line-height: 1;
				}
			}
			.sub-label,
			.custom-value {
				margin: 5px 10px 5px 0;
			}
		}
	}
}