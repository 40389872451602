.selector-container {
	display: flex;
	align-items: flex-start;
	margin: 24px 0px;

	.section {
		display: flex;
		width: 100%;

		.selector-heading {
			width: 24%;
			flex: 0.36 1 0%;
            h4{
                text-transform: capitalize;
            }
		}
		.selector-body {
			flex: 1;
			font-size: 14px;
			letter-spacing: 0.78px;
			color: $az_gray_6;
			line-height: 1.5;

			.custom-radio-btn {
				margin-bottom: 16px;
                font-size: 14px;
				input[type="radio"] {
					width: 12px;
					height: 12px;
					margin-bottom: 2px;
				}
				input[type="radio"]:after {
					width: 12px;
					height: 12px;
					border-radius: 15px;
					top: -1px;
					left: 0px;
					position: relative;
					content: "";
					display: inline-block;
					visibility: visible;
					border: 2px solid $az_border_color;
				}
				input[type="radio"]:checked:after {
					width: 12px;
					height: 12px;
					border-radius: 15px;
					top: -1px;
					left: 0px;
					position: relative;
					background-color: $az_cyan;
					content: "";
					display: inline-block;
					visibility: visible;
					border: 0px solid $az_white;
				}
				&.disabled {
					input[type="radio"] {
						cursor: unset;
					}
					input[type="radio"]:after {
						border: 2px solid $az_gray_3;
						cursor: unset;
					}
					input[type="radio"]:checked:after {
						background-color: $az_gray_3;
						cursor: unset;
					}
				}
			}
		}
	}

	h4 {
		font-size: 12px;
		font-weight: 500;
		text-transform: capitalize;
		color: $az_gray_6;
		letter-spacing: 0.72px;
		margin: 0px;
	}
}
