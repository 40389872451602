.switch {
	display: inline-block;
	width: auto;
	&.disabled {
		@include opacity(0.5);
		pointer-events: none;
	}
	.input_label {
		display: flex !important;
		cursor: pointer;
		align-items: flex-start;
		.input_title {
			margin-top: 6px !important;
			line-height: 1;
			&.active {
				color: $az_cyan;
				font-weight: 500;
			}
		}
		.errorIcon {
			margin-top: 4px;
		}
		&.textLabel {
			.switchBool {
				margin-right: 7px;
			}
		}
		.input_icon {
			position: relative;
			height: 25px;
			width: 25px;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 25px;
				border-radius: 30px;
				height: 15px;
				border: solid 2px #aaa;
				margin-top: 5px;
				transition: all 0.25s;
			}
			&:after {
				content: "";
				position: absolute;
				width: 15px;
				top: 0px;
				left: 0;
				bottom: 0;
				margin: auto;
				transition: all 0.25s;
				border-radius: 30px;
				height: 15px;
				border: solid 2px #aaa;
			}
			&.hasError:before, 
			&.hasError:after {
				border: solid $az_red 2px;
			}
			
			[error*=" active"] & {
				&:before,
				&:after {
					border: solid $az_red 2px;
				}
			}
			[error*=" invalid active"] & {
				&:before,
				&:after {
					border: solid $az_gold 2px;
				}
			}
		}
		.switchBool {
			display: flex;
			align-items: center;
			.bool {
				margin-left: 7px;
			}
		}
	}
	&.right {
		.switchBool {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			.bool {
				margin-right: 7px;
				margin-left: 0;
			}
		}
		.input_label {
			flex-direction: row-reverse;
			justify-content: flex-end;
			&.textLabel {
				.switchBool {
					margin-left: 10px;
					margin-right: 0px;
				}
			}
		}
	}
	input {
		@include opacity(0);
		position: absolute;
		pointer-events: none;
		&:checked + span {
			.input_icon {
				&:before {
					background: $az_cyan;
					border-color: $az_cyan;
				}
				&:after {
					background: $az_white;
					border-color: $az_cyan;
					left: 10px;
				}
			}
		}
	}
}