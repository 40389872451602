@import "./tileHeader";
@import "./tileFooter";

.tile {
	position: relative;
	background: $az_white;
	&.entryAnimate {
		margin-top: 64px;
		visibility: hidden;
		animation: entryAnimation 0.3s ease-in forwards;
		@keyframes entryAnimation {
			from {
				margin-top: 64px;
				visibility: hidden;
			}
			to {
				visibility: visible;
			}
		}
	}
}
