.label-value-container {
	display: flex;
	gap: 16%;
	align-items: center;
	margin-bottom: 10px;
	.label {
		width: 10%;
		min-width: 100px;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.78px;
		color: $az_gray_6;
		white-space: nowrap;
	}
	.value {
		width: 22%;
		.input-box-container {
			min-width: 200px;
		}
		.has-error {
			.select__control {
				border-color: $az_maroon;
			}
		}
		.select__control {
			min-height: 35px;
			min-width: 200px;
			transition: 0.2s all ease-in-out;
			border-color: $az_border_color;
			outline: none !important;
			&.select__control--is-focused {
				outline: none !important;
				border-color: $az_cyan !important;
			}
			&.select__control--menu-is-open {
				outline: none !important;
				border-color: $az_cyan !important;
			}
		}
		.select__indicators {
			margin-right: 8px;
		}
		.select__placeholder {
			color: $az_gray_4;
		}
		.select__icon {
			opacity: 1;
		}
		.select_down__icon {
			transition: 0.2s all ease-in-out;
			opacity: 1;
			&.is-focussed {
				transform: rotate(180deg);
			}
		}

		.disabled-parent-name {
			min-height: 35px;
			transition: 0.2s all ease-in-out;
			color: $az_gray_6;
			background: $az_gray_2;
			border-radius: 5px;
			display: flex;
			align-items: center;
			padding-left: 12px;
			margin-bottom: 10px;
		}
		.bill-to-parent-check {
			display: flex;
			align-items: center;
			grid-gap: 16px;
		}
		.display-description-container {
			display: flex;
			align-items: center;
			grid-gap: 12px;
			padding-top: 12px;
		}
		.form-check-box {
			.checkmark::after {
				border-color: $az_border_color;
			}
			&:hover {
				.checkmark {
					border: 1px solid $az_border_color;
				}
				.checkmark::after {
					border-color: $az_cyan;
				}
				.combo-box__checkbox[type="checkbox"]:checked + .checkmark {
					border: none;
				}

				.combo-box__checkbox[type="checkbox"]:checked + .checkmark::after {
					border-color: $az_white;
				}
			}
		}
		.tag-view-container {
			width: 395px;
		}
	}
}
