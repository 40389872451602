.common-overide{
    .custom-switch{
        .input_title{
            &.active{
                // TODO: Remove this
                color: $az_gray_6 !important;
                font-weight: normal !important;
            }
        }
    }
}