.divider {
    &.divider--vertical {
        padding: 0;
        width: 3px;
        border-radius: 3px;
        background: $az_gray_2;
    }
    &.divider--horizontal{
        padding: 0;
        border-radius: 3px;
        border: 1px solid $az_gray_2;
    }
    &.dashed{
        overflow: hidden;
        position: relative;
        border: 0px solid $az_gray_2;
        height: 1px;

    }
    &.dashed:before {
        content: "";
        position: absolute;
        border: 5px dashed $az_gray_2;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
      }
      
}