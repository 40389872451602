.worker-time-card {
	.worker-time-summary {
		.grid-row {
			display: grid;
			grid-template-columns: 33.3% 33.3% 33.3%;
			div {
				padding-left: 16px;
				padding-right: 16px;
			}
			label {
				font-size: 14px;
				font-weight: 500;
				color: $az_gray_6;
			}
		}
	}
	.divider {
		border-bottom-width: 1px;
		margin: 16px 0px;
		border-bottom-color: $az_gray_3;
		border-bottom-style: solid;
	}
	a {
		color: $az_cyan;
		cursor: pointer;
		text-decoration: underline;
	}
}
