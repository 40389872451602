.content_pane{
    .custom-radio-btn{
        input[type='radio']{
            width: 12px;
            height: 12px; 
        }
        input[type='radio']:after {
            width: 12px;
            height: 12px;
            border-radius: 15px;
            top: -1px;
            left: 0px;
            position: relative;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid $az_border_color;
        }
        input[type='radio']:checked:after {
            width: 12px;
            height: 12px;
            border-radius: 15px;
            top: -1px;
            left: 0px;
            position: relative;
            background-color: $az_cyan;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 0px solid $az_white;
        }
        &.disabled{
            input[type='radio']{
                cursor: unset;
            }
            input[type='radio']:after {
                border: 2px solid $az_gray_3;
                cursor: unset;
            }
            input[type='radio']:checked:after {
                background-color: $az_gray_3;
                cursor: unset;
            }
        }
    
    }
    .custom-switch{
        .input_title{
            &.active{
                // TODO: Remove this
                color: $az_gray_6 !important;
                font-weight: normal !important;
            }
        }
    }
}