reach-portal {
  z-index: 20000;
  display: block;
  top: 0;
  position: absolute;
  [data-reach-dialog-overlay] {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding: 25px 0;
    .dialogContent {
      margin: auto;
    }
    &.large {
      [data-reach-dialog-content] {
        width: 85vw;
        @include respondToBelow(sm) {
          max-width: 90%;
        }
      }
    }
    &.small {
      [data-reach-dialog-content] {
        width: 850px;
        @include respondToBelow(sm) {
          max-width: 90%;
        }
      }
    }
    &.extraSmall {
      [data-reach-dialog-content] {
        width: 500px;
        @include respondToBelow(sm) {
          max-width: 90%;
        }
      }
    }
    &.dialogOverlay_alert.confirmationAlertPopup {
      justify-content: center;
      
      [data-reach-dialog-content] {
        width: 400px;
        animation: none;

        @include respondToBelow(sm) {
          max-width: 90%;
        }
        .dialogHeader {
          font-size: 18px;
        }
        .azTitle.azTitle_secondary {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .dialogContent_export {
      @include respondToBelow(xs) {
        width: auto !important;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .dialogContent_configuration {
      @include respondToBelow(xs) {
        width: auto !important;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  [data-reach-dialog-content] {
    border-radius: 10px;
    padding: 20px 30px;
    width: 65vw;
    @include respondToBelow(sm) {
      width: 90vw;
    }
    @include respondToBelow(xs) {
      width: 95vw;
    }
    .dialogTail,
    .dialogFooter {
      padding: 12px 30px;
      margin: 20px -30px -20px;
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftRenders {
        display: flex;
        align-items: center;
        > * {
          margin: 0;
          & ~ * {
            margin-left: 10px;
          }
        }
      }
      .rightRenders {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
      }
    }
    .dialogHeader {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      .back_icon {
        height: 45px;
        width: 35px;
        margin-left: -10px;
      }
      &.dialogHeader_center {
        text-align: center;
      }
      .azTitle {
        flex-grow: 1;
      }
      .actions {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0 25px;
        display: flex;
        align-items: center;
        button,
        .button {
          margin-right: 25px;
        }
      }
      .edit {
        display: inline-block;
      }
      .close {
        width: 30px;
        height: 30px;
        color: $az_cyan;
        cursor: pointer;
        &.disable {
          color: $az_gray_4;
        }
      }
    }
    .dialogBody {
      .dialogBodyInWarp {
        p {
          line-height: 1.5;
        }
      }
    }

    .dialogFooter,
    .dialogTail {
      text-align: right;
      border-top: 0 !important;
      .formRow {
        width: 100%;
        margin: 0;
      }
      span ~ span {
        margin-left: 10px;
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(30px);
  }
  20% {
    transform: translate(-30px);
  }
  40% {
    transform: translate(15px);
  }
  60% {
    transform: translate(-15px);
  }
  80% {
    transform: translate(8px);
  }
  100% {
    transform: translate(0px);
  }
}
@keyframes slide_in {
  0% {
    @include opacity(0);
    transform: translateY(100px);
  }

  100% {
    @include opacity(1);
    transform: translateY(0);
  }
}
