@import "./components/listingTags";

.listing-page {
	max-width: 75%;
	min-width: 75%;
	.search-box-container {
		max-width: 250px;
		width: 60%;
	}
	.select__control {
		width: 32%;
		min-width: 32%;
		margin-left: 16px;
	}
	.select_down__icon {
		transition: 0.2s all ease-in-out;
		opacity: 1;
		&.is-focussed {
			transform: rotate(180deg);
		}
	}
	.select__menu {
		width: 32%;
		min-width: 32%;
		margin-left: 16px;
	}

	.date-select-label {
		display: flex;
		width: max-content;
		.date-label {
			width: 100px;
			margin-top: 10px;
		}
	}

	.left-action-container {
		width: 100%;
		display: flex;
		align-items: center;
		grid-column-gap: 16px;
	}

	.status-select-label {
		color: $az_gray_6;
		margin-left: 16px;
		margin-right: 16px;
		text-align: left;
		font-size: 13px;
		letter-spacing: 0.78px;
	}

	.filter-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.search-box {
		background-color: $az_gray_2;
		transition: 0.3s all ease-in-out;
		border: none;
		border-color: $az_cyan;
		&:focus-within {
			border: 1px solid $az_cyan;
			background-color: $az_white;
		}
	}
	.entity-number {
		color: $az_cyan;
	}
	.cell-hyper-link {
		color: $az_cyan;
	}
	.rdt_TableRow:hover {
		.entity-number {
			color: $az_white;
		}
		.cell-hyper-link {
			color: $az_white;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
.listing-table-tooltip {
	.cell-hyper-link-tooltip {
		color: $az_cyan;
		margin: 0px 4px;
	}
	.cell-hyper-link-tooltip:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}