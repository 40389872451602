.estimate-form {
	max-height: 100%;
	max-width: 978px;
	width: 100%;
	.estimate-action {
		display: flex;
		button {
			margin-left: 8px;
		}
	}
	.input-container__estimate {
		margin: 0px;
		height: 35px;
		display: inline-flex;
		align-items: center;
		border-color: $az_border_color;
		transition: 0.2s all ease-in-out;
		&.input-container__estimate:focus-within {
			border-color: $az_cyan;
		}
		input {
			height: 30px;
		}
		input::placeholder {
			color: $az_gray_4;
		}
		&.is-error {
			border-color: $az_maroon;
		}
	}
	.textarea-container__estimate {
		align-items: flex-start;
		.label {
			margin-top: 12px;
		}
		.value {
			width: 100%;
		}
		.textarea {
			resize: none;
			border-radius: 5px;
			border-color: $az_border_color;
			transition: 0.2s all ease-in-out;
			&::placeholder {
				color: $az_gray_4;
			}
			&:focus {
				border-color: $az_cyan;
			}
		}
	}
	.header-tile {
		max-height: 300px;
		transition: 0.6s all ease-in-out;
		&.extend-height {
			max-height: 800px;
		}
	}

	// .overflow-hidden {
	// 	overflow: hidden;
	// }

	.estimate-date-picker {
		display: flex;
		border: 1px solid $az_border_color;
		border-radius: 5px;
		transition: 0.2s all ease-in-out;
		align-items: center;
		.react-datepicker-wrapper {
			width: 90%;
			input {
				border: none;
				width: 100%;
				padding: 0px;
				padding-left: 8px;
				font-size: 14px;
			}
		}
		svg {
			margin: 0px 4px 0px 10px;
			color: $az_cyan;
		}
		&:focus-within {
			border-color: $az_cyan;
		}
		&.error {
			border-color: $az_maroon;
		}
	}
	.add-items-button {
		margin-top: 20px;
		padding-left: 20px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}

	.mt-16 {
		margin-top: 16px;
	}
	.label-value-container {
		.estimateDuration {
			display: flex;
			align-items: center;

			.input-area {
				.input-box-container {
					min-width: 50px;
					width: 50px;
				}
			}
			.time {
				margin: 0 20px 0 7px;
			}
		}
	}
}
