#settings {
	.accountsetting {
		#defaultTimeZone,
		#country {
			min-width: 250px;
			.react-select__menu {
				* {
					white-space: nowrap !important;
				}
				.react-select__option {
					white-space: nowrap !important;
				}
			}
		}
		.row-full-width {
			width: 100%;
		}
	}

	.intergrations {
		.sub-checkbox-container {
			position: relative;
			margin-left: 8px;
			height: 30px;
			.border-line {
				position: absolute;
				height: 20px;
				width: 50px;
				border-left: 1.5px dashed $az_cyan;
				border-bottom: 1.5px dashed $az_cyan;
				border-bottom-left-radius: 5px;
			}
			.sub-select {
				.formRow_key {
					margin-top: 0px !important;
				}
				position: absolute;
				left: 50px;
				top: 04px;
				display: flex;
				width: 214%;
				align-items: center;
			}
		}
	}

	.logo-upload {
		.image-wrapper {
			position: relative;
			width: fit-content;
			.company-logo {
				border-radius: 50%;
				width: 150px;
				height: 150px;
				min-width: 150px;
				min-height: 150px;
				border: 1px solid $az_border_color;
				margin-top: 15px;
			}
			.image-label {
				position: absolute;
				width: 30px;
				height: 30px;
				background: $az_cyan;
				border-radius: 50%;
				padding: 4px;
				left: 120px;
				bottom: 15px;
			}
			.select-image-file {
				display: none;
			}
			.close-button {
				width: 24px;
				height: 24px;
				position: absolute;
				left: 112px;
				top: 38px;
				background-color: white;
				padding: 4px;
				border-radius: 50%;
			}
			.upload-logo-label {
				position: relative;
				top: 46%;
				left: 12%;
			}
		}
	}
}
