.tab-header{
    padding: 8px 0px;
    .pill-highlight-container{
        position: relative;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
    }
    .hovered{
        position: absolute;
        transition: all 0.35s ease-in-out;
        background: $az_light_blue;
        height: 30px;
        border-radius: 35px;
    }
    .highlighter{
        position: absolute;
        transition: all 0.35s ease-in-out;
        background: $az_cyan;
        height: 30px;
        border-radius: 35px;
    }
    .pill-container{
        position: absolute;
        transition: all 0.35s ease-in-out;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        label{
            padding:4px 0px;
            text-transform: uppercase;
            font-size: 14px;
            color: $az_gray_6;
            cursor: pointer;
            transition: color 0.35s ease-in-out;
            font-weight: 400;
        }
        .selected{
            color: $az_white;
            font-weight: 500;
        }
    }
}