&.modal {
	z-index: 20000;
	margin: 1vh auto !important;
	[data-reach-dialog-overlay] {
		display: flex;
		flex-direction: column;
		background: $az_half_black;
		padding: 25px 0;
	}

	[data-reach-dialog-content] {
		border-radius: 10px;
		padding: 20px 20px 10px 0;
		width: 488px;
	}
	.modal-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		.title {
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
		}
		.close-icon {
			font-size: 14px;
			font-weight: 500;
			svg {
				width: 20px;
				height: 20px;
				stroke-width: 1;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	// TODO: make this dynamic style from the parent component
	.modal-body {
		max-height: 530px;
		overflow: auto;
	}

	.modal-footer {
		margin-top: 10px;
		.footer-align {
			display: flex;
			justify-content: flex-end;
			gap: 10px;
		}
	}
}
