.custom-field-layout {
	.select__control {
		min-width: 130px !important;
	}
	.field-column {
		display: flex;
		align-items: center;
	}
}
.custom-field-mapped-section {
	.mapped-custom-fields {
		display: flex;
		justify-content: space-between;
	}
	.mapped-sideNav {
		width: 20%;
		height: 70%;
		margin: 0px 20px 0px 0px;
	}
	.mapped-list {
		width: 100%;
	}
	hr {
		margin-top: 35px;
		border-top: 1px dashed $az_gray_3;
	}
	.header {
		margin: 0px;
		margin-top: 30px;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		color: #343741;
		letter-spacing: 0.72px;
	}
}
