.menu-button{
    svg{
        height:14px;
        width: 14px;
        transition: 0.3s all ease-in-out;
    }
    &.rotate-icon{
        background:$az_accent_active;
        svg{
            transform: rotate(180deg);
        }
    }
}