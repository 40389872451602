.invoiced-line-items {
	padding: 20px;
	.header {
		.column {
			font-size: 14px;
			font-weight: 500;
		}
	}
	.invoice-list {
		align-items: center;
	}
	.invoice-number {
		font-size: 14px;
		font-weight: 500;
		color: $az_blue;
		text-transform: uppercase;
	}
	.list-s {
		margin: 40px 0px;
		.column {
			font-weight: 100;
		}
		.invoice-status {
			font-style: italic;
			font-size: 14px;
			font-weight: 500;
		}
	}
}

.summary-invoiced {
	display: flex;
	justify-content: flex-end;
	padding: 15px;
}
.invoiced-line-items-summary {
	height: 200px;
	width: 400px;
	background-color: $az_gray_1;
	border-radius: 6px;
	padding: 35px;
	.heading {
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	.header {
		font-weight: 500;
	}
	.single-row {
		margin: 20px 0px;
	}
}
