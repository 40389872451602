.line-item-container-summary{
    margin-top: 8px;
    .add-line-item-container{
        display: flex;
        justify-content: space-between;
    }
    h4{
        width:25%;
        font-size: 12px;
        font-weight: 500;
        color: $az_gray_6;
        letter-spacing: 0.72px;
        margin: 0px;
        text-transform: capitalize;
    } 
    .field-label{
        display: flex;
        align-items: center;
        width: 20%;
        justify-content: space-between;
        .input-area{
            width: 70%;
        }
    }
    .discount-container{
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        .discount-type{
            width:15%;
            margin-right: 8px;
        }
        .text-field{
            width: 9%;
            margin-right: 8px;
            input{
                padding-left:4px;
            }
        }
    }
    .tax-container{
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        .tax-select-container{
            width:15%;
            margin-right: 8px;
        }
    }
    .total-container{
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
    }

}